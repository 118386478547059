import { useEffect, useState } from "react";
import { deleteSportsBlock, getActiveSportsMaster, getActiveFacilityMasterBySportID, getSportBook, postSportsBlock } from "../api/sportsAPI";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';

const SportsBlocking = (props) => {

    const [selectedDate,            setSelectedDate]            = useState(moment().toDate());
    const [rightSideDetails,        setRightSideDetails]        = useState(false);
    const [sports,                  setSports]                  = useState([]);
    const [sportType,               setSportType]               = useState({});
    const [allFacilities,           setAllFacilities]           = useState([]);
    const [facilityID,              setFacilityID]              = useState(null);
    const [sportsBookSheet,         setSportsBookSheet]         = useState([]);
    const [slot,                    setSlot]                    = useState([]);
    const [status,                  setStatus]                  = useState({});

    useEffect(() => {
        props.title("Sports Blocking", "sports-blocking")
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        fetchSports();
    },[]);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
        }else{
           
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if(selectedDate && facilityID){
            setTimeout(() => {
                fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), facilityID)
            }, 100);
        }
    },[selectedDate, facilityID])

    useEffect(() => {
        if(sportType){
            fetchFacilityMasterBySportID(sportType?.SportID)
        }
    },[sportType])

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            response.length > 0 && setSportType({'SportID': response[0].ID}), setSports(response);

        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchFacilityMasterBySportID = async(id) => {
        try {
            const master = await getActiveFacilityMasterBySportID(id);
            master.length > 0 && setFacilityID(master[0].ID), setAllFacilities(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchSportsBookSheetData = async(date, facilityID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getSportBook(date, facilityID);
            if (response.Holiday != null) {
                $('#lblHoliday').text("Holiday : " + response.Holiday.Name);
            } else {
                $('#lblHoliday').text("");
            }   
            setSlot([]);
            setSportsBookSheet(response);
            swal.close();

        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const updateSlotDetails = (value, checked) => {   
        if(checked){
            setSlot(p =>  [...p, value])
        }else{
            setSlot((a) => {
                const nA = a.filter((b, i) => b !== value);
                return nA;
            })
        }
    };

    const updateStatusDetails = (field, value) => {   
        setStatus((d) => {
            return { ...d, [field]: value  };
        });
    };

    const updateTypeDetails = (field, value) => { 
        setAllFacilities([])  
        setFacilityID(null)
        setSportType((d) => {
            return { ...d, [field]: value };
        });
    };

    const openDrawer = () =>{
        if(slot.length > 0){
            setRightSideDetails(true);
        }else{
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select atleast one slot',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
    }

    const deleteSlot = async(id) => {   
        try {
            await deleteSportsBlock(id)  
            swal.fire({
                icon: 'success',
                titleText: 'Booking Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), facilityID)
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const addTeeTimeBlock = async() => {
        const btn = $('#btnSave')
        var form = $('#ModalHolder');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                }        
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });
        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            const books = slot.map((a) =>({
                TemplateID  : a.TemplateID,
                Status      : status.Status,
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'),
            }))

            await postSportsBlock(books);

            helper.StopProcessing(btn);
            swal.fire({
                icon: 'success',
                titleText: 'Booking Submitted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });

            $('.book-check').prop('checked', false)
            setSlot([])  
            fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), facilityID)
            closeDrawer()


        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xl-2 col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control " 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Sport</label>
                                    <div className="form-group col-sm-2 ">
                                        <div>
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && sportType.SportID) && {label: sports.find((m) => m.ID == sportType.SportID).Name ,value: sportType.SportID} || ''}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Facility</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlFacility" 
                                                name="ddlFacility"
                                                className="multi-custom width-95p"
                                                value={((allFacilities && allFacilities.length > 0 && facilityID) && {label: allFacilities.find((m) => m.ID == facilityID) && allFacilities.find((m) => m.ID == facilityID).Name, value: facilityID}) || ''}
                                                onChange={(event) => setFacilityID(event.value)}
                                                options={allFacilities.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            <table id="table-book" className="table table-bordered table-hover margin-b30 ">
                                <thead className="light-grey-background">
                                    <tr>
                                        <th>{sportsBookSheet.BookingList && sportsBookSheet.BookingList.length > 0 && sportsBookSheet.BookingList[0].BookingType}</th>
                                        <th>Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {sportsBookSheet.BookingList && sportsBookSheet.BookingList.length > 0 && sportsBookSheet.BookingList.map((m, i) => (
                                    <tbody key={i} style={{borderTop: 'none'}}>
                                        <tr  >
                                            <td>{m.Slot}</td>
                                            <td>
                                                <div className={`badge badge-${m.Status == null ? 'success' : m.Status == 'Booked' ? 'label-danger' : 'danger'}`}>
                                                <span>{m.Status == null ? 'Available'  : m.Status == 'Booked' ? 'User Booking' : m.Status}</span>
                                                </div>
                                            </td>
                                            <td className="book-control padding-0">          
                                            {m.Status !== 'Booked' && 
                                                <center>  
                                                    {m.Status == null ? (
                                                        <label className="kt-checkbox kt-checkbox--brand margin-0"> 
                                                            <input 
                                                                type="checkbox" 
                                                                className="book-check" 
                                                                data-templateid="162"
                                                                onChange={(event) => updateSlotDetails(m,  event.target.checked)}
                                                                // onChange={(event) => updateSlotDetails((' '+ m.Slot),  event.target.checked)}
                                                            /> &nbsp;    	
                                                            <span></span>    
                                                        </label>  

                                                    ) : (
                                                        <>
                                                            {moment(sportsBookSheet.ServerDate).isBefore(moment(m.BookDate)) && 
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15" 
                                                                    onClick={() => deleteSlot(m.ID)}
                                                                >     
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                                </button>        
                                                            }
                                                        </>
                                                    )}
                                                </center>      
                                            }
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div id="tblTeeTimeBlocking" className="kt-datatable table-striped clickable"></div>
                                {rightSideDetails && <RightSideDetails title="Tee Time Blocking" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={slot.map(a => ' '+ a.Slot)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={status.Status || ''}
                                                            onChange={(event) => updateStatusDetails('Status', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTeeTimeBlock}>Save Booking</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>}
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <div className="row">
                                <div className="col-lg-8 ml-lg-auto">
                                    <button id="btnBook" type="button" className="btn btn-brand d-inline-block margin-b20" onClick={() =>openDrawer()}>Block Tee Time</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SportsBlocking;