import React,{ useState, useEffect } from "react";
import swal from 'sweetalert2';
import Select from "react-select";
// import * as helper from '../global/helper';
import { getStaggeredTemplateData, postStaggeredTemplate, deleteStaggeredTemplate } from "../api/teeTimeAPI"



const StaggeredTemplate = (props) => {

    const [pageData,            setPageData]           = useState({});
    const [isEdit,              setIsEdit]             = useState(false);
    const [isUpdate,            setIsUpdate]           = useState(false);

    const [allTemplates,        setAllTemplates]       = useState([]);
    const [newTemplate,         setNewTemplate]        = useState({ TeeBox:1 });

    //only for react-selects -->
    const [selectedMaster,      setSelectedMaster]     = useState();
    const [selectedBlock,       setSelectedBlock]      = useState();

    //#region Effects -->

    useEffect(() => {
        props.title("Staggered Template", "staggered-template");       
        fetchData();
        
        //Sorting Function -->
        AllowSortable();
    }, []);
    
    useEffect(() => {
        if(isEdit){
            $('#txtSlot').timepicker({
                minuteStep: 10,
                defaultTime: ''
            });
    
            $('#txtSlot').on('change',({target}) => dataHandler("Slot", target.value) );
        }
    },[isEdit])

   //#endregion 
      
    const fetchData = async() => {
        try {
            const {teeTimeTemplates, teeTimeBlocks, teeTimeMasters} = await getStaggeredTemplateData();         

            const firstTeeMasters = teeTimeMasters.flatMap(m => (m.TeeBox == 1)?({label: m.Name , value : m.ID, ...m}) : []);         
            const teeBlocks = teeTimeBlocks.map(b => ({label: b.Block , value : b.ID, ...b}));
            
            setPageData({ firstTeeMasters, teeBlocks, teeTimeMasters , teeTimeTemplates});     
            
            if(firstTeeMasters.length > 0){
                onMasterChange(firstTeeMasters[0], teeTimeMasters, teeTimeTemplates);                                                     
            }
                    
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const postTemplate = () => {       
        // const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addTemplateForm');
     
        form.validate({
            rules   : { ddlMaster: { required: true }, txtSlot: { required:true }, ddlBlock: { required: true }},
            messages: { ddlMaster : { required: "Please select master" }, txtSlot:{ required: "Please select slot" } , ddlBlock : { required: "Please select block" }}
        });

        if (!form.valid()) return;
        
        //Validation Goes Here -->  
        const master1  =  selectedMaster;

        if(!selectedMaster) {
            swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Please select master', buttonsStyling: false, confirmButtonClass: "btn btn-brand"});
            return;
        }

        const master10 = pageData.teeTimeMasters.find( f => f.StartDate === master1.StartDate && f.EndDate === master1.EndDate && f.ID !== master1.ID);
        const isExist  = allTemplates.find( f => (f.TeeTimeMasterID === master1.ID || f.TeeTimeMasterID === master10.ID) && f.TeeBox === newTemplate.TeeBox && f.Slot === newTemplate.Slot);
                     
        // if (t && ( isUpdate && t.ID != newTemplate.ID)) {
                  
        if ( ( !isUpdate && !!isExist)  || ( isUpdate && !!isExist && isExist.ID != newTemplate.ID)) {
            swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Slot already exist!', buttonsStyling: false, confirmButtonClass: "btn btn-brand"});
            return;
        } 
        
        try {        
            if(!isUpdate) {
                newTemplate.TeeTimeMasterID = selectedMaster.ID
                newTemplate.TeeTimeBlockID  = selectedBlock.ID
                newTemplate.Index           = allTemplates.length + 1;            
            }else{
                //Allow to update block, teebox, slot -->   
                newTemplate.TeeTimeBlockID  = selectedBlock.ID                          
            }

            //Calls API --
            saveTemplates([newTemplate]);

            if (newTemplate.ID) {            
                swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Template updated successfully!', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,
                    titleText: 'Template added successfully!',
                    onOpen: () => swal.hideLoading()    
                });
            }
                
            clearActions();
        } catch (err) {
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }        
    }
    
    const deleteHandler = async(templateId) => {
        if(!templateId) return;

        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            const res = await deleteStaggeredTemplate(templateId);
                        
            //Update and Refresh -->
            onMasterChange(selectedMaster, pageData.teeTimeMasters, res);        
            
            setPageData( d => {
                d.teeTimeTemplates = res;
                return {...d};
            });            
                                                
            swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Template deleted successfully!', 
                    onOpen: () => swal.hideLoading()                                     
            });
        } catch (err) {
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }
    }

    const saveTemplates = async(templates) => {
        if(templates.length <= 0) return;

        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                        
            const res = await postStaggeredTemplate(templates);
            
            //Update and Refresh -->
            onMasterChange(selectedMaster, pageData.teeTimeMasters, res);
            
            setPageData( d => {
                d.teeTimeTemplates = res;
                return {...d};
            });
                                    
            swal.close()
        } catch (err) {
            swal.close()
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;  
        }
    }

    //#region Helpers --->
        const dataHandler = (field,value) => setNewTemplate(d => ({...d, [field]: value}));

        const editHandler = (id) => {                 
            setIsUpdate(true);
            
            const d = allTemplates.find( a => a.ID == id);
            const b = pageData.teeBlocks.find( a => a.ID = d.TeeTimeBlockID);
            
            setNewTemplate(d);
            setSelectedBlock(b);
        
            $(`tr[data-id =${id}]`).addClass("bg-lightgrey");
        }
        
        const onMasterChange = (m, masterList = [], templateList = []) => {
            const {teeTimeMasters , teeTimeTemplates} = pageData;

            const mList = (!!masterList && (masterList.length > 0)) ?  masterList : teeTimeMasters || [];
            const tList = (!!templateList && (templateList.length > 0)) ?  templateList : teeTimeTemplates || [];    
                    
            const master1  =  m;
            const master10 = mList.find( f => f.StartDate === master1.StartDate && f.EndDate === master1.EndDate && f.ID !== master1.ID);   

            const filteredTemplates = tList.filter(i => i.TeeTimeMasterID === master1.ID || i.TeeTimeMasterID === master10.ID)
                    
            /** @todo 
            * Currently on Backend --> 
            * Update IsEdit Validation asper server date & teeTimePublish 
            */      
            setIsEdit(master1.IsEdit);
        
            setSelectedMaster(m);
            setAllTemplates(filteredTemplates);
            clearActions();
        }

         //Sorting -->
         const AllowSortable =() =>{
            const config = (event, ui)=> {
                const ids = [];
                $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
                sortAndSave(ids);   
            }

            window.sortInit('#table-block tbody', config);    
        }

        const sortAndSave = async(param)=> {
            if(param.length <= 0) return;      
                        
            try {
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                            
                let templates = [];        

                setAllTemplates( d => {
                    const sorted = [];
                    if(d.length > 0){                  
                        for (const [i,id] of Object.entries(param)) {                       
                            let f = d.find( a => a.ID == id);
                            if(!!f){
                                f.Index = parseInt(i)+1;                    
                                sorted.push(f);
                            }
                        }
                    
                        templates = sorted;
                        return d;
                    }
                });
                            
                if(templates.length > 0){                 
                    const res = await postStaggeredTemplate(templates);
                    setPageData( d => {
                        d.teeTimeTemplates = res;
                        return {...d};
                    });    
                }                   
                swal.close()
            } catch (err) {              
                swal.close()
                swal.fire({icon: 'error',titleText: 'Error!',text: err.message,buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
                return;  
            }
        }
        
        const clearActions = () => {                  
            setIsUpdate(false);
            setNewTemplate({ TeeBox:1 });
            setSelectedBlock();
            $("#table-block tbody tr").removeClass("bg-lightgrey");             
        }
    //#endregion

    const onCheck = async(value) => {
        saveTemplates([{...value, CheckBox: 'Y'}]);                  

    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <form className="kt-form" id="addTemplateForm" >                
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form" role="form">
                                    <div className="row">
                                        <div className="form-group col-sm-3 d-flex">
                                            <label className="col-form-label text-right mr-2">Master</label>
                                            <div className="input-group ddlCoursePnl">
                                                <Select id="ddlMaster" name="ddlMaster" className="width-95p" 
                                                    options={pageData.firstTeeMasters}
                                                    value={selectedMaster || ''} 
                                                    onChange={(v) => onMasterChange(v)}
                                                />
                                            </div>
                                        </div> 
                                        {(isEdit) && (<>                            
                                            <label className="col-form-label col-sm-1 text-right pnlAdd">Block <span className="red">*</span></label>
                                            <div className="form-group col-sm-2 pnlAdd">
                                                <div className="input-group">                                            
                                                    <Select id="ddlBlock" name="ddlBlock" className="width-95p" 
                                                        options={pageData.teeBlocks}
                                                        value={selectedBlock || ''} 
                                                        onChange={(v) => setSelectedBlock(v)} 
                                                    />
                                                </div>
                                            </div>

                                            <label className="col-form-label col-sm-1 text-right pnlAdd">Tee Box <span className="red">*</span></label>
                                            <div className="form-group col-sm-1 pnlAdd">
                                                <div className="input-group">
                                                    <select id="ddlTeeBox" name="ddlTeeBox" className="form-control"
                                                        value={newTemplate.TeeBox} onChange={({target}) => dataHandler("TeeBox", target.value)}
                                                    >
                                                        <option value="1">1</option>
                                                        <option value="4">4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-4 pnlAdd d-flex">
                                                <label className="col-form-label  text-right pnlAdd width-55 margin-lr20"> Slot </label>
                                                <div className="input-group timepicker width-170  margin-r20">
                                                    <input id="txtSlot" name="txtSlot" type="text" className="form-control" 
                                                        readOnly={true} placeholder="Select time" 
                                                        value={ newTemplate.Slot || '' }                                               
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text height-38">
                                                            <i className="la la-clock-o"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                {(!isUpdate) ? (
                                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0  height-38`} onClick={postTemplate} ><i className="la la-plus"></i>Add</button>
                                                ):(
                                                    <div className="btn-group  height-38">
                                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0 `} onClick={postTemplate} > Update </button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearActions}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                    </div>
                                                )}
                                            </div>
                                        </>) }                                  
                                    
                                    </div>                                
                                </div>
                            </form>
                            <table id="table-block" className="table table-bordered table-hover margin-b30">
                                <thead className="light-grey-background">
                                    <tr>
                                        <th>Block</th>
                                        <th>Tee</th>
                                        <th>Slot</th>
                                        <th>Active</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(allTemplates.length > 0 )?
                                        allTemplates.map((t,i) => {
                                            return (<tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                                <td>{t.Block}</td>
                                                <td>{t.TeeBox}</td>
                                                <td>{t.Slot}</td>
                                                <td className="padding-0" width="15%">
                                                    <span className="kt-switch kt-switch--sm kt-switch--icon margin-lr10">
                                                        <label className="margin-b0 padding-t5">
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox" 
                                                                defaultChecked={t.Active == 'N' ? '' : 'checked'}
                                                                onChange={(event) => onCheck({...t, Active: event.target.checked ? 'Y' : 'N'})} 
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </td>
                                                <td className="text-center">
                                                    {(isEdit) && (<>
                                                        <button type="button" disabled={isUpdate} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit mr-2"
                                                            onClick={() => editHandler(t.ID)}
                                                            >
                                                            <i className="flaticon2-edit"></i>
                                                        </button>
                                                        <button type="button" disabled={isUpdate} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"
                                                            onClick={() => deleteHandler(t.ID)}
                                                        >
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>                                                    
                                                    </>
                                                    )}
                                                </td>
                                            </tr>)
                                        }):
                                        (<tr><td colSpan={4}> 
                                            <div className="padding-10"> 
                                                <h5 className="text-center text-muted"> No Slots Available..! </h5> 
                                            </div>
                                        </td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaggeredTemplate;