import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet } from "./axiosFunctions";

const getFnbMenuCategoryUrl                                 = '/api/admin/fnb/getFnbMenuCategory';
const getFnbMenuSubCategoryUrl                              = '/api/admin/fnb/getFnbMenuSubCategory';
const getFnbMenuItemByCategoryIDUrl                         = (categoryID) => `/api/admin/fnb/getFnbMenuItemByCategoryID/${categoryID}`;
const getFnbMenuSubCategoryByCategoryIDUrl                  = (categoryID) => `/api/admin/fnb/getFnbMenuSubCategoryByCategoryID/${categoryID}`;
const getFnbMenuItemByCategoryIDAndSubcategoryIDUrl         = (categoryID, subcategoryID) => `/api/admin/fnb/getFnbMenuItemByCategoryIDAndSubcategoryID/${categoryID}/${subcategoryID}`;
const getTaxTypeUrl                                         = '/api/admin/fnb/getTaxType';
const getTaxStructureByTaxTypeIDUrl                         = (taxTypeID) => `/api/admin/fnb/getTaxStructureByTaxTypeID/${taxTypeID}`;
const getFnbRateMasterByMenuItemIDUrl                       = (menuItemID) => `/api/admin/fnb/getFnbRateMasterByMenuItemID/${menuItemID}`;
const postFnbMenuCategoryUrl                                = '/api/admin/fnb/postFnbMenuCategory';
const postFnbMenuSubCategoryUrl                             = '/api/admin/fnb/postFnbMenuSubCategory';
const postFnbMenuItemUrl                                    = '/api/admin/fnb/postFnbMenuItem';
const postTaxTypeUrl                                        = '/api/admin/fnb/postTaxType';
const postTaxStructureUrl                                   = '/api/admin/fnb/postTaxStructure';
const postFnbRateMasterUrl                                  = '/api/admin/fnb/postFnbRateMaster';

export const getFnbMenuCategory = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getFnbMenuCategoryUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getFnbMenuSubCategory = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getFnbMenuSubCategoryUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getFnbMenuItemByCategoryID = async (categoryID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getFnbMenuItemByCategoryIDUrl(categoryID), { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getFnbMenuSubCategoryByCategoryID = async (categoryID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getFnbMenuSubCategoryByCategoryIDUrl(categoryID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

export const getFnbMenuItemByCategoryIDAndSubcategoryID = async (categoryID, subcategoryID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getFnbMenuItemByCategoryIDAndSubcategoryIDUrl(categoryID, subcategoryID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

export const getTaxType = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getTaxTypeUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getTaxStructureByTaxTypeID = async (taxTypeID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getTaxStructureByTaxTypeIDUrl(taxTypeID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

export const getFnbRateMasterByMenuItemID = async (menuItemID, taxTypeID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getFnbRateMasterByMenuItemIDUrl(menuItemID, taxTypeID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};  

export const postFnbMenuCategory = async (menuDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postFnbMenuCategoryUrl, { menuDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const postFnbMenuSubCategory = async (menuDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postFnbMenuSubCategoryUrl, { menuDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const postFnbMenuItem = async (menuList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postFnbMenuItemUrl, { menuList }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};
  
export const postTaxType = async (taxTypeDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postTaxTypeUrl, { taxTypeDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const postTaxStructure = async (taxStructureDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postTaxStructureUrl, { taxStructureDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const postFnbRateMaster = async (masterDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postFnbRateMasterUrl, { masterDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};
  
