import { useEffect, useState } from "react";
import Dropzone from "../global/dropzone";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { validateMonthlyPaymentReport, getPrimaryMembersForMonthlyReport } from "../api/paymentAPI";
import * as XLSX from 'xlsx';
import ValidateMember from "./validateMember";

const AddMonthlyPaymentFile = (props) => {

    const [excelDocument,                   setExcelDocument]         = useState(null);
    const [memberList,                      setMemberList]            = useState([]);

    useEffect(() => {
        $('#add-excel-modal').modal({
            backdrop: 'static',
        });
        $('#add-excel-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);   
        });
    });

    useEffect(() => {
       if(excelDocument){
           validateHandler(excelDocument);
       }; 
    },[excelDocument]);

    const documentHandler = async (d) => {
        setExcelDocument(d);
    };  

    const removeDocumentHandler = async(e) => {
		e.preventDefault();	
        setExcelDocument(null)
	};
    
    const fetchExcelSheet = async(e) => {
        e.preventDefault();	

        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getPrimaryMembersForMonthlyReport(props.selectedMonth);
            const workbook = XLSX.utils.book_new();
        
            // Convert the data array to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(response);
            
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            
            // Generate a binary string from the workbook
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            
            // Create a Blob from the binary string
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx');
            link.click();

            swal.close();
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };
    
    const validateHandler = async() => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await validateMonthlyPaymentReport(excelDocument, props.selectedMonth);
            setMemberList(response)
            swal.close();
   
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }
    };
    
    const closeDrawer = () =>{
        setExcelDocument(null)
    };

    return(
        <>
            <div className="modal fade"id="add-excel-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Upload File</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-document-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <div className="btn-holder text-center">
                                                <button className="btn btn-label-brand"
                                                    onClick={fetchExcelSheet}
                                                >
                                                    <i className="fa fa-download" />
                                                    Download Template for Monthly Payment
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            {excelDocument  ? (
                                                <div className="kt-notification  margin-b0" style={{backgroundColor: '#f7f8fa' }} > 
                                                    <a className="kt-notification__item " >
                                                        <div className="kt-notification__item-icon margin-r10" >
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed" >
                                                                <img src={helper.getFileLogo(excelDocument.name || excelDocument)} alt="user-profile"  height="30px" width="30px"/>
                                                            </div>
                                                        </div>
                                                        <div className="kt-notification__item-details">
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {excelDocument.name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"
                                                            style={{width: "40px", height:"40px"}}                    
                                                            onClick={removeDocumentHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            ):(
                                                <Dropzone fileHandler={documentHandler} IsExcel/>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {memberList && memberList.length > 0 && (
                <ValidateMember
                    show={memberList}
                    onDismissModal={setMemberList}                          
                    memberList={memberList}
                    date={props.selectedMonth}        
                    refetchReport={() => props.refetchReport()}
                  
                />
            )}
        </>
    )
}

export default AddMonthlyPaymentFile;