import React, {useState, useEffect} from 'react'

const FeedBackModal = ({
    show,
    feedBack,
    onDismissModal
}) => {

    useEffect(() => {
        $('#feedBack-detail-modal').modal({
          backdrop: 'static',
        });
        $('#feedBack-detail-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            onDismissModal(); 
        });
    }, [show]);

    return (
        <>
            <div
                className="modal fade"
                id="feedBack-detail-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Feedback Details</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"                               
                                aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body grey-background">
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <div className="kt-widget kt-widget--user-profile-3">
                                        <div className="kt-widget__top">
                                            {feedBack.Photo && feedBack.Photo != '' ? 
                                                <img className="kt-widget__pic kt-widget__pic" src={feedBack.Photo} alt="user-profile"/> :
                                                <div
                                                    className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest `}>
                                                    {feedBack.Name.slice(0, 1)}
                                                </div>
                                            }   
                                            <div className="kt-widget__content">
                                                <div className="kt-widget__head">
                                                    <span className="kt-widget__username">
                                                        {feedBack.Name}{' '}
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span className={`kt-badge kt-badge--success kt-badge--inline kt-badge--pill padding-lr10`}>
                                                            {feedBack.MembershipType}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="kt-widget__subhead padding-t0">
                                                    <span className="padding-r10">
                                                        <i className="flaticon2-calendar-3"></i>
                                                        {feedBack.Category} 
                                                    </span>
                                                    <span className="padding-r10">
                                                        <i className="flaticon2-user"></i>
                                                        {feedBack.MembershipNo}
                                                    </span>
                                                    <br></br>
                                                    {feedBack.Mobile && (
                                                        <span className="padding-r10">
                                                        <i className="flaticon2-phone"></i>
                                                        {feedBack.Mobile}
                                                        </span>
                                                    )}
                                                    {feedBack.Email && (
                                                        <span className="padding-r10">
                                                            <i className="flaticon2-new-email"></i>
                                                            {feedBack.Email}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-t0">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        <p 
                                            id="txtMessage" 
                                            className="text-desc"
                                        >{feedBack.Message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">
                                Close
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedBackModal