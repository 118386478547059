import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getTaxType } from "../api/fnbAPI";
import StructureModal from "./structureModal";

const TaxStructure = (props) => {
    
    const [taxType,                 setTaxType]                 = useState({});
    const [taxTypeList,             setTaxTypeList]             = useState([]);
    const [isInit,                  setIsInit]                  = useState(true);
    const [rightSideDetails,        setRightSideDetails]        = useState(false);

    useEffect(() => {
        props.title("Tax Structure", "tax-structure")
        fetchTaxType();
    }, []);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setTaxType({});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [taxTypeList]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
        helper.SetScrollHeight();
    });

    const fetchTaxType = async() =>{
        try {
            const master = await getTaxType()
            isInit ? setIsInit(false) : $('#tblTaxTypeMaster').KTDatatable().destroy(); 
            setTaxTypeList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    function fillDataTable() {

        const dataTable = $('#tblTaxTypeMaster').KTDatatable({
            data: {
                saveState: false,
                source: taxTypeList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2__pic">
                                ${`<div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                            </div> 
                            <div class="kt-user-card-v2__details text-clip ml-3">
                                <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> 
                            </div>`
                    }
                }
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTaxTypeMaster .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {

            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTaxType(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Master</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTaxTypeMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Tax Structure Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        <div
                                                            className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-`}>
                                                            {taxType.Name.slice(0, 1)}
                                                        </div>
                                                         <div className="kt-widget__content">
                                                            <div className="kt-widget__head padding-b0">
                                                                <span className="kt-widget__username">
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {taxType.Name}{' '}&nbsp;&nbsp;
                                                                        <span
                                                                            type="button"
                                                                            className={`kt-badge kt-badge--inline kt-badge--pill padding-lr10`}
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover"
                                                                            data-placement="right"
                                                                            data-content={taxType.Description}
                                                                        >
                                                                            <i className="la la-exclamation-circle" style={{ color: 'grey', fontSize: "26px", transform: 'rotate(180deg)' }}></i>
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                        <StructureModal
                                            taxType = {taxType}
                                        />
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default TaxStructure;