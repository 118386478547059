import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";



const getAdvertisementMasterAllUrl                                = '/api/admin/advertisement/getAdvertisementMasterAll';
const getAdvertisementPostAllUrl                                  = '/api/admin/advertisement/getAdvertisementPostAll';
const getAdvertisementMediaByAdvertisementIDUrl                   = (id) => `/api/admin/advertisement/getAdvertisementMediaByAdvertisementID/${id}`
const postAdvertisementMasterUrl                                  = '/api/admin/advertisement/postAdvertisementMaster';
const postAdvertisementPostUrl                                    = '/api/admin/advertisement/postAdvertisementPost';

    
export const getAdvertisementMasterAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAdvertisementMasterAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdvertisementPostAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAdvertisementPostAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdvertisementMediaByAdvertisementID = async (ID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAdvertisementMediaByAdvertisementIDUrl(ID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAdvertisementMaster = async (advertisementDetails, mediaList) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAdvertisementMasterUrl,
        { advertisementDetails, mediaList},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postAdvertisementPost = async (advertisementDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAdvertisementPostUrl,
        { advertisementDetails},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
