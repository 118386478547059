import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAllFacilityMasterBySportID, postFacilityMaster } from "../api/sportsAPI";

const FacilityBlock = (props) => {

    const {sports} = props

    const [allFacilities,      setAllFacilities]     = useState([]);
    const [newFacility,        setNewFacility]       = useState({SportID: sports.ID, Active: "Y"});
    const [isEdit,             setIsEdit]            = useState(false);

    useEffect(() => {
        if(sports){
            fetchFacilityMasterBySportID(sports.ID);
            AllowSortable()
        }
    },[sports])

    const dataHandler = (field,value) => setNewFacility(d => ({...d, [field]: value}));

    const editHandler = (id) => {
        setIsEdit(true);
        const d = allFacilities.find(a => a.ID == id);
        setNewFacility(d)
    }

    const postFacility = async() => {
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addBlockForm');

        form.validate({
            rules: { txtName: { required: true }},
            messages: { txtName : { required: "Please enter facility name" }}
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);
        try {
            if(!isEdit) newFacility.Index = allFacilities.length + 1;       
            
            saveBlocks([newFacility]);                  
            
            helper.StopProcessing(btn);                  
            if (newFacility.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Facility updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Facility added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const saveBlocks = async(facility) => {
        if(facility.length <= 0) return;
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            const res = await postFacilityMaster(facility);          
            setAllFacilities(res);
                    
            swal.close()
        } catch (err) {
            swal.close()
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    }

    const fetchFacilityMasterBySportID = async(id) => {
        try {
            const master = await getAllFacilityMasterBySportID(id)
            setAllFacilities(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    }

    //Sorting -->
    const AllowSortable =() =>{

        const config = (event, ui) =>{
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    }

    const sortAndSave = (param)=> {
        if(param.length <= 0) return;      
        let facilities = [];        

        setAllFacilities( d => {
            const sortedFacilities = [];
            
            for (const [i,id] of Object.entries(param)) {
                let f = d.find( a => a.ID == id);
                if(!!f){
                    f.Index = parseInt(i)+1;                    
                    sortedFacilities.push(f);
                }
            }

            facilities = sortedFacilities;
            return d;
        });
                    
        if(facilities.length > 0) saveBlocks(facilities);                              
    }

    const clearAction = () => {       
        setNewFacility({SportID: sports.ID, Active: "Y"});
        setIsEdit(false);
    } 

    const onCheck = async(value) => {
        saveBlocks([value]);                  

    }

    return(
        <>
            <div className="kt-portlet__body padding-t20 padding-b0">
                <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="addBlockForm" >                
                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form padding-l150" role="form">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-3 text-right"> Name </label>
                            <div className="col-xl-3 col-sm-3">
                                <div className="input-group timepicker">
                                    <input 
                                        id="txtName" name="txtName" type="text" className="form-control" 
                                        value={ newFacility.Name || '' }
                                        onChange={({target}) => dataHandler("Name", target.value)} 
                                    />
                                </div>                                      
                            </div>
                            <div className="col-sm-3">
                                {(!isEdit) ? (
                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0`} onClick={postFacility} ><i className="la la-plus"></i> Add </button>
                                ):(
                                    <div className="btn-group">
                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postFacility} > Update </button>
                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <table id="table-block" className="table table-bordered table-hover margin-b30">
                    <thead className="light-grey-background">
                        <tr>
                            <th  width="60%" > Name </th>
                            <th className="text-center" width="10%" > Active </th>
                            <th  width="10%" >  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(allFacilities.length > 0 )?
                            allFacilities.map((t,i) => {
                                return (
                                    <tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                        <td width="60%">{t.Name}</td>
                                        <td className="text-center" width="10%">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input 
                                                        id="chkActive" 
                                                        name="chkActive" 
                                                        type="checkbox" 
                                                        defaultChecked={t.Active == 'N' ? '' : 'checked'}
                                                        onChange={(event) => onCheck({...t, Active: event.target.checked ? 'Y' : 'N'})} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </td>
                                        <td className="text-center" width="10%">
                                            <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>
                                        </td>
                                    </tr>
                                )
                            }):
                            (
                            <tr>
                                <td colSpan={3}> 
                                    <div className="padding-10"> 
                                        <h5 className="text-center text-muted"> No Facilities..! </h5> 
                                    </div>
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FacilityBlock;