import { useEffect, useState } from "react";
import { getCourseAll, getScoreCardMasterByTeeBoxID, getTeeBoxByCourseID, postScoreCardMaster } from "../api/golfClubAPI";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';

const ScorecardMaster = (props) => {

    const [courseList,                     setCourseList]               = useState([]);
    const [course,                         setCourse]                   = useState();
    const [teeBoxList,                     setTeeBoxList]               = useState([]);
    const [teeBox,                         setTeeBox]                   = useState(null);
    const [scoredcardList,                 setScorecardList]            = useState([]);
    

    useEffect(() => {
        props.title('Scorecard Master','scorecard-master')
        fetchCourse();

    },[])  
    
    useEffect(() => {
        if(!!course){
            fetchTeeBox(course);
        }
    },[course])

    useEffect(() => {
        if(!!teeBox){
            fetchScorecardMaster(teeBox);
        }
    },[teeBox])

    useEffect(() => {
        if(scoredcardList){
            createRow('#table-front', scoredcardList);
            createRow('#table-back', scoredcardList);        
        }
    },[scoredcardList])

    const fetchScorecardMaster = async(id) =>{
        try {
            const response = await getScoreCardMasterByTeeBoxID(id);
            setScorecardList(response.ScoreCardMaster);
            $('#lblTotalYard').text(response.YardCount);
            $('#lblTotalPar').text(response.ParCount);
            
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchCourse = async() => {
        try {
            const response = await getCourseAll()
            setCourseList(response);
            
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchTeeBox = async(id) => {
        try {
            const response = await getTeeBoxByCourseID(id)
            setTeeBoxList(response);
            setTeeBox(null)
            
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    
    const addScorecardMaster = async() => {
        var scoreCards = [];
        $('#table-front > tbody > tr').each(function (index, element) {
            
            if (index == 9) return false;

            var id      = $(this).attr('data-id');
            var hole    = $(this).find('.score-hole').html();
            var yard    = $(this).find('[id^=txtYard]').val();
            var par     = $(this).find('[id^=txtPar]').val();
            var stroke  = $(this).find('[id^=txtStroke]').val();
            
            scoreCards.push({
                ID         : id,
                Par        : par,
                Type       : 'OUT',
                Yard       : yard,
                HoleNo     : hole,
                Stroke     : stroke,
                TeeBoxID   : teeBox,
                HoleNoValue: hole,
            });
        });
        $('#table-back > tbody > tr').each(function (index, element) {

            if (index == 9) return false;

            var id      = $(this).attr('data-id');
            var hole    = $(this).find('.score-hole').html();
            var yard    = $(this).find('[id^=txtYard]').val();
            var par     = $(this).find('[id^=txtPar]').val();
            var stroke  = $(this).find('[id^=txtStroke]').val();
            
            scoreCards.push({
                ID         : id,
                Par        : par,
                Type       : 'IN',
                Yard       : yard,
                HoleNo     : hole - 9,
                Stroke     : stroke,
                TeeBoxID   : teeBox,
                HoleNoValue: hole,
            });
        });
        
        var isEmpty = false;
        $.each(scoreCards, function (index, element) {

            if (element.Yard == "" || element.Yard == undefined || element.Yard == 0) {
                isEmpty = true;
                return false;
            }
            if (element.Par == "" || element.Par == undefined || element.Par == 0) {
                isEmpty = true;
                return false;
            }
            if (element.Stroke == "" || element.Stroke == undefined || element.Stroke == 0) {
                isEmpty = true;
                return false;
            }
        });

        if (isEmpty) {
            Swal.fire({
                icon: 'warning',
                titleText: 'Please fill entire scorecards',
                timer: 1500,
                animation: false,
                showConfirmButton: false,
            });
            return;
        }
        else {
            Swal.close();
        }

        var isNumber = true;
        $.each(scoreCards, function (index, element) {

            if (!$.isNumeric(element.Yard)) {
                isNumber = false;
                return false;
            }
            if (!$.isNumeric(element.Par)) {
                isNumber = false;
                return false;
            }
            if (!$.isNumeric(element.Stroke)) {
                isNumber = false;
                return false;
            }
        });
        
        if (!isNumber) {
            Swal.fire({
                icon: 'warning',
                titleText: 'Please enter only numbers in score scoreCards',
                timer: 1500,
                animation: false,
                showConfirmButton: false,
            });
            return;
        }
        else {
            Swal.close();
        }
        
        try {
            await postScoreCardMaster(scoreCards)
            fetchScorecardMaster(teeBox);
            if (scoreCards[0].ID == 0) {
                swal.fire({
                    icon: 'success',
                    titleText: 'Scorecard added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }else{
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Scorecard updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
            
        }
    }

    const calculate = () => {
        $("#cardHolder").on('change' , function(e) {
            var totalYard = 0;
            var finalTotalYard = 0;
            var totalPar = 0;
            var finalTotalPar = 0;

            const tableName = e.target.closest('table').id  //get the table name

            //yard input
            for (var i = ((tableName == 'table-front') ? 1 : 10); i < ((tableName == 'table-front') ? 10 : 19); i++)
            totalYard += parseInt($('#txtYard' + i).val());

            $('#' + tableName).find('#lblYard').text(totalYard);

            for (var i = 1; i < 19; i++)
            finalTotalYard += parseInt($('#txtYard' + i).val());
            $('#lblTotalYard').text(finalTotalYard)

            //par input
            for (var i = ((tableName == 'table-front') ? 1 : 10); i < ((tableName == 'table-front') ? 10 : 19); i++)
            totalPar += parseInt($('#txtPar' + i).val());

            $('#' + tableName).find('#lblPar').text(totalPar);

            for (var i = 1; i < 19; i++)
            finalTotalPar += parseInt($('#txtPar' + i).val());
            $('#lblTotalPar').text(finalTotalPar)

        });


    }

    function createRow(table, scoreCard) {
        calculate()
        
        $(table + ' > tbody').empty();
        
        if (scoreCard.length == 0) {
            
            for (var i = (table == '#table-front') ? 0 : 9; i < ((table == '#table-front') ? 9 : 18); i++) {
 
                var row = '';

                row += '<tr data-id="0">';
                row += '    <td class="score-hole">' + (i + 1) + '</td>';
                row += '    <td class="score-yard"><input id="txtYard' + (i + 1) + '"  name="txtYard' + (i + 1) + '" type="text" class="form-control input-sm" value="0"   /></td>';
                row += '    <td class="score-par"><input id="txtPar' + (i + 1) + '"  name="txtPar' + (i + 1) + '" type="text" class="form-control input-sm" value="0"   /></td>';
                row += '    <td class="score-stroke"><input id="txtStroke' + (i + 1) + '"  name="txtStroke' + (i + 1) + '" type="text" class="form-control input-sm" value="0"   /></td>';
                row += '</tr>';
                
                $(table + ' > tbody').append(row);

                $('#txtYard' + (i + 1)).forceNumeric();
                $('#txtPar' + (i + 1)).forceNumeric();
                $('#txtStroke' + (i + 1)).forceNumeric();
            }

            var out = '';

            out += '<tr style="background-color:#eee;">';
            out += '    <td class="out-hole">OUT</td>';
            out += '    <td class="out-yard"><span id="lblYard"></span></td>';
            out += '    <td class="out-par"><span id="lblPar"></span></td>';
            out += '    <td class="out-stroke"><span id="lblStroke"></span></td>';
            out += '</tr>';

            $(table + ' > tbody').append(out);

            $(table).find('#lblYard').text(0);
            $(table).find('#lblPar').text(0);
        }
        else {

            var totalYard = 0;
            var totalPar = 0;

            for (var i = (table == '#table-front') ? 0 : 9; i < ((table == '#table-front') ? 9 : 18); i++) {

                var element = scoreCard[i];
                var row = '';

                row += '<tr data-id="' + element.ID + '">';
                row += '    <td class="score-hole">' + (i + 1) + '</td>';
                row += '    <td class="score-yard"><input id="txtYard' + (i + 1) + '"  name="txtYard' + (i + 1) + '" type="text" class="form-control input-sm" value="' + element.Yard + '" /></td>';
                row += '    <td class="score-par"><input id="txtPar' + (i + 1) + '"  name="txtPar' + (i + 1) + '" type="text" class="form-control input-sm"    value="' + element.Par + '" /></td>';
                row += '    <td class="score-stroke"><input id="txtStroke' + (i + 1) + '"  name="txtStroke' + (i + 1) + '" type="text" class="form-control input-sm" value="' + element.Stroke + '" /></td>';
                row += '</tr>';

                $(table + ' > tbody').append(row);

                $('#txtYard' + (i + 1)).forceNumeric();
                $('#txtPar' + (i + 1)).forceNumeric();
                $('#txtStroke' + (i + 1)).forceNumeric();

                totalYard += parseInt(element.Yard);
                totalPar += parseInt(element.Par);
            }

            var out = '';

            out += '<tr style="background-color:#eee;">';
            out += '    <td class="out-hole">OUT</td>';
            out += '    <td class="out-yard"><span id="lblYard"></span></td>';
            out += '    <td class="out-par"><span id="lblPar"></span></td>';
            out += '    <td class="out-stroke"><span id="lblStroke"></span></td>';
            out += '</tr>';

            $(table + ' > tbody').append(out);

            $(table).find('#lblYard').text(totalYard);
            $(table).find('#lblPar').text(totalPar);
        }

    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop: '0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body kt-portlet__body--fit padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 border-bottom-light" role="form">
                                <div className="row">
                                    <div className="col-sm-3"></div>
                                    <div className="form-group col-sm-2">
                                        <div className="ddlCoursePnl">
                                            <Select
                                                value={(courseList && courseList.length > 0 && course) && {label: courseList.find(c => c.ID == course).Name, value: course}}
                                                onChange={(event) => setCourse(event.value)}
                                                name="ddlCourse"
                                                placeholder="Select Course"
                                                id="ddlCourse"
                                                className="ddlCourse"
                                                options={courseList.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />                                        
                                        </div>
                                    </div>
                                    <div className="col-sm-1"></div>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select
                                                value={(teeBoxList && teeBoxList.length > 0 && teeBox) && {label: teeBoxList.find(c => c.ID == teeBox).Name, value: teeBox}}
                                                onChange={(event) => setTeeBox(event.value)}
                                                name="ddlTeeBox"
                                                placeholder="Select Tee Box"
                                                id="ddlTeeBox"
                                                className="ddlTeeBox"
                                                options={teeBoxList.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />                                              
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {teeBox != null && scoredcardList && (
                                <div id="cardHolder">
                                    <div className="row  margin-lr10">
                                        <div className="col-md-6">
                                            <table id="table-front" className="table table-bordered">
                                                <caption> <h6> Front Score Card </h6> </caption>
                                                <thead style={{backgroundColor: '#eee'}}>
                                                    <tr>
                                                        <th style={{width: '10%'}}>Hole</th>
                                                        <th style={{width: '25%'}}>Yard</th>
                                                        <th style={{width: '25%'}}>Par</th>
                                                        <th style={{width: '25%'}}>Stroke</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td><input id="" name="" type="number"  className="form-control input-sm"
                                                        //  onChange="" onKeyUp="" 
                                                            /></td>
                                                        <td><input id="" name="" type="number" className="form-control input-sm" /></td>
                                                        <td><input id="" name="" type="number" className="form-control input-sm" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <table id="table-back" className="table table-bordered">
                                                <caption> <h6> Back Score Card </h6></caption>
                                                <thead style={{backgroundColor: '#eee'}}>
                                                    <tr>
                                                        <th style={{width: '10%'}}>Hole</th>
                                                        <th style={{width: '25%'}}>Yard</th>
                                                        <th style={{width: '25%'}}>Par</th>
                                                        <th style={{width: '25%'}}>Stroke</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row mb-2 margin-lr10">
                                        <div className="col-md-10 d-flex align-items-center ">
                                            <div className="text-left">
                                                <strong>Total:</strong> Yard = <span id="lblTotalYard">0</span> | Par = <span id="lblTotalPar">0</span>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" id="btnSave" name="btnSave" className="btn btn-primary" onClick={addScorecardMaster}>
                                                <i className="fa fa-save"></i> Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScorecardMaster;