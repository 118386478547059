import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import { getSubscriptionChargesByDateAndSubID, getSubscriptionMasterByMembershipType, postOfflineSubscription } from "../api/paymentAPI";

const UpdateSubscriptionModal = (props) => {

    const {subscriptionDetails, setSubscriptionDetails, from, subscriptionValidity, userDetails, refetchValidity, vIndex} = props;

    const [subscriptionValidityDetails, setSubscriptionValidityDetails]     = useState({});    
    const [subscriptionTypeOption,      setSubscriptionTypeOption]          = useState([]);
    const [subID,                       setSubID]                           = useState();

    useEffect(() => {
        $('#add-updateSub-modal').modal({
            backdrop: 'static',
        });
        $('#add-updateSub-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            $(from).removeClass('modal-blur');
            props.onDismissModal(false);
        });
        $(from).addClass('modal-blur');  
        $('#txtSubscriptionExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionExpiresOn').on('change', function (event) {
            updateChargeDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });  
        $('#txtSubscriptionStartsFrom').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionStartsFrom').on('change', function (event) {
            updateChargeDetails('SubscriptionStartsFrom',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });  
    }, []);
    
    useEffect(() => {
        if (userDetails.MembershipType != '') {
            fetchSubscriptionMaster(userDetails.MembershipType);
        };
      }, [userDetails.MembershipType]);
    
    useEffect(() => {
        if (subID && !subscriptionValidityDetails?.OrderID) {
            fetchSubscriptionChargesByDateAndSubID(subID);
        };
      }, [subID]);

    useEffect(() => {
        if (Object.keys(subscriptionDetails).length > 0) {
            if(from){
                setSubscriptionValidityDetails({...subscriptionDetails, Charge: subscriptionDetails?.Charge?.replace(/,/g, ""), GSTAmount: subscriptionDetails?.GSTAmount?.replace(/,/g, ""), Total: subscriptionDetails?.Total?.replace(/,/g, "")});
            }else{
                setSubscriptionValidityDetails(subscriptionDetails)
            }
        };
      }, [subscriptionDetails]);
      
    useEffect(() => {
    if (subscriptionValidityDetails && subscriptionValidityDetails.Charge && subscriptionValidityDetails.GST) {
            const { Charge, GST } = subscriptionValidityDetails;
            
            // Calculate GSTAmount and Total using Math.floor for rounding down
            const GSTAmount = Math.floor((Charge * GST) / 100);
            const Total = GSTAmount + Math.floor(Charge);
            
            // Update only if there's a change to prevent unnecessary re-renders
            if (GSTAmount !== subscriptionValidityDetails.GSTAmount || Total !== subscriptionValidityDetails.Total) {
                setSubscriptionValidityDetails(d => ({ ...d, GSTAmount, Total }));
            }
        }
        
    }, [subscriptionDetails, Math.round(parseFloat(subscriptionValidityDetails.Charge)), Math.round(subscriptionValidityDetails.GST)]);
    
    const fetchSubscriptionMaster = async(membershipType) =>{
        try {
            const response = await getSubscriptionMasterByMembershipType(membershipType)
            setSubscriptionTypeOption(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchSubscriptionChargesByDateAndSubID = async(id) =>{
        try {
            const response = await getSubscriptionChargesByDateAndSubID(id)
            if(Object.keys(response).length > 0){
                setSubscriptionValidityDetails({...response, Charge: response?.Charge?.replace(/,/g, ""), GSTAmount: response?.GSTAmount?.replace(/,/g, ""), Total: response?.Total?.replace(/,/g, "")});
            }
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const addChargeHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-sub-form');
        
        form.validate({
            rules: {    
                ddlSubscriptionPlan: {
                    required: true
                },          
                txtSubscriptionStartsFrom: {
                    required: true,
                },         
                txtSubscriptionExpiresOn: {
                    required: true,
                },         
                txtCharge: {
                    required: true
                },          
                txtGST: {
                    required: true
                }          
            },
            messages: {
                ddlSubscriptionPlan: {
                    required: "Please select subscription plan"
                },
                txtSubscriptionStartsFrom: {
                    required: "Please select subscription starts from"
                },
                txtSubscriptionExpiresOn: {
                    required: "Please select subscription expires On"
                },
                txtCharge: {
                    required: "Please enter charge"
                },
                txtGST: {
                    required: "Please enter gst"
                }
            }
        });
        
        if (!form.valid()) return;
        if((moment(subscriptionValidityDetails.SubscriptionStartsFrom).isAfter(subscriptionValidityDetails.SubscriptionExpiresOn)) || subscriptionValidity.find((d) => d.OrderID !== subscriptionValidityDetails.OrderID && (moment(subscriptionValidityDetails.SubscriptionStartsFrom) < moment(d.SubscriptionStartsFrom) && moment(subscriptionValidityDetails.SubscriptionExpiresOn) > moment(d.SubscriptionExpiresOn)  || moment(subscriptionValidityDetails.SubscriptionStartsFrom).isBetween(d.SubscriptionStartsFrom, d.SubscriptionExpiresOn) || moment(subscriptionValidityDetails.SubscriptionExpiresOn).isBetween(d.SubscriptionStartsFrom, d.SubscriptionExpiresOn) || moment(subscriptionValidityDetails.SubscriptionStartsFrom).isSame(d.SubscriptionStartsFrom) || moment(d.SubscriptionStartsFrom).isSame(subscriptionValidityDetails.SubscriptionExpiresOn) || moment(subscriptionValidityDetails.SubscriptionExpiresOn).isSame(d.SubscriptionExpiresOn) || moment(d.SubscriptionExpiresOn ).isSame(subscriptionValidityDetails.SubscriptionStartsFrom)))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        };
        helper.StartProcessing(btn);

        try {

            const subDetails = {
                OrderID:                   subscriptionValidityDetails?.OrderID,
                UserID:                    userDetails.ID,
                SubscriptionID:            subscriptionValidityDetails.SubscriptionID,
                Total:                     subscriptionValidityDetails.Total,
                GST:                       subscriptionValidityDetails.GST,
                Comment:                   subscriptionValidityDetails?.Comment || '',
                GroupID:                   userDetails.GroupID,
                SubscriptionStartsFrom:    subscriptionValidityDetails.SubscriptionStartsFrom,
                SubscriptionExpiresOn:     subscriptionValidityDetails.SubscriptionExpiresOn
            };
            
            helper.StopProcessing(btn);
            $('#add-updateSub-modal').modal('hide');
            
            if(from){
                await postOfflineSubscription(subDetails)
                $('#edit-user-modal').removeClass('modal-blur');  
                refetchValidity()

            }else{
                const currentDate = new Date().toISOString()
                setSubscriptionDetails({...subDetails, ...subscriptionTypeOption.filter(f => f.ID == subDetails.SubscriptionID)[0] , Status: subDetails.SubscriptionStartsFrom <= currentDate && currentDate <= subDetails.SubscriptionExpiresOn ? "Active" : "Upcomming", OrderID: subscriptionDetails.OrderID || vIndex});
                // setSubscriptionValidityDetails({})
            };

            if (subscriptionValidityDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Subscription updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Subscription added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setSubscriptionValidityDetails({});

        form.clearForm();
        form.validate().resetForm();
    };

    const updateChargeDetails = (field, value) => { 
        if(field == 'SubscriptionID') setSubID(value);
        setSubscriptionValidityDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        setSubscriptionDetails({});
    };

    return(
        <>
            <div className="modal fade"id="add-updateSub-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Subscription</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-sub-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-4 form-group" >
                                            <label>Subscription Plan</label>
                                            <Select 
                                                id="ddlSubscriptionPlan" 
                                                name="ddlSubscriptionPlan"
                                                className="multi-custom"
                                                placeholder="Select Subscription Plan"
                                                value={(subscriptionTypeOption && subscriptionTypeOption.length > 0 && subscriptionValidityDetails.SubscriptionID) && {label: subscriptionTypeOption.find((m) => m.ID == subscriptionValidityDetails.SubscriptionID).Name, value: subscriptionValidityDetails.SubscriptionID} || ''}
                                                onChange={(event) => updateChargeDetails('SubscriptionID', event.value)}
                                                options={subscriptionTypeOption.map(m => ({
                                                    label: m.Name,
                                                    value: m.ID
                                                }))}
                                            />
                                        </div>
                                        <div className="col-4 form-group">
                                            <label>Subscription Starts From</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtSubscriptionStartsFrom" 
                                                    name="txtSubscriptionStartsFrom" 
                                                    type="text" 
                                                    placeholder="Select starts from"
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={subscriptionValidityDetails.SubscriptionStartsFrom ? moment(subscriptionValidityDetails.SubscriptionStartsFrom).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 form-group">
                                            <label>Subscription Expires On</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtSubscriptionExpiresOn" 
                                                    name="txtSubscriptionExpiresOn" 
                                                    type="text" 
                                                    placeholder="Select expires on"
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={subscriptionValidityDetails.SubscriptionExpiresOn ? moment(subscriptionValidityDetails.SubscriptionExpiresOn).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Charge</label>
                                            <input 
                                                id="txtCharge" 
                                                name="txtCharge" 
                                                type="number" 
                                                className="form-control"
                                                value={subscriptionValidityDetails.Charge  || ''}
                                                onChange={(event) => updateChargeDetails('Charge', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-2 form-group" style={{marginBottom: '1rem'}}>
                                            <label>GST %</label>
                                            <input 
                                                id="txtGST" 
                                                name="txtGST" 
                                                type="number" 
                                                className="form-control"
                                                value={subscriptionValidityDetails.GST  || ''}
                                                onChange={(event) => updateChargeDetails('GST', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-2 form-group" style={{marginBottom: '1rem'}}>
                                            <label>GST Amount</label>
                                            <input 
                                                id="txtGSTAmount" 
                                                name="txtGSTAmount" 
                                                type="number" 
                                                className="form-control"
                                                value={subscriptionValidityDetails.GSTAmount  || ''}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Total</label>
                                            <input 
                                                id="txtTotal" 
                                                name="txtTotal" 
                                                type="number" 
                                                className="form-control"
                                                value={subscriptionValidityDetails.Total  || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Remark</label>
                                            <textarea 
                                                id="txtComment" 
                                                name="txtComment" 
                                                className="form-control" 
                                                rows="3"
                                                value={subscriptionValidityDetails.Comment || ''}
                                                onChange={(event) => updateChargeDetails('Comment', event.target.value)} 
                                            ></textarea>
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addChargeHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                {subscriptionDetails?.OrderID ? 'Update Subscription' : 'Add Subscription'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateSubscriptionModal;