import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getTaxStructureByTaxTypeID, postTaxStructure } from "../api/fnbAPI";
import Select from 'react-select';


const StructureModal = (props) => {
    const {taxType} = props

    const [allTaxStructure,    setAllTaxStructure]     = useState([]);
    const [taxStructure,       setTaxStructure]        = useState({TaxTypeID: taxType.ID, Active: "Y"});
    const [isEdit,             setIsEdit]              = useState(false);
    const [taxTypeOption,      setTaxTypeOption]       = useState([{ value:  'GST', label: 'GST'}, { value:  'CGST', label: 'CGST'}, { value:  'VAT', label: 'VAT'} ]);

    useEffect(() => {
        if(taxType){
            fetchTaxStructureByTaxTypeID(taxType.ID);
        }
    },[taxType]);

    const dataHandler = (field,value) => setTaxStructure(d => ({...d, [field]: value}));

    const editHandler = (id) => {
        setIsEdit(true);
        const d = allTaxStructure.find(a => a.ID == id);
        setTaxStructure(d)
    };

    const postTaxStructureData = async() => {
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addBlockForm');

        form.validate({
            rules: { txtName: { required: true }, txtTax: { required: true }},
            messages: { txtName : { required: "Please enter name" }, txtTax : { required: "Please enter tax" }}
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);
        try {
            taxStructure.TaxTypeID = taxType.ID;

            const res = await postTaxStructure(taxStructure);          
            setAllTaxStructure(res);            
            helper.StopProcessing(btn);                  
            if (taxStructure.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Tax Structure updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Tax Structure added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const fetchTaxStructureByTaxTypeID = async(id) => {
        try {
            const master = await getTaxStructureByTaxTypeID(id)
            setAllTaxStructure(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    };

    const clearAction = () => {       
        setTaxStructure({TaxTypeID: taxType.ID, Active: "Y"});
        setIsEdit(false);
    };

    return(
        <>
            <div className="kt-portlet__body padding-t20 padding-b0">
                <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="addBlockForm" >                
                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form padding-l150" role="form">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-1 text-right"> Type </label>
                            <div className="col-xl-3 col-sm-3">
                                <div className="form-group timepicker">
                                    <Select 
                                        id="ddlName" 
                                        name="ddlName"
                                        className="multi-custom"
                                        placeholder="Select Type"
                                        value={taxStructure && taxTypeOption.find((m) => m.value == taxStructure.Name) || ''}
                                        onChange={(event) => dataHandler('Name', event.value)}
                                        options={taxTypeOption}

                                    />
                                </div>                                      
                            </div>
                            <label className="col-form-label col-sm-1 text-right"> Tax </label>
                            <div className="col-xl-3 col-sm-3">
                                <div className="input-group timepicker">
                                    <input 
                                        id="txtTax" name="txtTax" type="number" className="form-control" 
                                        value={ taxStructure.Tax || '' }
                                        onChange={({target}) => dataHandler("Tax", target.value)} 
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fa fa-percentage"></i>
                                        </span>
                                    </div>
                                </div>                                      
                            </div>
                            <div className="col-sm-3">
                                {(!isEdit) ? (
                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0`} onClick={postTaxStructureData} ><i className="la la-plus"></i> Add </button>
                                ):(
                                    <div className="btn-group">
                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={postTaxStructureData} > Update </button>
                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <table id="table-block" className="table table-bordered table-hover margin-b30">
                    <thead className="light-grey-background">
                        <tr>
                            <th  width="60%" > Type </th>
                            <th className="text-center" width="10%" > Tax </th>
                            <th  width="10%" >  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(allTaxStructure.length > 0 ) ?
                            allTaxStructure.map((t,i) => {
                                return (
                                    <tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                        <td width="60%">{t.Name}</td>
                                        <td width="10%">{t.Tax} %</td>
                                        <td className="text-center" width="10%">
                                            <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>
                                        </td>
                                    </tr>
                                )
                            }):
                            (
                            <tr>
                                <td colSpan={3}> 
                                    <div className="padding-10"> 
                                        <h5 className="text-center text-muted"> No Tax Structure..! </h5> 
                                    </div>
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default StructureModal