import { useEffect, useState } from "react";
import { getActiveSportsMaster, getSportsPublishMasterData, getSportsPublishPageData, postSportsPublish } from "../api/sportsAPI";
import swal from 'sweetalert2';
import RightSideDetails from "../global/rightSideDetails";
import TeeTimePublishTable from "../teeTime/teeTimePublishTable";
import Select from 'react-select';

const SportsPublish = (props) => {

    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [isInit,                     setIsInit]                     = useState(true);
    const [sportsPublishMaster,        setSportsPublishMaster]        = useState({});
    const [type,                       setType]                       = useState({'SportID':  0});
    const [sports,                     setSports]                     = useState([]);
    const [pageData,                   setPageData]                   = useState();
    const [dayData,                    setDayData]                    = useState();
    const [publishDays,                setPublishDays]                = useState([]);
    const [isCreated,                  setIsCreated]                  = useState(false);

    useEffect(() => {
        props.title("Sports Publish", "sports-publish")
        fetchSportsPublish();
        fetchSports();
    },[])
    
    useEffect(() => {       
        if (!isInit) fillDataTable(type);            
    }, [pageData]);

    useEffect(() => {
        if(type && !isInit){
            $('#tblMaster').KTDatatable().destroy(); 
            fillDataTable(type);
        }
    },[type])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()   
        }else{
            setSportsPublishMaster({});
            resetTable();  
        }
    },[rightSideDetails])

    useEffect(() => {
        //Sets  Publish Day for Update -->
        if(!!dayData){
            
            if(dayData.publishDays.length > 0){
                const newPublishDays = dayData.publishDays.map((dy => {
                   const w = dayData.publishWeek.find( f => f.ID === dy.PublishWeekID);
                   return ({...dy, MasterIndex : w.Index});
                }))
                
                setPublishDays(newPublishDays);  
            }
        }
    },[dayData])

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            setSports([{ID: 0, Name: 'All'}, ...response])
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchSportsPublish = async() => {
        try {
            const response = await getSportsPublishPageData();
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setPageData(response);         
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message });
        }
    }

    const postData = async(data) => {
       
        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            //Post and Refetch -->   
            await postSportsPublish(data);
            
            await fetchSportsPublish();
            closeDrawer();

            if(!sportsPublishMaster.ID){
                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master added successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master updated successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });
            }
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    } 

    //#region  Helpers -->

    const createDragable = ()=> {      
        let dragholder = [];
        
        //dragable-days Holder -->    
        $('.day-holder').each((i, element) => dragholder.push(element));

        //dragable-days -->
        dragholder.push(document.getElementById('days-holder'));

        window.dragInit(dragholder, onDragConfig);
    }

    const saveData = ()=> {   
        let isValid       = true;   
        const publishWeek = [];            
        
        //Validation Check -->
        if (publishDays.length !== 7) {
            swal.fire({ icon: 'warning', title: 'All days are not published', timer: 2000 });
            return;
        }
        
        $('[data-rowindex]').each((index, row) => {
            let masterIndex   = parseInt(row.getAttribute('data-rowindex'))
            let currentDay    = $(row).children('th').data('day');
            let MorningTime   = $(`#txtSlot1-${currentDay}`).val();
            let AfternoonTime = $(`#txtSlot2-${currentDay}`).val();
            
            const daysCount = publishDays.filter( d => d.MasterIndex === masterIndex).length;

            //time-validation -->
            if((MorningTime ==='' || AfternoonTime === '') && daysCount != 0 ){
                swal.fire({ icon: 'warning', title: 'Start time not selected', timer: 2500});                   
                isValid = false;
                return false;                    
            }

            const daymaster = { MorningTime, AfternoonTime, Day: currentDay, DayCount: daysCount, Index: masterIndex };
                                                        
            publishWeek.push(daymaster);
        });
        
        //Post TeeTimePublish -->          
        if (!isValid) return;
        postData({sportsPublishMaster, publishWeek, publishDays});
    }

    //Input & onChange Handler --->
    const dataHandler = (field, value)=> {
        const { latestMaster, maxOpenedDays, IsPrevMaster, validStartDate, validSkipDays } = pageData;                
        
        setSportsPublishMaster(d => ({...d, [field]: value}));
                    
        if((field != "EndDate")) resetTable();  
        
        if(!sportsPublishMaster.ID && !!latestMaster && (field == 'StartDate') && (maxOpenedDays != 0) ){              
            const StartDateDiff = Math.abs(moment(validStartDate, 'DD/MM/YYYY').diff(moment(value , 'YYYY-MM-DD'), 'days'));
            const newSkipDays   = Math.abs(validSkipDays - StartDateDiff);
                            
            if((validSkipDays != 0) && StartDateDiff <= (maxOpenedDays - (IsPrevMaster ? 0 : 1))) 
                setSportsPublishMaster(d => ({...d, ["SkipDays"]: newSkipDays}))                         
        }
    };

    const onDayDrag = (param)=> {       
        setPublishDays( d => {
            const n =  d.filter(c => c.Day !== param.Day);
            return [...n, param];
        })
    } 

    const fillDataTable = (t)=> {
        var publishMastersList = [];
        if (t.SportID == 0 ) {
            publishMastersList = pageData.publishMasters;
        }else{
            publishMastersList = pageData.publishMasters.filter(i => i.SportID == t.SportID);
        }
        const dataTable = $('#tblMaster').KTDatatable({
            data     : { saveState: false, source : publishMastersList, pageSize : 20 },
            layout   : { scroll: true, height: $(window).height() - 250, footer: false },
            sortable : true, pagination: true,
            search   : { input: $('#generalSearch')},
            columns  : [               
                {
                    field: 'Title', title: 'Sport',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.StartDate).format('YYYY-MM-DD')).isSame(moment(row.EndDate).format('YYYY-MM-DD')) ? moment(row.StartDate).format('DD MMM YYYY') : moment(row.StartDate).format('DD MMM YYYY') + ' - ' + moment(row.EndDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }                
                },
                {  field: 'SkipDays',  title: 'Skip Days', width: 80 },
            ]
        });
    
        dataTable.on('click', 'tr', function () {
            const index         = ($("tr").index(this) - 1);
            const currentMaster = dataTable.dataSet[index]
            if(!!currentMaster){
                setSportsPublishMaster({SportID: currentMaster.SportID})
                onOpenDrawer(currentMaster);                
            };
        });    
    }

    const onOpenDrawer = async(selectedMaster)=> { 
        if(!pageData) return;
        const { publishMasters, latestMaster, maxOpenedDays, validStartDate, validSkipDays } = pageData;

        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            setRightSideDetails(true);

            //Set Current Master -->
            if(!!selectedMaster){
                setSportsPublishMaster(selectedMaster);
                //get Selected Master Week And Days -->
                const masterData = await getSportsPublishMasterData(selectedMaster.ID);               
                setDayData(masterData);  
                createTable(selectedMaster);                
            }

            //Init Date picker -->
            setTimeout(() => {
                $('.date-input').datepicker({ format: 'dd-mm-yyyy', minDate: new Date()});                
                $('#txtStartDate').on('change', ({target}) => dataHandler('StartDate', moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
                $('#txtEndDate').on('change', ({target}) => dataHandler('EndDate', moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));          
            }, 200); 
            
            //StartTime & Skipdays AS per previously opened master if Exist -->
            if((sportsPublishMaster && sportsPublishMaster.SportID && publishMasters.find(f => f.SportID == sportsPublishMaster.SportID)) && (publishMasters.length != 0) && !!latestMaster && !selectedMaster) {
                if(maxOpenedDays != 0) dataHandler("SkipDays", validSkipDays);
                else dataHandler("SkipDays", 1);                                      
                
                dataHandler("StartDate", moment(validStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
            }
                
            swal.close();
        } catch (error) {
            swal.close();
            swal.fire({ icon :'error', titleText : error.message });
        }                           
    }
    

    const createTable = (master)=> {
        if(!master) return;
                                                
        const form = $('#addMasterForm');
        form.validate({
            rules: { ddlSport: { required: true }, txtStartDate: { required: true }, txtEndDate  : { required: true }, txtskipdays : { required: true } },
            messages: {
                ddlSport:     { required: "Please select sport"},
                txtStartDate: { required: "Please select Start Date"},
                txtEndDate  : { required: "Please select End Date"},
                txtskipdays : { required: "Please enter skip days" },
            }
        });

        if (!form.valid()) return;

        const isValid = validateMaster(master);
        if (!isValid) return;
                                
        setIsCreated(true);         
                  
        if (!!master.ID && !master.IsEdit) {
            $('#txtStartDate').attr('disabled', true);
            $('#txtskipdays').attr('disabled', true);
        }

        //init Drag & Slotpicker -->
        setTimeout(() => {                
            $('.slot-picker').timepicker({ minuteStep: 10, defaultTime: ''});  
            createDragable();
            
            //if IsEdit is false don't allow to drag days -->
            if(!!master.ID && !master.IsEdit) window.clearDrag();
        }, 300);
    }

    const validateMaster = (master)=> {
        const { publishMasters, validStartDate } = pageData;
        
        const { StartDate, EndDate, SkipDays } = master;

        let isValidMaster = true;
        let msg = '';
        
        if (moment(StartDate).isAfter(EndDate)) {
            msg = 'Invalid Dates';
            isValidMaster = false;
        }

        if (SkipDays < 1) {
            msg = 'Skip days cannot be less 1';
            isValidMaster = false;
        }
       
        //Check if SkipDays < Previously Opened Days --> 
        const targetDate    = moment(StartDate).add(SkipDays, 'days');
        var inValidSkipDays = moment(targetDate).isBefore(moment(validStartDate, "DD/MM/YYYY"));
        if (publishMasters.find(f => f.SportID == master.SportID) && (!master.ID) && (publishMasters.length != 0) && inValidSkipDays) {                
            msg = 'Skip days cannot be less than days opened by previous master';
            isValidMaster = false;
        }
    

        if (!isValidMaster) swal.fire({  icon: 'warning',  titleText: msg,  timer: 2500,  showConfirmButton: false });
        
        return isValidMaster;
    }

    
    const onDragConfig = (el, target, source, sibling)=> {
        let parentIndex  = $(target).parent().attr('data-rowIndex');
        let currentIndex = $(el).data('index');
        let currentDay   = $(el).data('val');
        let isValid      = $(target).find('div').length < 1;
            
        if ($(target).attr('id') == 'days-holder') isValid = false;
    
        if (isValid) {
            //Check Below Rows for Lower Index
            for (let i = (parseInt(parentIndex) + 1); i < 8; i++) {
                var row = $(`[data-rowindex = ${i}]`);
                row.find('.dragable-day').each((index, element) => {
                    if ($(element).data('index') < currentIndex) {    
                        swal.fire({ toast: true, icon: 'warning', position: 'bottom-end', showConfirmButton: false, timer: 1000,
                            title: 'Previous day is not opened..!',
                        });
    
                        isValid = false;
                    }
                });
            }

            //Check Above Rows for Higher Index
            for (let i = 1; i < parentIndex; i++) {
                var row = $(`[data-rowindex = ${i}]`);
                row.find('.dragable-day').each((index, element) => {
                    if ($(element).data('index') > currentIndex) {
                        swal.fire({ toast: true, icon: 'warning', timer: 1000, position: 'bottom-end', showConfirmButton: false,
                            title: 'Next day is already opened..!',
                        });    

                        isValid = false;
                    }
                });
            }

            //call day setter -->         
            onDayDrag({ Day: currentDay, Index: currentIndex, MasterIndex: parseInt(parentIndex) });            
        } 

        return isValid;
    };

    const resetTable = ()=> {
        setIsCreated(false);
        setDayData();
        setPublishDays([]);
        window.clearDrag();
    } 

    const closeDrawer = ()=> {
        Config.Core.CloseDrawer();
        setTimeout(() => setRightSideDetails(false), 200);           
    }  

    //#endregion

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0 padding-tb20">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0" style={{display: 'flex'}}>
                                        <div className="col-form-label col-1"></div>
                                        <label className="col-form-label col-2">Sport</label>
                                        <div className="kt-input-icon padding-r10 margin-lr20 col-7">
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || {label: 'All', value: type.SportID}}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                        <div className="col-form-label col-1"></div>
                                        <div className="kt-input-icon kt-input-icon--left col-7">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Sports Publish..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => onOpenDrawer()}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Master</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && (                    
                                <RightSideDetails title="Calender Details" onCloseDrawer={() => closeDrawer()}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">  
                                                    <form className="kt-form" id="addMasterForm">
                                                        <div className="row margin-b15">
                                                            {!sportsPublishMaster.ID && (
                                                                <div className="col-sm-3 padding-0 padding-r5">
                                                                    <div className="form-group mb-0  row">
                                                                        <label className="col-form-label col-sm-4">Sports <span className="red">*</span></label>
                                                                        <div className="col-sm-8">
                                                                            <Select 
                                                                                id="ddlSport" 
                                                                                name="ddlSport"
                                                                                className="multi-custom"
                                                                                placeholder="Select Sports"
                                                                                value={(sports && sports.length > 0 && sportsPublishMaster.SportID) && {label: sports.find((m) => m.ID == sportsPublishMaster.SportID).Name ,value: sportsPublishMaster.SportID}}
                                                                                onChange={(event) => dataHandler('SportID', event.value)}
                                                                                options={sports.slice(1).map(c => ({
                                                                                    label: c.Name,
                                                                                    value: c.ID
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className={`col-sm-${!sportsPublishMaster.ID ? '3' : '4'} padding-0 padding-r5`}>
                                                                <div className="form-group mb-0  row">
                                                                    <label className="col-form-label col-sm-4">Start Date <span className="red">*</span></label>
                                                                    <div className="col-sm-8">
                                                                        <div className="input-group date">
                                                                            <input id="txtStartDate" name="txtStartDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select date"
                                                                                value={sportsPublishMaster.StartDate ? moment(sportsPublishMaster.StartDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text">
                                                                                    <i className="la la-calendar-check-o"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`col-sm-${!sportsPublishMaster.ID ? '3' : '4'} padding-0 padding-r5`}>
                                                                <div className="form-group mb-0 row">
                                                                    <label className="col-form-label col-sm-4">End Date <span className="red">*</span></label>
                                                                    <div className="col-sm-8">
                                                                        <div className="input-group date">
                                                                            <input id="txtEndDate" name="txtEndDate" type="text" className="form-control date-input " readOnly={true} placeholder="Select date"
                                                                                value={sportsPublishMaster.EndDate ? moment(sportsPublishMaster.EndDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text">
                                                                                    <i className="la la-calendar-check-o"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`col-sm-${!sportsPublishMaster.ID ? '2' : '3'} padding-r10`}>
                                                                <div className="form-group mb-0 row">
                                                                    <label className="col-form-label col-sm-9"> Advance opening <span className="red">*</span></label>
                                                                    <div className="col-sm-3">
                                                                        <input id="txtskipdays" name="txtskipdays" type="text" className="form-control"
                                                                            value = {sportsPublishMaster.SkipDays || ''}
                                                                            onChange={({target}) => dataHandler("SkipDays", target.value)}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-1 padding-t3">
                                                                {!isCreated && (
                                                                    <button id="btnCreate" type="button" className="btn btn-sm btn-primary m-0"
                                                                        onClick={() => createTable(sportsPublishMaster)}
                                                                    > Create </button>
                                                                )}
                                                            </div>
                                                        </div>                                                                                                                                                                                           
                                                    </form>
                                                    
                                                    {/* Table holder */}
                                                    {isCreated && (<>
                                                        <TeeTimePublishTable publishMaster= {sportsPublishMaster} dayData={dayData} />
                                                    </>)}                                      
                                                </div>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={saveData}> Save </button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Cancel </button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SportsPublish;