import { useState } from "react";
import { getActiveSportsMaster, getActiveFacilityMasterBySportID, getSportsBookUserForReport, sendNoShowSportEmail } from "../api/sportsAPI";
import { useEffect } from "react";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';


const SportsReport  = (props) => {

    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [sportsTable,                     setSportsTable]              = useState(null);
    const [isInit,                          setIsInit]                   = useState(true);
    const [sportsReport,                    setSportsReport]             = useState([]);
    const [sports,                          setSports]                   = useState([]);
    const [type,                            setType]                     = useState({});
    const [allFacilities,                   setAllFacilities]            = useState([]);
    const [facilityID,                      setFacilityID]               = useState(null);
    
    
    useEffect(() => {
        props.title('Sports Report ','sports-report')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchSportsMaster();
    },[])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [sportsReport]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate && facilityID){
            fetchSportsReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), facilityID)
        }
    },[selectedFromDate, selectedToDate, facilityID])

    useEffect(() => {
        if(type){
            fetchFacilityMasterBySportID(type?.SportID)
        }
    },[type])

    useEffect(() => {
        if(facilityID && !isInit){
            $('#tblSportsReport').KTDatatable().destroy(); 
            fillDataTable();
        }
    },[facilityID])

    const fetchSportsMaster = async() => {
        try {
            const sportMasters =  await getActiveSportsMaster();
            sportMasters.length > 0 && setType({'SportID': sportMasters[0].ID})
            setSports(sportMasters)        
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchFacilityMasterBySportID = async(id) => {
        try {
            const master = await getActiveFacilityMasterBySportID(id);
            master.length > 0 && setFacilityID(master[0].ID), setAllFacilities(master);

        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchSportsReport = async(fdate, tDate, facilityID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getSportsBookUserForReport(fdate, tDate, facilityID)

            isInit ? setIsInit(false) : $('#tblSportsReport').KTDatatable().destroy(); 
            setSportsReport(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    
       
    const sendTeeTimeNoShowEmail = async(users) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure',
            text                : users.length > 1 ? `You want to send no show email to all` :  `You want to send no show email to ${users[0].UserName}`,
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Confirm',
            preConfirm: async () => {
              try {
                let userList = []
                for (const m of users) {
                    if(m.IsPresent !== 'Y'){
                        userList.push({
                            UserName:        m.UserName,
                            Email:           m.Email || '',
                            BookDate:        m.BookDate,                 
                            Slot:            m.Slot,
                            SportName:       m.SportName
                        })
                    }
                }
                await sendNoShowSportEmail(userList)
              } catch (err) {
                swal.fire({
                  icon                : 'error',
                  titleText           : 'Error!',
                  text                : err.message,
                  buttonsStyling      : false,
                  confirmButtonClass  : 'btn btn-brand',
                });
                return;
              }
            },
        });
        if (!swalResult.value) return;

        swal.fire({
            icon              : 'success',
            titleText         : 'Email Send Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    const updateTypeDetails = (field, value) => { 
        setAllFacilities([])  
        setFacilityID(null)
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    function exportSheet(type) {
        sportsTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - Sports Report` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    }


    function fillDataTable() {
        var ReportList = sportsReport;

        const dataTable = $('#tblSportsReport').KTDatatable({
            data: {
                saveState: false,
                source: ReportList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'SportBookID',
                    title: 'Book ID',
                    width: 35
                },
                {
                    field: 'BookDate',
                    title: 'Game Date',
                    width: 85,
                    template: function (row) {
                        return moment(row.BookDate).format("DD-MMM-YYYY");
                    }
                },
                {
                    field: 'Slot',
                    title: `${ReportList[0]?.BookingType || 'Slot'}`,
                    width: 120

                },
                {
                    field: 'UserNameAdmin',
                    title: 'User Name',
                    width: 140,
                    template: function (row) {
                        if (row?.UserNameAdmin) {
                            return `<span class='kt-badge kt-badge--${row.UserType == "User" ? 'success' : (row.UserType == "Guest" || row.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.UserNameAdmin}`
                        }else {
                            return '';
                        }
                    }

                },
                {
                    field: 'MemberType',
                    title: 'Member Type',
                    width: 55,
                },
                {
                    field: 'MembershipNo',
                    title: 'Membership No',
                    width: 85,
                },
                {
                    field: 'IsOwner',
                    title: 'Booking Type',
                    width: 80,
                    template: function (row) {
                        if (row.IsPresent == 'Y') {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-success" style="padding-left: 9px">' + 'Confirmed' + '</span></div>';
                        } else {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-warning">' + 'No Show' + '</span></div>';
                        }
                    }
                },
                {
                    field: '',
                    title:   `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-emailAll" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="Send no show email to all"><i class="flaticon2-email"></i></button>`,
                    width: 25,
                    template: function (row) {

                        if (row.IsPresent == 'N') {
                            return `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-email" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="Send no show email"><i class="flaticon2-email"></i></button>`
                        } else {
                            return '';
                        }
                    }
                },
                
            ]
            ,
            rows: {
                autoHide: false,
            }
        });
        Config.Core.InitPopover();

        dataTable.on('click', '.btn-email', function (ss) {  // Email for individual
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                sendTeeTimeNoShowEmail([dataTable.dataSet[index]])
            } 
        });

        dataTable.on('click', '.btn-emailAll', function () { //Email for all
            sendTeeTimeNoShowEmail(dataTable.dataSet)
        });

        // export to excel
        const sportsTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Sports Report`,
        });
        setSportsTable(sportsTable);
        $('.tableexport-caption').addClass('hidden');
        
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div  className="col-sm-1">
                                    </div> */}
                                    <label className="col-form-label col-sm-1 text-right  ">Sport</label>
                                    <div className="form-group col-sm-2 ">
                                        <div>
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || ''}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Facility</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlFacility" 
                                                name="ddlFacility"
                                                className="multi-custom width-95p"
                                                value={((allFacilities && allFacilities.length > 0 && !!facilityID) && {label: (allFacilities.find((m) => m.ID == facilityID) && allFacilities.find((m) => m.ID == facilityID).Name), value: facilityID}) || ''}
                                                onChange={(event) => setFacilityID(event.value)}
                                                options={allFacilities.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-1">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblSportsReport" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SportsReport;