import { useEffect, useState } from "react";
import * as helper from "../global/helper";
import swal from 'sweetalert2';
import { addMonthlyPayment, validateMemberForMonthlyPayment } from "../api/paymentAPI";
import ic_check_on from '../../assets/images/ic_check_on.png'

const ValidateMember = (props) => {

    const pageSize = 50; // Number of items to display per page

    const [memberList,                      setMemberList]            = useState([]);
    const [memberDetails,                   setMemberDetails]         = useState({});
    const [isSelected,                      setIsSelected]            = useState(false);
    const [isProceed,                       setIsProceed]             = useState(false);
    const [currentPage,                     setCurrentPage]           = useState(1);

    // Calculate indexes for pagination
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, memberList.length);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const totalPages = Math.ceil(memberList.filter(f => f.IsValid === "N").length / pageSize);

    // Render only the items for the current page
    const displayedMembers = memberList.filter(f => f.IsValid === "N").slice(startIndex, endIndex);

    useEffect(() => {
        $('#addMonthlyPaymentUser-modal').modal({ backdrop: 'static' });
  
        $('#addMonthlyPaymentUser-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          props.onDismissModal(); 
        });
    }, []);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
        helper.SetScrollHeight();
    });

    useEffect(() => {
       if(props.memberList && props.memberList.length > 0){
            setMemberList(props.memberList);
       }
    }, [props.memberList]);

    const editHandler = (obj) => {
       setMemberDetails(obj)
       setIsSelected(true)
    }

    const updatedMember = async() => {
        const btn = $("#btnUpdate");
        var form = $('#updateMemberForm');

        form.validate({
            rules: {
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                }                    
            },
            messages: {
                txtMobile: {
                    required: "Please enter mobile",
                }
            }
        });
        
        // if (!form.valid()) return;
        helper.StartProcessing(btn);
        try {

            // Remove the comma from the string
            const stringWithoutComma = memberDetails.BillAmount !== undefined && memberDetails.BillAmount !== null ? memberDetails.BillAmount.toString().replace(/,/g, "") : '';
            // Convert the modified string to a float
            const myFloat = parseFloat(stringWithoutComma);      
            if(isNaN(myFloat) || memberDetails.BillAmount == 0){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Bill amount is not correct',
                    buttonsStyling        : false,  
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return
            }
            // const index = memberList.indexOf(memberDetails);
            const data = await validateMemberForMonthlyPayment(memberDetails.Mobile);
            const index = memberList.findIndex(member => member.MembershipNumber === memberDetails.MembershipNumber);

            if (index !== -1) {
                const updatedList = [...memberList];
                updatedList[index] = {...memberDetails, BillAmount: myFloat, IsCorrected: true, UserID: data.ID, Name: data.Name, Mobile: data.Mobile, Email: data.Email};
        
                setMemberList(updatedList);
            };        
            setMemberDetails({});
            setIsSelected(false);

            helper.StopProcessing(btn);                  

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const checkHandler = () => {
        if((memberList && memberList.filter(f => f.IsValid == "N").length > 0 && !isProceed)){
            setIsProceed(true)
        }else{
            addMonthlyPaymentData();
        }
    };

    const addMonthlyPaymentData = async() => {
        const btn = $("#save-btn");

        helper.StartProcessing(btn);
        try {

            let filteredMemberList = [];

            for (const m of memberList) {
                if(m.IsValid == "Y" || m.IsCorrected){
                    filteredMemberList.push({
                        UserID:       m.UserID,
                        Date:         m.Date,
                        BillAmount:   m.BillAmount,
                        Status:       m.Status,
                        IsMailSent:   "N"
                    })
                }
            }
            await addMonthlyPayment(filteredMemberList, props.date);
            swal.fire({
                icon: 'success',
                titleText: `Sheet Added Successfully!`,
                showConfirmButton: false,
                timer: 1500,
            });

            helper.StopProcessing(btn);
            
            $('#add-excel-modal').modal('hide');
            $('#addMonthlyPaymentUser-modal').modal('hide');
            props.refetchReport();

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const deleteHandler = (member) => {
        setMemberList(prevList => {
            return prevList.filter(item => item !== member);
        });
    };

    const updateMemberDetails = (field, value) => { 
        setMemberDetails((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const clearAction = () => {       
        setMemberDetails({});
        setIsSelected(false)

    };

    const handlePrevClick = (e) => {
        e.preventDefault();	
        handlePageChange(currentPage - 1);
    };

    const handleNextClick = (e) => {
        e.preventDefault();	
        handlePageChange(currentPage + 1);
    };


    return(
        <>
          <div className="modal fade" id="addMonthlyPaymentUser-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Validate Member</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                    </div>
                    <div className="modal-body padding-b0 padding-t10">
                        {Object.keys(memberDetails).length > 0 && (
                            <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="updateMemberForm" >                
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form padding-l150" role="form">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label col-sm-1 text-right"> Mobile </label>
                                        <div className="col-xl-3 col-sm-3">
                                            <input 
                                                id="txtMobile" 
                                                name="txtMobile" 
                                                type="text" 
                                                minLength='10'
                                                maxLength='10'
                                                className="form-control"
                                                value={memberDetails.Mobile || ''}
                                                onChange={(event) => updateMemberDetails('Mobile', event.target.value)} 
                                            />                                      
                                        </div>
                                        <label className="col-form-label col-sm-1 text-right"> Bill Amount </label>
                                        <div className="col-xl-3 col-sm-3">
                                            <input 
                                                id="txtBillAmount" 
                                                name="txtBillAmount" 
                                                type="number" 
                                                className="form-control"
                                                value={memberDetails.BillAmount  || ''}
                                                onChange={(event) => updateMemberDetails('BillAmount', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="btn-group">
                                                <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={updatedMember} > Update </button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        {memberList && memberList.filter(f => f.IsValid == "N").length >  0 && !isProceed? (
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable margin-b0">
                                <table className="kt-datatable__table table-striped">
                                    <thead className="kt-datatable__head" >
                                        <tr className="kt-datatable__row">
                                            <th width="35%" className="kt-datatable__cell text-clip" >
                                                <span>Name</span>
                                            </th>
                                            <th width="20%" className="kt-datatable__cell text-clip">
                                                <span >Contact</span>
                                            </th>
                                            <th width="15%"className="kt-datatable__cell text-clip">
                                                <span>Bill Amount</span>
                                            </th>
                                            <th width="15%" className="kt-datatable__cell text-clip">
                                                <span >Action</span>
                                            </th>
                                            <th width="10%" className="kt-datatable__cell text-clip">
                                                <span></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                        {displayedMembers.map((a, i) => {
                                            return (
                                                <tr className={`kt-datatable__row block-row ${a !== memberDetails && isSelected && 'rowdisabled'}`} key={i} >
                                                    <td width="35%" title='' className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                {a.Photo && a.Photo != '' ? 
                                                                <img src={a.Photo} alt="user-profile" /> : 
                                                                <div className={`kt-badge kt-badge--xl kt-badge--info `}> {a.Name.substring(0, 1)}</div>}
                                                            </div>
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip"> {a.Name} </span> <span className="kt-user-card-v2__desc text-clip"> {a.MembershipNumber} </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width="20%" className="kt-datatable__cell text-clip padding-l0 padding-15">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__details text-clip">
                                                                <span className="kt-user-card-v2__name text-clip"> {a.Mobile} </span> 
                                                                <span className="kt-user-card-v2__desc text-clip"> {a.Email} </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td width="15%" className="kt-datatable__cell text-clip padding-l0 padding-15">
                                                        <span className="kt-user-card-v2__name text-clip"> {a.BillAmount} </span> 
                                                    </td>
                                                    <td width="15%" className="kt-datatable__cell text-clip padding-l6 padding-15">
                                                        {a.Comment != 'User Already Exist' && (
                                                            <>
                                                                <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(a)}><i className="flaticon2-edit"></i></button>&nbsp;&nbsp;
                                                                {!isSelected && <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"onClick={() => deleteHandler(a)}><i className="flaticon2-rubbish-bin-delete-button"></i></button>} 
                                                            </>
                                                        )}
                                                    </td>
                                                    <td width="10%" className="kt-datatable__cell text-clip padding-l0 padding-15">
                                                        {a.IsCorrected ? (
                                                            <i className="fa fa-check-circle" style={{color: '#0abb87', fontSize: "24px"}}></i>
                                                        ):(
                                                            <span
                                                                type="button"
                                                                data-toggle="kt-popover"
                                                                data-trigger="hover"
                                                                data-placement="left"
                                                                data-content={a.Comment}
                                                            >
                                                                    <i className="la la-exclamation-circle" style={{color: '#ffb822', fontSize: "29px"}}></i>
                                                            </span>                                                        
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {/* Pagination */}
                                {memberList.filter(f => f.IsValid === "N").length > 50 ? (
                                    <div className="pagination padding-tb10">
                                        <button onClick={handlePrevClick} disabled={currentPage === 1} type="button"className="btn btn-secondary">
                                            Previous
                                        </button>
                                        <span className="padding-t10">{`Page ${currentPage} of ${totalPages}`}</span>
                                        <button onClick={handleNextClick} disabled={currentPage === totalPages} type="button"className="btn btn-brand">
                                            Next
                                        </button>
                                    </div>
                                ):(
                                    <div className="padding-tb10">
                                    </div>
                                )}
                            </div>
                        ):(
                            <div style={{textAlign: "center"}}  className="padding-50 monthly-payemnt-box">
                                <img src={ic_check_on} style={{height: '60px', width: '60px'}}/>
                                <h3 style={{color: "#535354"}} className="padding-t20">{memberList.filter(f => f.IsValid == "Y" || f.IsCorrected).length} records will be uploaded</h3>
                                <h6 style={{color: "#535354"}}> Click on upload button to proceed.</h6>
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        {(memberList && memberList.filter(f => f.IsValid == "N").length > 0) && !isProceed && <h1 style={{fontSize: '13px', paddingRight: "600px"}}>Upon upload, the above data will be skipped.<br/> If you update the information, data marked with a green tick will be uploaded.</h1>}
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal">   
                            Close
                        </button>
                        <button
                            id="save-btn"
                            type="button"
                            className="btn btn-brand margin-0"
                            onClick={checkHandler}
                        >   
                            {isProceed ? "Upload" : "Proceed"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ValidateMember;
