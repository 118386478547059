import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";

const getCheckInMasterUrl                  = '/api/admin/checkIn/getCheckInMaster';
const postCheckInMasterUrl                 = '/api/admin/checkIn/postCheckInMaster';
const getViewCheckInByDateUrl              = (fDate, tDate) => `/api/admin/checkIn/getViewCheckInByDate/${fDate}/${tDate}`;
const getActiveCheckInMasterUrl            = '/api/admin/checkIn/getActiveCheckInMaster';

export const getCheckInMaster = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getCheckInMasterUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const postCheckInMaster = async (masterDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postCheckInMasterUrl, { masterDetails }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
};

export const getViewCheckInByDate = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getViewCheckInByDateUrl(fDate, tDate),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};    

export const getActiveCheckInMaster = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getActiveCheckInMasterUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
  };