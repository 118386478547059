import React, { useState, useEffect } from 'react'
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getCoachCancellationSheet } from '../api/coachAPI';
import BookingFilterForCancellation from './BookingFilterForCancellation';

const CoachBookCancellation = (props) => {

    const [selectedFromDate,        setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,          setSelectedToDate]           = useState(moment().toDate());
    const [selectedCoach,           setSelectedCoach]            = useState(null);
    const [selectedSport,           setSelectedSport]            = useState(null);
    const [cancellationSheet,       setCancellationSheet]        = useState([]);
    const [cancellationTable,       setCancellationTable]        = useState(null);
    const [isInit,                  setIsInit]                   = useState(true);
    
    useEffect(() => {
        props.title("Cancellation Sheet", "coach-cancel-sheet");
    }, []);

    useEffect(() => {       
        if (!isInit) {
            fillTable();
        }
    }, [cancellationSheet]);

    useEffect(() => {
        if(selectedCoach && selectedFromDate && selectedToDate && selectedSport) {
            fetchCancellationSheet(
                moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 
                moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 
                selectedCoach.CoachID, 
                selectedSport.ID
            )
        }
    }, [selectedCoach, selectedFromDate, selectedToDate, selectedSport])
    
    const fetchCancellationSheet = async(fdate, tDate, coachID, sportID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const booking = await getCoachCancellationSheet(fdate, tDate, coachID, sportID);
            isInit ? setIsInit(false) : $('#tblCancelSheet').KTDatatable().destroy(); 
            setCancellationSheet(booking)
            swal.close();
        } catch (error) {
            swal.close();
            helper.reuseableSwal('error', 'Error!', error.message)  
        }
    }

    function exportSheet(btnType) {
        let fileName = `${moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} - Coach cancellation Sheet}`;

        cancellationTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName // pass in a new set of properties
        });
        
        if (btnType == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        
        $('.tableexport-caption').addClass('hidden');
    }

    function fillTable() {
        var filteredList = cancellationSheet;
        var tableFields = []

        swal.close();
    
        $.each(filteredList, function (index, element) {

            if (element.Status != null) {
                element["Status"] = '<div class="badge badge-danger"><span>' + element["Status"] + '</span></div>';
            }

        });
        tableFields = [{ field: "Slot", title: "Slot", width: 120 } ]

        Config.Core.InitPopover();

        const dataTable = $('#tblCancelSheet').KTDatatable({
            data: {
                saveState: false,
                source: filteredList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'CancelDate',
                    title: "Cancel Dt",
                    width: 100,
                    template: function (row) {
                        return moment(row.CancelDate).format('DD-MM-YYYY');
                    }
                },
                {
                    field: 'BookDate',
                    title: "Book Dt ",
                    width: 100,
                    template: function (row) {
                        return moment(row.BookDate).format('DD-MM-YYYY');
                    }
                },
                ...tableFields,
                { field: "CoachName", title: "Coach", },
                { field: "UserName", title: "Player", },
                {
                    field: 'Status',
                    title: 'Status',
                    template: function (row) {
                        var text = row.Status;
                        if (row.Status === null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return text;
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {
                        if (row.Comment) {
                            return `<center>                                              
                                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>
                                    </center>`;
                        } else {
                            return '';
                        }
                    }
                }
            ],
            rows: {
                autoHide: false,
            }
            
        })

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const CancellationTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Coach Cancellation Sheet`,
        });
        setCancellationTable(CancellationTable);
        $('.tableexport-caption').addClass('hidden');

    }
    return (
        <>
           <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 p  adding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className='row'>
                                    <BookingFilterForCancellation
                                        selectedDate={selectedFromDate}
                                        selectedToDate={selectedToDate}
                                        selectedCoach={selectedCoach}
                                        selectedSport={selectedSport}
                                        setSelectedDate={setSelectedFromDate}
                                        setSelectedToDate={setSelectedToDate}
                                        setSelectedCoach={setSelectedCoach}
                                        setSelectedSport={setSelectedSport}
                                    />
                                    <div className="col-sm-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblCancelSheet" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default CoachBookCancellation