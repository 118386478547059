import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";

const getMatMasterAllUrl                = '/api/admin/mat/getMatMasterAll';
const postMatMasterUrl                  = '/api/admin/mat/postMatMaster';

const getMatTimeMasterAllUrl            = '/api/admin/mat/getMatTimeMasterAll';
const postMatTimeMasterUrl              = '/api/admin/mat/postMatTimeMaster';

const postMatTemplateUrl                = '/api/admin/mat/postMatTemplate';
const getMatTemplateByMasterIDUrl       = (id) => `/api/admin/mat/getMatTemplateByMasterID/${id}`;
const deleteMatTemplateUrl              = '/api/admin/mat/deleteMatTemplate';

const getMatBookUrl                     = '/api/admin/mat/getMatBook';
const postMatTimeBlockUrl               = '/api/admin/mat/postMatTimeBlock';  
const postMatBookUrl                    = '/api/admin/mat/postMatBook';  
const deleteMatTimeBlockUrl             = (id) => `/api/admin/mat/deleteMatTimeBlock/${id}`;  
const deleteMatBookUrl                  = (matBookID) => `/api/admin/mat/deleteMatBook/${matBookID}`;  
const getMatCancellationSheetUrl        = (fDate, tDate) => `/api/admin/mat/getMatCancellationSheet/${fDate}/${tDate}`;

const getMatPublishPageDataUrl          = '/api/admin/mat/getMatPublishPageData';    
const postMatPublishUrl                 = '/api/admin/mat/postMatPublish';    
const getPublishMasterDataUrl           = '/api/admin/mat/getPublishMasterData';    

export const deleteMatBook = async (matBookID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        deleteMatBookUrl(matBookID),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getMatCancellationSheet = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getMatCancellationSheetUrl(fDate, tDate),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getMatMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatMaster= async(matMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatMasterUrl, { matMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatTimeMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatTimeMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postTimeMatMaster= async(matMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTimeMasterUrl, { matMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatTemplate= async(matTemplate)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTemplateUrl, { matTemplate }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteMatTemplate = async(templateId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteMatTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getMatTemplateByMasterID = async(id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatTemplateByMasterIDUrl(id), { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatBook = async (matBookDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( getMatBookUrl, {matBookDetails}, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatTimeBlock = async(books, date) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatTimeBlockUrl, { books, date }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteMatTimeBlock = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteMatTimeBlockUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postMatBook = async(matBook)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatBookUrl, { matBook }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getMatPublishPageData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getMatPublishPageDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postMatPublish = async(data) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postMatPublishUrl, {...data }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getPublishMasterData = async(masterId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getPublishMasterDataUrl}/${masterId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}