import React, { useEffect, useState } from 'react'
import * as helper from '../global/helper';
import Select from 'react-select';

import { getCoachMasterAll, getSportTaggingByCoachID } from '../api/coachAPI';

const GetBookingFilter = ({
    selectedDate,
    selectedToDate,
    selectedCoach,
    selectedSport,
    setSelectedDate,
    setSelectedToDate,
    setSelectedCoach,
    setSelectedSport,
}) => {

    const [coachList,               setCoachList]                   = useState([]);
    const [sportList,               setSportList]                   = useState([]);
    
    useEffect(() => {
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchCoachMaster()
    }, []);

    useEffect(() => {
        if (selectedCoach) fetchSportTaggingByCoachID(selectedCoach.ID)
    }, [selectedCoach])

    useEffect(() => {
        if (coachList && coachList.length > 0) setSelectedCoach(coachList[0])
    }, [coachList])

    useEffect(() => {
        if (sportList && sportList.length > 0) setSelectedSport(sportList[0])
    }, [sportList])

    const fetchCoachMaster = async () => {
        try {
            const coachMaster =  await getCoachMasterAll();
            setCoachList(coachMaster)
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)  
        }
    }

    const fetchSportTaggingByCoachID = async (coachID) => {
        try {
            const response = await getSportTaggingByCoachID(coachID);
            const sortedSport = response.sort((a, b) => {
                return (a.Active === 'Y' ? -1 : 1) - (b.Active === 'Y' ? -1 : 1);
            });
            // const sortedSport = response.sort((a, b) => a.)
            setSportList(sortedSport)
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    return (
        <>
            <label className="col-form-label col-sm-1 text-right">Date </label>
            <div className="form-group col-sm-2">
                <div className="input-group date">
                    <input 
                        id="txtDate" 
                        name="txtDate" 
                        type="text" 
                        className="form-control" 
                        placeholder="Select date"
                        defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                    /> 
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="la la-calendar-check-o"></i>
                        </span>
                    </div>
                </div>
            </div>
            <label className="col-form-label col-sm-1 text-right">Coach </label>
            <div className="form-group col-sm-2">
                <Select
                    onChange={(event) => setSelectedCoach(event.value)}
                    name="ddlSelectUser"
                    value={(selectedCoach && coachList.length > 0) && {label : selectedCoach.Name, value: selectedCoach}}
                    id="ddlSelectUser"
                    className="ddlSelectUser"
                    options={coachList.map(c => ({
                        label: `${c.Name}`,
                        value: c
                    }))}
                />
            </div>
            <label className="col-form-label col-sm-1 text-right">Sport </label>
            <div className={`form-group col-sm-2`}>
                <Select
                    onChange={(event) => setSelectedSport(event.value)}
                    name="ddlSelectUser"
                    value={(selectedSport && sportList.length > 0) && {label : selectedSport.Name, value: selectedSport}}
                    id="ddlSelectUser"
                    className="ddlSelectUser"
                    options={sportList.map(c => ({
                        label: `${c.Name}`,
                        value: c
                    }))}
                />
            </div>
        </>
    )
}

export default GetBookingFilter