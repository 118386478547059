import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";

const getScorecardSheetUrl                  = '/api/admin/game/getScorecardSheet';
const postGameScoresUrl                     = '/api/admin/game/postGameScores';
const verifyGameScorecardUrl                = (gameID) => `/api/admin/game/verifyGameScorecard/${gameID}`;
const getGameDetailsUrl                     = (id) => `/api/admin/game/getGameDetails/${id}`;

export const getScorecardSheet = async (fromDate, toDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( getScorecardSheetUrl, { fromDate, toDate }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postGameScores = async (gameScores, deletedGameScores, gamePlayers) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postGameScoresUrl, { gameScores, deletedGameScores, gamePlayers }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getGameDetails = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getGameDetailsUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const verifyGameScorecard = async (gameID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        verifyGameScorecardUrl(gameID),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};