import { useEffect, useState } from "react";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { getActiveSportsMaster, getSportsCancellationSheet } from "../api/sportsAPI";

const SportsCancellationSheet = (props) => {

    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [sports,                          setSports]                   = useState([]);
    const [isInit,                          setIsInit]                   = useState(true);
    const [cancellationSheet,               setCancellationSheet]        = useState([]);
    const [cancellationTable,               setCancellationTable]        = useState(null);
    const [type,                            setType]                     = useState({});

    useEffect(() => {
        props.title('Cancellation Sheet','sport-cancel-sheet')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchSports();
    },[])
    
    useEffect(() => {       
        if (!isInit) {
            fillTable();
        }
    }, [cancellationSheet]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate && type?.SportID){
            fetchCancellationSheet(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.SportID)
        }
    },[selectedFromDate, selectedToDate, type])

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            response.length > 0 && setType({'SportID': response[0].ID})
            setSports(response)
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchCancellationSheet = async(fdate, tDate, sportID) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getSportsCancellationSheet(fdate, tDate, sportID);
            isInit ? setIsInit(false) : $('#tblCancelSheet').KTDatatable().destroy(); 
            setCancellationSheet(response.CancelSheet);
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function exportSheet(type) {
        cancellationTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - Sports Cancellation Sheet - ${type}` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    }


    function fillTable() {


        $.each(cancellationSheet, function (index, element) {

            if (element.Status != null) {
                element["Status"] = '<div class="badge badge-danger"><span>' + element["Status"] + '</span></div>';
            }

        });

        Config.Core.InitPopover();

        const dataTable = $('#tblCancelSheet').KTDatatable({
            
            data: {
                saveState: false,
                source: cancellationSheet,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: cancellationSheet.length > 0 && cancellationSheet[0].BookingType == 'Slot' ? [
                {
                    field: 'CancelDate',
                    title: "Cancel Dt",
                    width: 50,
                    template: function (row) {
                        return moment(row.CancelDate).format('DD-MM-YYYY');
                    }
                },
                {
                    field: 'BookDate',
                    title: "Book Dt ",
                    width: 50,
                    template: function (row) {
                        return moment(row.BookDate).format('DD-MM-YYYY');
                    }
                }, 
                { field: "FacilityName", title: "Facility", width: 40, },
                {
                    field: 'Player1',
                    title: 'Player 1',
                    template: function (row) {
                        if (row?.Player1) {
                            return `<span class='kt-badge kt-badge--${row.UserType1 == "User" ? 'success' : (row.UserType1 == "Guest" || row.UserType1 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player1}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player2',
                    title: 'Player 2',
                    template: function (row) {
                        if (row?.Player2) {
                            return `<span class='kt-badge kt-badge--${row.UserType2 == "User" ? 'success' : (row.UserType2 == "Guest" || row.UserType2 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player2}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player3',
                    title: 'Player 3',
                    template: function (row) {
                        if (row?.Player3) {
                            return `<span class='kt-badge kt-badge--${row.UserType3 == "User" ? 'success' : (row.UserType3 == "Guest" || row.UserType3 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player3}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player4',
                    title: 'Player 4',
                    template: function (row) {
                        if (row?.Player4) {
                            return `<span class='kt-badge kt-badge--${row.UserType4 == "User" ? 'success' : (row.UserType4 == "Guest" || row.UserType4 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player4}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Status',
                    title: 'Status',
                    template: function (row) {
                        var text = row.Status;
                        if (row.Status === null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return text;
                        }
                    }, width: 90
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {
                        if (row.Comment) {
                            return `<center>                                              
                                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>
                                    </center>`;
                        } else {
                            return '';
                        }
                    }
                }
            ]:[
                {
                    field: 'CancelDate',
                    title: "Cancel Dt",
                    width: 80,
                    template: function (row) {
                        return moment(row.CancelDate).format('DD-MM-YYYY');
                    }
                },
                {
                    field: 'BookDate',
                    title: "Book Dt",
                    width: 80,
                    template: function (row) {
                        return moment(row.BookDate).format('DD-MM-YYYY');
                    }
                }, 
                { field: "FacilityName", title: "Facility", width: 100, },
                {
                    field: 'UserNameAdmin',
                    title: 'User Name',
                    template: function (row) {
                        if (row?.UserNameAdmin) {
                            return `<span class='kt-badge kt-badge--${row.UserType == "User" ? 'success' : (row.UserType == "Guest" || row.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.UserNameAdmin}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Status',
                    title: 'Status',
                    template: function (row) {
                        var text = row.Status;
                        if (row.Status === null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return text;
                        }
                    }, width: 90
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {
                        if (row.Comment) {
                            return `<center>                                              
                                        <button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>
                                    </center>`;
                        } else {
                            return '';
                        }
                    }
                }
            ],
            rows: {
                autoHide: false,
            }
            
        });
        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const CancellationTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Sports Cancellation Sheet`,
        });
        setCancellationTable(CancellationTable);
        $('.tableexport-caption').addClass('hidden');
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">From Date</label>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">To Date</label>
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                    </div>
                                    <div className="form-group col-sm-2 pnlStart ">
                                        <div>
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || ''}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Sports..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblCancelSheet" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SportsCancellationSheet;