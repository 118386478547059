import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";


const getEmailTemplateUrl                                = '/api/admin/golfClub/getEmailTemplate';
const getAdminEmailTemplateUrl                           = '/api/admin/golfClub/getAdminEmailTemplate';
const postEmailTemplateUrl                               = '/api/admin/golfClub/postEmailTemplate';
const getCourseAllUrl                                    = '/api/admin/golfClub/getCourseAll';
const postCourseUrl                                      = '/api/admin/golfClub/postCourse';
const getTeeBoxAllUrl                                    = '/api/admin/golfClub/getTeeBoxAll';
const getTeeBoxByCourseIDUrl                             = (id) => `/api/admin/golfClub/getTeeBoxByCourseID/${id}`;
const getScoreCardMasterByTeeBoxIDUrl                    = (id) => `/api/admin/golfClub/getScoreCardMasterByTeeBoxID/${id}`;
const getViewHoleInOneAllUrl                             = '/api/admin/golfClub/getViewHoleInOneAll';
const postTeeBoxUrl                                      = '/api/admin/golfClub/postTeeBox';
const postScoreCardMasterUrl                             = '/api/admin/golfClub/postScoreCardMaster';
const postClubUrl                                        = '/api/admin/golfClub/postClub';
const getClubAllUrl                                      = '/api/admin/golfClub/getClubAll';
const getFeedbackAllUrl                                  = '/api/admin/golfClub/getFeedbackAll';
const getAppUpdateByTypeUrl                              = (type) => `/api/admin/golfClub/getAppUpdateByType/${type}`;
const postAppUpdateUrl                                   = '/api/admin/golfClub/postAppUpdate';
const getAppServiceByTypeUrl                             = (type) => `/api/admin/golfClub/getAppServiceByType/${type}`;
const postAppServiceUrl                                  = '/api/admin/golfClub/postAppService';
const getHolidayCalenderAllUrl                           = '/api/admin/golfClub/getHolidayCalenderAll';
const postHolidayCalenderUrl                             = '/api/admin/golfClub/postHolidayCalender';
const getTournamentCalenderAllUrl                        = '/api/admin/golfClub/getTournamentCalenderAll';
const postTournamentCalenderUrl                          = '/api/admin/golfClub/postTournamentCalender';
const getCourseClosedAllUrl                              = '/api/admin/golfClub/getCourseClosedAll';
const postCourseClosedUrl                                = '/api/admin/golfClub/postCourseClosed';
const postHoleInOneUrl                                   = '/api/admin/golfClub/postHoleInOne';
const deleteAdminEmailTemplateUrl                        = (id) => `/api/admin/golfClub/deleteAdminEmailTemplate/${id}`
const addAdminEmailTemplateUrl                           = "/api/admin/golfClub/addAdminEmailTemplate";
const updateAdminEmailTemplateUrl                        = "/api/admin/golfClub/updateAdminEmailTemplate";
const sendPromotionalMailUrl                             = "/api/admin/golfClub/sendPromotionalMail";
const getAdminEmailLogUrl                                = "/api/admin/golfClub/getAdminEmailLog";
const getAdminEmailAttachmentUrl                         = "/api/admin/golfClub/getAdminEmailAttachment";
const deleteAdminEmailAttachmentByIDUrl                  = (id) => `/api/admin/golfClub/deleteAdminEmailAttachmentByID/${id}`;
const addAdminEmailAttachmentUrl                         = '/api/admin/golfClub/addAdminEmailAttachment';
const getAdminEmailAttachmentByIDUrl                     = (id) => `/api/admin/golfClub/getAdminEmailAttachmentByID/${id}`;
const getInvoiceTemplateUrl                              = '/api/admin/golfClub/getInvoiceTemplate';
const postInvoiceTemplateUrl                             = '/api/admin/golfClub/postInvoiceTemplate';
const postConciergeServiceUrl                            = '/api/admin/golfClub/postConciergeService';
const getConciergeServiceAllUrl                          = '/api/admin/golfClub/getConciergeServiceAll';
const postAffiliateClubUrl                               = '/api/admin/golfClub/postAffiliateClub';
const getAffiliateClubAllUrl                             = '/api/admin/golfClub/getAffiliateClubAll';

export const getEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getEmailTemplateUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAdminEmailTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAdminEmailTemplateUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postEmailTemplate = async (templateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postEmailTemplateUrl,
        { templateDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCourseAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCourseAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourse = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTeeBoxAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getTeeBoxAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTeeBoxByCourseID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getTeeBoxByCourseIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getScoreCardMasterByTeeBoxID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getScoreCardMasterByTeeBoxIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getViewHoleInOneAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getViewHoleInOneAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTeeBox = async (teeBoxDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postTeeBoxUrl,
        { teeBoxDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postScoreCardMaster = async (scorecardDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postScoreCardMasterUrl,
        { scorecardDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postClub = async (clubDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postClubUrl,
        { clubDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getClubAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getClubAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getFeedbackAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getFeedbackAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAppUpdateByType = async (type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAppUpdateByTypeUrl(type),
        { headers: { auth_token: `bearer ${token}` } }
        );
        return response;
};


export const postAppUpdate = async (appDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAppUpdateUrl,
        { appDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAppServiceByType = async (type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getAppServiceByTypeUrl(type),
        { headers: { auth_token: `bearer ${token}` } }
        );
        return response;
};

export const postAppService = async (appDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAppServiceUrl,
        { appDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getHolidayCalenderAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getHolidayCalenderAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postHolidayCalender = async (calenderDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postHolidayCalenderUrl,
        { calenderDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getTournamentCalenderAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getTournamentCalenderAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postTournamentCalender = async (calenderDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postTournamentCalenderUrl,
        { calenderDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};



export const getCourseClosedAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCourseClosedAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCourseClosed = async (courseDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCourseClosedUrl,
        { courseDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
export const postHoleInOne = async (holeInOneDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postHoleInOneUrl,
        { holeInOneDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteAdminEmailTemplate = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteAdminEmailTemplateUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addAdminEmailTemplate = async (data) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    const response = await axiosPost( 
        addAdminEmailTemplateUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const updateAdminEmailTemplate = async (data) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    const response = await axiosPatch( 
        updateAdminEmailTemplateUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const sendPromotionalMail = async (data, documents) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);

    const response  = await axiosPost(
        sendPromotionalMailUrl,
        { data, documents },
        { headers:{ auth_token: `bearer ${token}` } } 
    );

    return response;
}

export const getEmailStats = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);

    const response =  await axiosGet(
        getAdminEmailLogUrl,
        { headers:{ auth_token: `bearer ${token}` } } 
    )
    
    return response;
}

export const getAdminEmailAttachment = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);

    const response =  await axiosGet(
        getAdminEmailAttachmentUrl,
        { headers:{ auth_token: `bearer ${token}` } } 
    )
    return response;
}

export const deleteAdminEmailAttachmentByID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosDelete(
        deleteAdminEmailAttachmentByIDUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const addAdminEmailAttachment = async (attachmentDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        addAdminEmailAttachmentUrl,
      {  attachmentDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
}
  
export const getAdminEmailAttachmentByID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAdminEmailAttachmentByIDUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
  
export const getInvoiceTemplate = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getInvoiceTemplateUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postInvoiceTemplate = async (templateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postInvoiceTemplateUrl,
        { templateDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postConciergeService = async (conciergeDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postConciergeServiceUrl,
        { conciergeDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getConciergeServiceAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getConciergeServiceAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postAffiliateClub = async (affiliateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postAffiliateClubUrl,
        { affiliateDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getAffiliateClubAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getAffiliateClubAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}
