import RightSideDetails from "../global/rightSideDetails";
import { useEffect, useState } from "react";
import { getActiveSportsMaster, getMarkerBySportID, postMarker } from "../api/sportsAPI";
import swal from 'sweetalert2';
import Select from 'react-select';
import { getMobileCode } from "../api/usersAPI";
import defaultUserImg from '../../assets/images/user.jpg'
import * as helper from '../global/helper';
import { uploadPublicFile } from "../api/fileAPI";
import ImageCropper from "../global/imageCropper";


const Markers = (props) => {
    
    const [rightSideDetails,     setRightSideDetails]    = useState(false);
    const [sportsList,           setSportList]           = useState([]);
    const [isInit,               setIsInit]              = useState(true);
    const [sports,               setSports]              = useState({});
    const [mobileCodeList,       setMobileCodeList]      = useState([]);
    const [markerList,           setMarkerList]          = useState([]);
    const [marker,               setMarker]              = useState({});
    const [markerPhotoBlob,      setUserPhotoBlob]       = useState(null);
    const [cropperData,          setCropperData]         = useState(null);
    const [isEdit,               setIsEdit]              = useState(false);

    useEffect(() => {
        props.title("Markers", "markers")
        fetchSportsMaster();
        fetchMobileCode();

    },[])

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [sportsList]);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setUserPhotoBlob(null), setIsEdit(false);
    }, [rightSideDetails]);

    const fetchSportsMaster = async() => {
        try {
            const sportMasters =  await getActiveSportsMaster();
            isInit ? setIsInit(false) : $('#tblSports').KTDatatable().destroy(); 
            setSportList(sportMasters)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchMobileCode = async() => {
        try {
            const code = await getMobileCode();
            setMarker({CountryCode: code.length > 0 && code[0]?.Code, IsDisplay: 'Y', Active: 'Y'})
            setMobileCodeList(code)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchMarker = async(sportID) => {
        try {
            const marker = await getMarkerBySportID(sportID)   
            setMarkerList(marker);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblSports').KTDatatable({
            data: {
                saveState: false,
                source: sportsList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Sport',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.BookingType} </span>
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSports .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                fetchMarker(dataTable.dataSet[index].ID);
                setSports(dataTable.dataSet[index]); 
                setRightSideDetails(true); 
            }    
        });
    }

    const updateMarkerDetails = (field, value) => {   
        setMarker((d) => {
            return { ...d, [field]: value };
        });
    };

    const addMarkerHandler = async(e) => {
        e.preventDefault()

        var form = $('#add-marker-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true,
                }                         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });
        if (!form.valid()) return;

        const btn = $('#btnAdd');

        helper.StartProcessing(btn);

        try {
            let markerPhoto = '';
            //Call upload API -->
            if(markerPhotoBlob){
                const res = await uploadPublicFile(markerPhotoBlob, 'Content');
                markerPhoto = res.fileName;  
            }else{
                if(marker.Photo) markerPhoto = marker.Photo.split('/Content/')[1];
            }
            const markerDetails = {
                ID:                    marker.ID || 0,
                SportID:               sports.ID,
                Name:                  marker.Name,
                Email:                 marker.Email || '',
                Photo:                 markerPhoto || '',
                CountryCode:           marker.CountryCode,
                Mobile:                marker.Mobile,
                IsDisplay:             marker.IsDisplay,
                Active:                marker.Active
            }
            await postMarker(markerDetails);
            helper.StopProcessing(btn);
            setUserPhotoBlob(null)
            setIsEdit(false)
            fetchMarker(sports.ID);
            $('.chkDisplay').prop("checked", true );
            $('.chkActive').prop("checked", true );
            setMarker({})
            if (marker.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: `${sports.MarkerDisplayName} updated successfully!`,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: `${sports.MarkerDisplayName} added successfully!`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    }

    const editHandler = (id) => {
        setIsEdit(true);
        const d = markerList.find(a => a.ID == id);
        $('.chkDisplay').prop("checked", d.IsDisplay == "Y" ? true : false);
        $('.chkActive').prop("checked", d.Active == "Y" ? true : false);
        setMarker(d)
    }

    
    const clearAction = () => {
        $('.chkDisplay').prop("checked", true );
        $('.chkActive').prop("checked", true );       
        setMarker({CountryCode: mobileCodeList.length > 0 && mobileCodeList[0]?.Code, IsDisplay: 'Y', Active: 'Y'})
        setIsEdit(false);
    } 

    const removePhotoHandler = async(e) => {
		e.preventDefault();
        setMarker((d) => {
            return { ...d, Photo: '' };
        });
		setUserPhotoBlob(null);
	}

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    } 

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Sport..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSports" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title={`${sports.MarkerDisplayName}`} onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        {sports.Logo && sports.Logo != '' ? 
                                                            <img className="kt-widget__pic kt-widget__pic" src={sports.Logo} alt="user-profile"/> :
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-`}>
                                                                {sports.Name.slice(0, 1)}
                                                            </div>
                                                        }   
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {sports.Name}{' '}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={`kt-badge kt-badge--${sports.ActiveAdmin == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                        {sports.ActiveAdmin == 'Y' ? 'Active' : 'Inactive'}
                                                                    </span> 
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span className="padding-r10">
                                                                    <i className="fa fa-clock"></i>
                                                                    {sports.BookingType} 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                        <div className="kt-grid__item kt-grid__item--fluid  ">
                                            <div className="kt-form ">
                                                <div className="padding-lr10">
                                                    <form className="kt-form" id="add-marker-form">
                                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                            <div className="row padding-t30">
                                                                <div className="col-2 form-group">
                                                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed  offset-2" id="kt_user_add_avatar">
                                                                        <div className="kt-avatar__holder" 
                                                                            style={{ backgroundImage : `url( ${markerPhotoBlob ? URL.createObjectURL(markerPhotoBlob): marker.Photo ? marker.Photo : defaultUserImg})` ,width: "105px", height:"105px" } }>    
                                                                        </div>
                                                                        <label 
                                                                            className="kt-avatar__upload" 
                                                                            data-toggle="kt-tooltip"  
                                                                            data-original-title="Change avatar"
                                                                            onClick ={openCropper}
                                                                        >
                                                                            <i className="fa fa-pen"></i>
                                                                        </label>
                                                                        {(!!markerPhotoBlob || marker?.Photo) && (
                                                                            <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                                <i className="fa fa-times"></i>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-10">
                                                                    <div className="row">
                                                                        <div className="col-6 form-group">
                                                                            <input 
                                                                                id="txtName" 
                                                                                name="txtName" 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                placeholder="Name"
                                                                                value={marker.Name || ''}
                                                                                onChange={(event) => updateMarkerDetails('Name',  helper.camelCase(event.target.value))} 
                                                                            />
                                                                        </div>
                                                                        <div className="col-6 form-group">
                                                                            <input 
                                                                                id="txtEmail" 
                                                                                name="txtEmail" 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                placeholder="Email "
                                                                                value={marker.Email || ''}
                                                                                onChange={(event) => updateMarkerDetails('Email', event.target.value)} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-2 form-group">
                                                                            <Select
                                                                                value={(mobileCodeList && mobileCodeList.length > 0 && marker.CountryCode) && {label: `${mobileCodeList.find(m => m.Code == marker.CountryCode).Name} ${mobileCodeList.find(m => m.Code == marker.CountryCode).Code}`, value: marker.CountryCode} || ''}
                                                                                onChange={(event) => updateMarkerDetails('CountryCode', event.value)}
                                                                                name="ddlCountryCode"
                                                                                id="ddlCountryCode"
                                                                                className="ddlCountryCode"
                                                                                options={mobileCodeList.map(c => ({
                                                                                    label: `${c.Name} (${c.Code})`,
                                                                                    value: c.Code
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                        <div className="col-4 form-group ">
                                                                            <input 
                                                                                id="txtMobile" 
                                                                                name="txtMobile" 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                minLength='10'
                                                                                maxLength='10'
                                                                                placeholder="Mobile"
                                                                                value={marker.Mobile || ''}
                                                                                onChange={(event) => updateMarkerDetails('Mobile', event.target.value)} 
                                                                            />
                                                                        </div>
                                                                        <div className="col-1 form-group">
                                                                            <label className="col-form-label col-lg-2">Display</label>
                                                                        </div>
                                                                        <div className="col-1 form-group">
                                                                            <span className="kt-switch kt-switch--icon">
                                                                                <label>
                                                                                    <input 
                                                                                        id="chkDisplay" 
                                                                                        name="chkDisplay" 
                                                                                        className="chkDisplay" 
                                                                                        type="checkbox" 
                                                                                        defaultChecked={marker.IsDisplay == 'N' ? '' : 'checked'}
                                                                                        onChange={(event) => updateMarkerDetails('IsDisplay', event.target.checked ? 'Y' : 'N')} 
                                                                                    />
                                                                                    <span></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-1 form-group">
                                                                            <label className="col-form-label col-lg-2">Active</label>
                                                                        </div>
                                                                        <div className="col-1 form-group">
                                                                            <span className="kt-switch kt-switch--icon">
                                                                                <label>
                                                                                    <input 
                                                                                        id="chkActive" 
                                                                                        name="chkActive" 
                                                                                        className="chkActive" 
                                                                                        type="checkbox" 
                                                                                        defaultChecked={marker.Active == 'N' ? '' : 'checked'}
                                                                                        onChange={(event) => updateMarkerDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                                    />
                                                                                    <span></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                        <div className={`col-2 padding-l20`}>
                                                                            {(!isEdit) ? (
                                                                                <button id="btnAdd" type="button" className={`btn btn-label-brand btn-block`}
                                                                                    onClick={addMarkerHandler} 
                                                                                    ><i className="la la-plus"></i>Add</button>
                                                                                ):(
                                                                                <div className="btn-group  height-38">
                                                                                    <button id="btnAdd" type="button" className={`btn btn-label-success d-inline-block margin-0 `} onClick={addMarkerHandler} > Update </button>
                                                                                    <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                                                </div>
                                                                            )} 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            {markerList && markerList.length > 0 && (
                                                                <div className="row">
                                                                   <div className="col-sm-12">
                                                                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                            <table className="kt-datatable__table">
                                                                                <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                                                    <tr className="kt-datatable__row block-row">
                                                                                        <th className="kt-datatable__cell text-clip" width="50%">
                                                                                            <span>Name</span>
                                                                                        </th>
                                                                                        <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="20%">
                                                                                            <span>Mobile</span>
                                                                                        </th>
                                                                                        <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                                                                            <span>Display</span>
                                                                                        </th>
                                                                                        <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable">
                                                                            <table className="kt-datatable__table table-striped">
                                                                                <tbody className="kt-datatable__body" style={{ display: 'block', minHeight:'40vh' }}>
                                                                                    {markerList.map((m, i) => (
                                                                                        <tr key={i} className={`kt-datatable__row block-row`}>                                                   
                                                                                            <td width="50%"className="padding-l20 kt-datatable__cell text-clip">
                                                                                                <div className="kt-user-card-v2">
                                                                                                    <div className="kt-user-card-v2__pic">
                                                                                                        {m.Photo && m.Photo != '' ? 
                                                                                                        <img src={m.Photo} alt="user-profile" /> : 
                                                                                                        <div className={`kt-badge kt-badge--xl kt-badge--info`}> {m.Name.substring(0, 1)}</div>}
                                                                                                    </div>
                                                                                                    <div className="kt-user-card-v2__details text-clip">
                                                                                                        <span className="kt-user-card-v2__name text-clip"> {m.Name} </span> <span className="kt-user-card-v2__desc text-clip"> {m.Email} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td width="20%" className="kt-datatable__cell--center kt-datatable__cell text-clip">
                                                                                                <span>{m.Mobile || ''}</span>
                                                                                            </td>
                                                                                            <td width="15%" className="kt-datatable__cell--center kt-datatable__cell text-clip">
                                                                                                <span>{m.IsDisplay}</span>
                                                                                            </td>
                                                                                            <td width="15%" className="text-right kt-datatable__cell text-clip">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                    onClick={() => editHandler(m.ID)}
                                                                                                    >
                                                                                                    <i className="flaticon2-edit"></i>
                                                                                                </button>
                                                                                                <span style={{ marginRight: '38px' }}></span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>   
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Markers;



                                                                    