import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete } from "./axiosFunctions";

//#region Api URL's --> 

const getStaggeredMasterAllUrl          = '/api/admin/teetime/getStaggeredMasterAll';
const postStaggeredMasterUrl            = '/api/admin/teetime/postStaggeredMaster';

const getStaggeredBlockAllUrl           = '/api/admin/teetime/getStaggeredBlockAll';
const postStaggeredBlockUrl             = '/api/admin/teetime/postStaggeredBlock';

const getStaggeredTemplateDataUrl       = '/api/admin/teetime/getStaggeredTemplateData';
const postStaggeredTemplateUrl          = '/api/admin/teetime/postStaggeredTemplate';
const deleteStaggeredTemplateUrl        = '/api/admin/teetime/deleteStaggeredTemplate';

const getShotgunMasterAllUrl            = '/api/admin/teetime/getShotgunMasterAll';
const postShotgunMasterUrl              = '/api/admin/teetime/postShotgunMaster';

const getShotgunBlockAllUrl             = '/api/admin/teetime/getShotgunBlockAll';
const postShotgunBlockUrl               = '/api/admin/teetime/postShotgunBlock';

const getShotgunTemplateDataUrl         = '/api/admin/teetime/getShotgunTemplateData';
const postShotgunTemplateUrl            = '/api/admin/teetime/postShotgunTemplate';
const deleteShotgunTemplateUrl          = '/api/admin/teetime/deleteShotgunTemplate';

const getShotgunCalendarAllUrl          = '/api/admin/teetime/getShotgunCalendarData';   
const postShotgunCalendarUrl            = '/api/admin/teetime/postShotgunCalendar';  
const deleteShotgunCalendarUrl          = '/api/admin/teetime/deleteShotgunCalendar';  

const getShotgunMappingByCalendarIDUrl  = '/api/admin/teetime/getShotgunMappingByCalendarID';    

const getPublishPageDataUrl             = '/api/admin/teetime/getPublishPageData';    
const getPublishMasterDataUrl           = '/api/admin/teetime/getPublishMasterData';    
const postTeeTimePublishUrl             = '/api/admin/teetime/postTeeTimePublish';    
const verifyDateUrl                     = '/api/admin/teetime/verifyDate';  
const getShotgunBookUrl                 = '/api/admin/teetime/getShotgunBook';  
const getTeeTimeBookUrl                 = '/api/admin/teetime/getTeeTimeBook';  
const getShotgunBookSheetUrl            = '/api/admin/teetime/getShotgunBookSheet';  
const getTeeTimeBookSheetUrl            = '/api/admin/teetime/getTeeTimeBookSheet';  
const getBookingDetailsUrl              = '/api/admin/teetime/getBookingDetails';  
const postTeeTimeBlockUrl               = '/api/admin/teetime/postTeeTimeBlock';  
const deleteTeeTimeBlockUrl             = (id) => `/api/admin/teetime/deleteTeeTimeBlock/${id}`

const postTeeTimeBookUrl                = '/api/admin/teetime/postTeeTimeBook';
const deleteBookingUrl                  = (id, comment) => `/api/admin/teetime/deleteBooking/${id}/${comment}`

const getCancellationSheetUrl           = (fDate, tDate) => `/api/admin/teetime/getCancellationSheet/${fDate}/${tDate}`;
const getViewTeeTimeBookUserByDateUrl   = (fDate, tDate) => `/api/admin/teetime/getViewTeeTimeBookUserByDate/${fDate}/${tDate}`;
const sendNoShowEmailUrl               = '/api/admin/teetime/sendNoShowEmail';  


//#endregion

export const getStaggeredMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getStaggeredMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postStaggeredMaster= async(teeMaster)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postStaggeredMasterUrl, { teeMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getStaggeredBlockAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getStaggeredBlockAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postStaggeredBlock= async(teeBlocks)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postStaggeredBlockUrl, { teeBlocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getStaggeredTemplateData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getStaggeredTemplateDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postStaggeredTemplate= async( teeTemplates )=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postStaggeredTemplateUrl, { teeTemplates }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteStaggeredTemplate = async(templateId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteStaggeredTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getShotgunMasterAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getShotgunMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postShotgunMaster = async(shotgunMaster) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postShotgunMasterUrl, { shotgunMaster }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getShotgunBlockAll = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getShotgunBlockAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postShotgunBlock= async(shotgunBlocks)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postShotgunBlockUrl, { shotgunBlocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getShotgunTemplateData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getShotgunTemplateDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postShotgunTemplate= async( shotgunTemplates )=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postShotgunTemplateUrl, { shotgunTemplates }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteShotgunTemplate = async(templateId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteShotgunTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}


export const getShotgunCalendarData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getShotgunCalendarAllUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postShotgunCalendar = async(calendar, blocks) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postShotgunCalendarUrl, { calendar, blocks }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteShotgunCalendar = async(calendarId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosDelete( `${deleteShotgunCalendarUrl}/${calendarId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getShotgunMappingByCalendar = async(calendarId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getShotgunMappingByCalendarIDUrl}/${calendarId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getPublishPageData = async() => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( getPublishPageDataUrl, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getPublishMasterData = async(masterId) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet( `${getPublishMasterDataUrl}/${masterId}`, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postTeeTimePublish = async(data) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postTeeTimePublishUrl, {...data }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const verifyDate = async(date) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${verifyDateUrl}/${date}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getShotgunBook = async(date, blockId) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getShotgunBookUrl}/${date}/${blockId}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getTeeTimeBook = async(date, teeBox) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getTeeTimeBookUrl}/${date}/${teeBox}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const postTeeTimeBlock = async(books, date) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postTeeTimeBlockUrl, { books, date }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteTeeTimeBlock = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteTeeTimeBlockUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getShotgunBookSheet = async(date) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getShotgunBookSheetUrl}/${date}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getTeeTimeBookSheet = async(date) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getTeeTimeBookSheetUrl}/${date}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const getBookingDetails = async(id) => {
    const token = getAuthState('token');
    if(!token) setTimeout(() => { return }, 2000);

    const response = await axiosGet( `${getBookingDetailsUrl}/${id}`, { headers: { auth_token: `bearer ${token}` } });
    return response;
}

export const postTeeTimeBook = async (book, users, teeTimePayment) => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return }, 2000);
    
    const response = await axiosPost(
        postTeeTimeBookUrl,
      {  book, users, teeTimePayment},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteBooking = async (id, comment) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteBookingUrl(id, comment),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCancellationSheet = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCancellationSheetUrl(fDate, tDate),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getViewTeeTimeBookUserByDate = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getViewTeeTimeBookUserByDateUrl(fDate, tDate),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const sendNoShowEmail = async(data) =>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( sendNoShowEmailUrl, { data }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}