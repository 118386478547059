import { axiosPost, axiosGet } from "./axiosFunctions";
import { getTokenOrLogout } from "./common";

const uploadPublicFileUrl         = (folderName) => `/api/admin/file/uploadPublicFile/${folderName}`;
const uploadPrivateFileUrl        = (folderName) => `/api/admin/file/uploadPrivateFile/${folderName}`;
const downloadFileUrl             = (fileType, fileName) => `/api/admin/file/downloadFile/${fileType}/${fileName}`

const privateFolder = ['Document'];
const publicFolder = ['Content', 'User','Attachment'];

export const uploadPublicFile = async (file, folderName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    if (!publicFolder.includes(folderName)) throw new Error('Unknown file type')

    const formData = new FormData()
    formData.append('file', file)
    
    const response = await axiosPost(
        uploadPublicFileUrl(folderName),
        formData,
        {
            headers: { auth_token: `bearer ${token}`, "Content-Type":"multipart/form-data" },
            onUploadProgress(progress) { console.log('file upload progress:', `${progress.loaded}/${progress.total}`)},
        }
    )
    return response
}

export const uploadPrivateFile = async (file, folderName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  

    if (!privateFolder.includes(folderName)) throw new Error('Unknown file type')

    const formData = new FormData()
    formData.append('file', file)
    
    const response = await axiosPost(
        uploadPrivateFileUrl(folderName),
        formData,
        {
            headers: { auth_token: `bearer ${token}`, "Content-Type":"multipart/form-data" },
            onUploadProgress(progress) { console.log('file upload progress:', `${progress.loaded}/${progress.total}`)},
        }
    )
    return response
}

export const downloadFile = async (fileType, fileName) => {
    const token = getTokenOrLogout();
    if (!token) setTimeout(()=> { return }, 2000);  
       
    if (!privateFolder.includes(fileType)) throw new Error('Unknown file type')
    const response = await axiosGet(
        downloadFileUrl(fileType, fileName),
        {
            headers: {
                auth_token: `bearer ${token}`               
            }
        }
    )
    return response
}