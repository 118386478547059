import swal from 'sweetalert2';
import Select from 'react-select';
import { useEffect, useState } from "react";
import * as helper from '../global/helper';

const AddUser = (props) => {

    const [genderOption,                setGenderOption]             = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [userDetails,                 setUserDetails]              = useState({});

    useEffect(() => {
        $('#modalUser').modal({
            backdrop: 'static',
        });
        $('#modalUser').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
      
    }, [])

    const updateUserDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{

    }
    return(
        <>
            <div id="modalUser" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add User</h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal" 
                                aria-label="Close"
                                onClick={closeDrawer}
                            ></button>
                        </div>
                        <div className="modal-body">

                            <div id="ModalUserHolder" className="kt-form kt-form--label-right" role="form">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">First Name <span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input 
                                            id="txtFirstName" 
                                            name="txtFirstName" 
                                            type="text" 
                                            className="form-control" 
                                            value={userDetails.FirstName || ''}
                                            onChange={(event) => updateUserDetails('FirstName',  helper.camelCase(event.target.value))} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Last Name <span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input 
                                            id="txtLastName" 
                                            name="txtLastName" 
                                            type="text" 
                                            className="form-control" 
                                            value={userDetails.LastName || ''}
                                            onChange={(event) => updateUserDetails('LastName',  helper.camelCase(event.target.value))} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input 
                                            id="txtMobile" 
                                            name="txtMobile" 
                                            type="text" 
                                            minLength='10'
                                            maxLength='10'
                                            className="form-control" 
                                            value={userDetails.Mobile || ''}
                                            onChange={(event) => updateUserDetails('Mobile', event.target.value)} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Email <span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <input 
                                            id="txtEmail" 
                                            name="txtEmail" 
                                            type="text" 
                                            className="form-control" 
                                            value={userDetails.Email || ''}
                                            onChange={(event) => updateUserDetails('Email', event.target.value)} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Gender <span className="red">*</span></label>
                                    <div className="col-xl-6 col-lg-8">
                                        <div >
                                            <Select 
                                                id="ddlGender" 
                                                name="ddlGender"
                                                className="multi-custom"
                                                placeholder="Select Gender"
                                                value={userDetails && genderOption.find((m) => m.value == userDetails.Gender) || ''}
                                                onChange={(event) => updateUserDetails('Gender', event.value)}
                                                options={genderOption}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal" onClick={closeDrawer}>Close</button>
                            <button type="button" id="btnSaveUser" name="btnSaveUser" className="btn btn-brand margin-0 ">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddUser;