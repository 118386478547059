import { useState } from "react";
import ImageCropper from "../global/imageCropper";
import ImageUploader from "../global/imageUploader";
import Select from 'react-select';
import { useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { uploadPublicFile } from "../api/fileAPI";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdvertisementMasterAll, getAdvertisementMediaByAdvertisementID, postAdvertisementMaster } from "../api/advertisementAPI";


const AdvertisementMaster = (props) => {
    const [advertisementMasterList,            setAdvertisementMasterList]     = useState([]);
    const [advertisementMaster,                setAdvertisementMaster]         = useState({Active: 'Y'});
    const [rightSideDetails,                   setRightSideDetails]            = useState(false);
    const [isInit,                             setIsInit]                      = useState(true);
    const [redirectOption,                     setRedirectOption]              = useState([{ value:  'Form', label: 'Form'}, { value:  'Link', label: 'Link'},]);

    //<--- Cropper -->
    const [advertisementPhotoBlob,             SetAdvertisementPhotoBlob]      = useState(null);
    const [bannerPhotoBlob,                    SetBannerPhotoBlob]             = useState(null);
    const [coverPhotoBlob,                     SetCoverPhotoBlob]              = useState(null);
    const [cropperData,                        setCropperData]                 = useState(null);
        

    //<--- Multi Image Uploader -->
    const [showUploader,               setShowUploader]                        = useState(false);
    const [multiImages,                setMultiImages]                         = useState([]);
    
    useEffect(() => {
        props.title('Create', 'advertisement-master')
        fetchAdvertisementMaster()
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
           
        }else{
            setAdvertisementMaster({Active: 'Y'});
            SetAdvertisementPhotoBlob(null);
            SetBannerPhotoBlob(null);
            SetCoverPhotoBlob(null);
            setMultiImages([])
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [advertisementMasterList]);

    const fetchAdvertisementMaster = async() =>{
        try {
            const master = await getAdvertisementMasterAll()   
            isInit ? setIsInit(false) : $('#tblAdvertisementMaster').KTDatatable().destroy(); 
            setAdvertisementMasterList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAdvertisementMedia = async(ID) =>{
        try {
            const advMedia = await getAdvertisementMediaByAdvertisementID(ID)   
            setMultiImages(advMedia);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblAdvertisementMaster').KTDatatable({
            data: {
                saveState: false,
                source:advertisementMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                        ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="Logo" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.CompanyName} </span>
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'RedirectTo',
                    title: 'Redirect To ',
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setAdvertisementMaster(dataTable.dataSet[index]);  
                fetchAdvertisementMedia(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });

    }

    const dataHandler = (f) => {
        if(f.length > 0){
            const newArr = f.map( s => ({imgSrc:s.img, Caption:'', File: s.File}));
            setMultiImages( d => ([...d, ...newArr]));
        }
    }

    const onCaptionChange = (d,i) => {              
        setMultiImages((o) => {            
            o[i].Caption = d;        
            return [...o];            
        }) 
    }
    
    const onMediaRemove = (i) => {        
        setMultiImages((m)=>{
            m.splice(i,1);
            return [...m];
        });
    }

    const updateAdvertisementMasterDetails = (field, value) => {   
        setAdvertisementMaster((d) => {
            return { ...d, [field]: value };
        });
    };

    const addAdvertisementHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-Advertisement-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },          
                ddlRedirectTo: {
                    required: true
                },          
                txtURL: {
                    required: true
                },          
                txtEmail: {
                    email: advertisementMaster.Email ? true : false
                }          
            },
            messages: {
                txtEventTitle: {
                    required: "Please enter advertisement name"
                },
                ddlRedirectTo: {
                    required: "Please select redirect to"
                },
                txtURL: {
                    required: "Please enter url"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let advertisementLogo = '';
            //Call upload API -->
            if(advertisementPhotoBlob){
                const res = await uploadPublicFile(advertisementPhotoBlob,'Content');
                advertisementLogo = res.fileName;            
            }else{
                if(advertisementMaster.Logo) advertisementLogo = advertisementMaster.Logo.split('/Content/')[1];
            }

            let bannerPhoto = '';
            //Call upload API -->
            if(bannerPhotoBlob){
                const res = await uploadPublicFile(bannerPhotoBlob,'Content');
                bannerPhoto = res.fileName;            
            }else{
                if(advertisementMaster.BannerImage) bannerPhoto = advertisementMaster.BannerImage.split('/Content/')[1];
            }

            let advertisementCoverImage = '';
            //Call upload API -->
            if(coverPhotoBlob){
                const res = await uploadPublicFile(coverPhotoBlob,'Content');
                advertisementCoverImage = res.fileName;            
            }else{
                if(advertisementMaster.CoverImage) advertisementCoverImage = advertisementMaster.CoverImage.split('/Content/')[1];
            }


            let mediaList = [];
            for (const  [index, m]  of multiImages.entries()) {
                let adImage = '';
                if(m.File && m.File !== '' && !m.ID){
                    const res = await uploadPublicFile(m.File, 'Content')
                    adImage = res.fileName;
                }

                mediaList.push({
                    ID      :   m.ID || '',
                    Caption :   m.Caption,
                    Image   :   (m.Image && m.Image.split("/").pop()) || adImage,
                    Index   :   index
                })
            }

            const advertisementDetails = {
                ID:                  advertisementMaster.ID || '',
                Name:                advertisementMaster.Name,
                CompanyName:         advertisementMaster.CompanyName || '',
                Email:               advertisementMaster.Email || '',
                Logo:                advertisementLogo || '',
                BannerImage:         bannerPhoto || '',
                RedirectTo:          advertisementMaster.RedirectTo,
                URL:                 advertisementMaster.URL || '',
                Title:               advertisementMaster.Title || '',
                Description:         advertisementMaster.Description || '',
                CoverImage:          advertisementCoverImage || '',
                Active:              advertisementMaster.Active
            }

            await postAdvertisementMaster(advertisementDetails, mediaList);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchAdvertisementMaster()
            if (advertisementMaster.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Advertisement updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Advertisement added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  SetAdvertisementPhotoBlob(val),
            header : 'Upload Logo'
        })
    }

    const openBannerCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 300},
            dataHandler : (val) =>  SetBannerPhotoBlob(val),
            header : 'Upload Banner'
        })
    }

    const openCoverImageCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 300},
            dataHandler : (val) =>  SetCoverPhotoBlob(val),
            header : 'Upload Cover Image'
        })
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Advertisement..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Advertisement</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblAdvertisementMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Advertisement Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-Advertisement-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={advertisementMaster.Name || ''}
                                                            onChange={(event) => updateAdvertisementMasterDetails('Name', event.target.value)} 
                                                        />						
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Company Name </label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <input 
                                                            id="txtCompanyName" 
                                                            name="txtCompanyName" 
                                                            className="form-control" 
                                                            value={advertisementMaster.CompanyName || ''}
                                                            onChange={(event) => updateAdvertisementMasterDetails('CompanyName', event.target.value)}
                                                        />
                                                    </div>
                                                </div>                                      
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Email </label>
                                                    <div className="col-xl-4 col-lg-6">
                                                    <input 
                                                        id="txtEmail" 
                                                        name="txtEmail" 
                                                        type="text" 
                                                        className="form-control"
                                                        placeholder="Email "
                                                        value={advertisementMaster.Email  || ''}
                                                        onChange={(event) => updateAdvertisementMasterDetails('Email', event.target.value)} 
                                                    />
                                                    </div>
                                                </div>                                      
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Logo </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!advertisementPhotoBlob || advertisementMaster.Logo) &&(<>
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${advertisementPhotoBlob ? URL.createObjectURL(advertisementPhotoBlob) : advertisementMaster.Logo && (advertisementMaster.Logo) })` } }>    
                                                                </div>
                                                            </div>
                                                        </>) }

                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                               {(!!advertisementPhotoBlob || advertisementMaster.Logo) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(200 W x 200 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Banner </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!bannerPhotoBlob || advertisementMaster.BannerImage) &&(<>
                                                            <img height={150} src={bannerPhotoBlob ? URL.createObjectURL(bannerPhotoBlob) : advertisementMaster.BannerImage && (advertisementMaster.BannerImage) }/>
                                                        </>) }
                                                        <p className="mt-3">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openBannerCropper}>
                                                               {(!!bannerPhotoBlob || advertisementMaster.BannerImage) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(500 W x 300 H pixels)</p>                                                       
                                                    </div>
                                                </div>                                        
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox" 
                                                                    defaultChecked={advertisementMaster.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateAdvertisementMasterDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Redirect To <span className="red">*</span></label>
                                                    <div className="col-2">
                                                        <Select 
                                                            id="ddlRedirectTo" 
                                                            name="ddlRedirectTo"
                                                            className="multi-custom"
                                                            placeholder="Select"
                                                            value={advertisementMaster && redirectOption.find((m) => m.value == advertisementMaster.RedirectTo) || ''}
                                                            onChange={(event) => updateAdvertisementMasterDetails('RedirectTo', event.value)}
                                                            options={redirectOption}
                                                        />
                                                    </div>
                                                </div>
                                                {advertisementMaster.RedirectTo == 'Link' && (
                                                    <>
                                                     <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">URL <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-6">
                                                            <input 
                                                                id="txtURL" 
                                                                name="txtURL" 
                                                                className="form-control" 
                                                                value={advertisementMaster.URL || ''}
                                                                onChange={(event) => updateAdvertisementMasterDetails('URL', event.target.value)}
                                                            />
                                                        </div>
                                                    </div> 
                                                    </>
                                                )}
                                                {advertisementMaster.RedirectTo == 'Form' && (
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Title</label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div className="input-group date">
                                                                    <input
                                                                        id="txtTitle"
                                                                        name="txtTitle"
                                                                        type="text"
                                                                        className="form-control" 
                                                                        value={advertisementMaster.Title || ''} 
                                                                        onChange={(event) => updateAdvertisementMasterDetails('Title', event.target.value)}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Description </label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <textarea 
                                                                    id="txtDescription" 
                                                                    name="txtDescription" 
                                                                    className="form-control" 
                                                                    rows="3"
                                                                    value={advertisementMaster.Description || ''}
                                                                    onChange={(event) => updateAdvertisementMasterDetails('Description', event.target.value)}
                                                                ></textarea>
                                                            </div>
                                                        </div>  
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3"> Cover Image </label>
                                                            <div className="col-sm-9">                                                        
                                                                {(!!coverPhotoBlob || advertisementMaster.CoverImage) &&(<>
                                                                    <img height={150} src={coverPhotoBlob ? URL.createObjectURL(coverPhotoBlob) : advertisementMaster.CoverImage && (advertisementMaster.CoverImage) }/>
                                                                </>) }
                                                                <p className="mt-3">
                                                                    <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCoverImageCropper}>
                                                                    {(!!coverPhotoBlob || advertisementMaster.CoverImage) ? 'Change Image' : 'Upload Image'}
                                                                    </button>
                                                                </p>
                                                                <p className="">(500 W x 300 H pixels)</p>                                                       
                                                            </div>
                                                        </div>
                                                        {/* FOR Multi Image Uploader */}
        
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3"> Images </label>
                                                            <div className="col-sm-9">                                                                                                                
                                                                <p className="">
                                                                    <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={() => setShowUploader(true)}>
                                                                        <i className="flaticon-upload-1"></i> Upload Images
                                                                    </button>
                                                                </p>
                                                                                                                    
                                                            </div>
                                                        </div>
                                                        {multiImages.length > 0 && (
                                                        <div className="row justify-content-center">
                                                            <div className="col-xl-6">
                                                                <table className="table table-striped table-bordered table-hover">
                                                                    <thead>
                                                                        <tr><th>Index</th><th>Image</th><th>Caption</th><th>Action</th></tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {multiImages.map((t,i)=>{
                                                                            return(
                                                                                <tr key={i}>
                                                                                    <td className="text-center">{i + 1}</td>
                                                                                    <td>
                                                                                        {t.Image && t.Image != '' ?
                                                                                        <img src={t.Image} alt="user-profile" width="100" height="100"/> :
                                                                                        <img className="media-image" src={t.imgSrc} width="100" height="100"/>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <textarea className="form-control caption" cols="20" rows="3" value={t.Caption} onChange={({target}) => onCaptionChange(target.value, i)}/>
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <button  onClick={() => onMediaRemove(i)} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete delete-dynamic-row" type="button">
                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>                
                                                    )}
                                                    {/* Multi Image Uploader ENDS */}
                                                    </>
                                                )} 
                                                
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addAdvertisementHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {showUploader && (
                <ImageUploader
                    show           = {showUploader} 
                    dataHandler    = {(d) => dataHandler(d)}                
                    onDismissModal = {() => setShowUploader(false) }
                />
            )}
        </>
    )
}
export default AdvertisementMaster;