const AssessmentTracker = (props) => {

    const { userDetails } = props;
 
    return(
        <>
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                </div>
            </div>
            <div className="kt-portlet__body padding-t50 tracker-onboarding">
                <div className="kt-notes">
                    <div className="kt-notes__items">
                        <div className="kt-notes__item">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    <span className={`kt-badge kt-badge--${userDetails.FormSubmit == "Y" ? 'success' : 'warning'} kt-badge--dot kt-badge--xl`}></span>
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section" >
                                    <div className={`kt-notes__info  ${userDetails.FormSubmit == "Y" && 'padding-r30'}`}>
                                        <a href="#" className="kt-notes__title">
                                            Coach
                                        </a>
                                        <span className={`kt-badge kt-badge--${userDetails.FormSubmit == "Y" ? 'success' : 'warning'} kt-badge--inline kt-badge--inline kt-badge--pill padding-lr10`}>{userDetails.FormSubmit == "Y" ? 'Submitted' : 'Form Pending'}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="kt-notes__item  ">
                            <div className="kt-notes__media">
                                <span className="kt-notes__icon">
                                    {userDetails.Submit == "Y"  ? <i className="flaticon2-check-mark" style={{color: '#0abb87'}}></i> : <i className="flaticon2-time" style={{color: '#e1e1ef'}}></i>}
                                </span>
                            </div>
                            <div className="kt-notes__content">
                                <div className="kt-notes__section">
                                    <div className="kt-notes__info">
                                        <a href="#" className="kt-notes__title">{userDetails.Submit == "Y" ? 'Completed' : 'Pending'}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};


export default AssessmentTracker;