import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import Select from 'react-select';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getAdvertisementMasterAll, getAdvertisementPostAll, postAdvertisementPost } from "../api/advertisementAPI";


const AdvertisementPost = (props) => {

    const [advertisementPostList,              setAdvertisementPostList]       = useState([]);
    const [advertisementPost,                  setAdvertisementPost]           = useState({Active: 'Y'});
    const [rightSideDetails,                   setRightSideDetails]            = useState(false);
    const [isInit,                             setIsInit]                      = useState(true);
    const [advertisementMasterList,            setAdvertisementMasterList]     = useState([]);
    const [pageOption,                         setPageOption]                  = useState([{ value:  'Notice Board', label: 'Notice Board'},{ value:  'Notice Board Card', label: 'Notice Board Card'}, { value:  'Golf Of The Year', label: 'Golf Of The Year'}, { value:  'Hole In One', label: 'Hole In One'}]);


    useEffect(() => {
        props.title('Post', 'advertisement-post')
        fetchAdvertisementPost();
        fetchAdvertisementMaster();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            $('#txtFromDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtFromDate').on('change', function (event) {
                updateAdvertisementPostDetails('FromDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtToDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtToDate').on('change', function (event) {
                updateAdvertisementPostDetails('ToDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
           
        }else{
            setAdvertisementPost({Active: 'Y'});
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [advertisementPostList]);

    const fetchAdvertisementPost = async() =>{
        try {
            const adsPost = await getAdvertisementPostAll()   
            isInit ? setIsInit(false) : $('#tblAdvertisementPost').KTDatatable().destroy(); 
            setAdvertisementPostList(adsPost);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAdvertisementMaster = async() =>{
        try {
            const master = await getAdvertisementMasterAll()  
            setAdvertisementMasterList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblAdvertisementPost').KTDatatable({

            data: {
                saveState: false,
                source: advertisementPostList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [              
                {
                    field: 'AdvertisementName',
                    title: 'Advertisement',
                    template: function (row) {
                        return '<div class="kt-user-card-v2"><div class="kt-user-card-v2__pic"><div class="kt-badge kt-badge--xl kt-badge--info">' + row.AdvertisementName.substring(0, 1).toUpperCase() + '</div></div><div class="kt-user-card-v2__details text-clip"><span class="kt-user-card-v2__name text-clip">' + row.AdvertisementName + '</span><span class="kt-user-card-v2__desc text-clip">' + row.CompanyName + '</span></div></div>'
                    }
                },          
                {
                    field: 'Page',
                    title: 'Page ',
                },
                {
                    field: 'FromDate',
                    title: 'From Date',
                    template: function (row) {
                        return moment(row.FromDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'ToDate',
                    title: 'To Date',
                    template: function (row) {
                        return moment(row.ToDate).format('DD-MMM-YYYY');
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
           
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setAdvertisementPost(dataTable.dataSet[index]);  
                setRightSideDetails(true); 
            }    
        });
    }

    const updateAdvertisementPostDetails = (field, value) => {   
        setAdvertisementPost((d) => {
            return { ...d, [field]: value };
        });
    };

    const addAdvertisementPostHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-Advertisement-form');
        
        form.validate({
            rules: {
                ddlAdvertisement: {
                    required: true
                },          
                ddlPage: {
                    required: true
                },          
                txtFromDate: {
                    required: true
                },          
                txtToDate: {
                    required: true
                },          
                txtIndex: {
                    required: true
                }          
            },
            messages: {
                ddlAdvertisement: {
                    required: "Please select advertisement"
                },
                ddlPage: {
                    required: "Please select page"
                },
                txtFromDate: {
                    required: "Please select from date"
                },
                txtToDate: {
                    required: "Please select to date"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {

            const advertisementDetails = {
                ID:                   advertisementPost.ID || '',
                AdvertisementID:      advertisementPost.AdvertisementID,
                Page:                 advertisementPost.Page,
                RefID:                0,
                FromDate:             advertisementPost.FromDate,
                ToDate:               advertisementPost.ToDate,
                Index:                advertisementPost.Index,
                Active:               advertisementPost.Active
            }

            await postAdvertisementPost(advertisementDetails);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchAdvertisementPost()
            if (advertisementPost.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Advertisement updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Advertisement Posted successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Advertisement..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Advertisement Post</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblAdvertisementPost" className="kt-datatable table-striped clickable"></div>
                            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                {rightSideDetails && <RightSideDetails title="Advertisement Details" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="add-Advertisement-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Advertisement <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-6">
                                                            <div>
                                                                <Select 
                                                                    id="ddlAdvertisement" 
                                                                    name="ddlAdvertisement"
                                                                    className="multi-custom"
                                                                    placeholder="Select Advertisement"
                                                                    value={advertisementPost && advertisementPost.AdvertisementID && {label: advertisementMasterList.find((m) => m.ID == advertisementPost.AdvertisementID).Name, value: advertisementPost.AdvertisementID} || ''}
                                                                    onChange={(event) => updateAdvertisementPostDetails('AdvertisementID', event.value)}
                                                                    options={advertisementMasterList.map(a => ({
                                                                        label: a.Name,
                                                                        value: a.ID,
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Page <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-6">
                                                            <div>
                                                                <Select 
                                                                    id="ddlPage" 
                                                                    name="ddlPage"
                                                                    className="multi-custom"
                                                                    placeholder="Select Page"
                                                                    value={advertisementPost && pageOption.find((m) => m.value == advertisementPost.Page) || ''}
                                                                    onChange={(event) => updateAdvertisementPostDetails('Page', event.value)}
                                                                    options={pageOption}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="pnlTournament" className="hidden">
                                                        <label className="col-form-label col-lg-3">Tournament <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-6">
                                                            <div>
                                                                <Select 
                                                                    id="ddlTournament" 
                                                                    name="ddlTournament"
                                                                    className="multi-custom"
                                                                    placeholder="Select Tournament"
                                                                    // value={advertisementPost && tournament.find((m) => m.value == advertisementPost.RefID) || ''}
                                                                    // onChange={(event) => updateAdvertisementPostDetails('RefID', event.value)}
                                                                    // options={tournament}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">From Date <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtFromDate" 
                                                                name="txtFromDate" 
                                                                type="text" 
                                                                className="form-control  date-input" 
                                                                placeholder="Select date" 
                                                                readOnly={true}
                                                                value={advertisementPost.FromDate ? moment(advertisementPost.FromDate).format('DD-MM-YYYY') : ''}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                    </div>      
                                                    <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">To Date <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtToDate" 
                                                                name="txtToDate" 
                                                                type="text" 
                                                                className="form-control  date-input" 
                                                                placeholder="Select date" 
                                                                readOnly={true}
                                                                value={advertisementPost.ToDate ? moment(advertisementPost.ToDate).format('DD-MM-YYYY') : ''}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>   
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                        <div className="col-xl-3 col-lg-6">
                                                            <input 
                                                                id="txtIndex" 
                                                                name="txtIndex" 
                                                                type="number" 
                                                                className="form-control"
                                                                value={advertisementPost.Index || advertisementPost.Index == 0 ? advertisementPost.Index : ''}
                                                                onChange={(event) => updateAdvertisementPostDetails('Index', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Active</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <span className="kt-switch kt-switch--icon">
                                                                <label>
                                                                    <input 
                                                                        id="chkActive" 
                                                                        name="chkActive" 
                                                                        type="checkbox" 
                                                                        defaultChecked={advertisementPost.Active == 'N' ? '' : 'checked'}
                                                                        onChange={(event) => updateAdvertisementPostDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                    />                                                                
                                                                    <span></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>                                                                                                                       
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addAdvertisementPostHandler}>Save</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvertisementPost;