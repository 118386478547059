import React, { useState, useEffect } from "react";
import * as helper                    from "../global/helper";
import nofile from "../../assets/images/no-file.svg";
import emailLogo from "../../assets/images/email.png";
import Template from "./template";
import Loading from "../global/loading";

const PreviewModal = (props) => {
    const { templatePreview } = props;

    useEffect(() => {
        $('#emailPreview-modal').modal({ backdrop: 'static' });
  
        $('#emailPreview-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          props.onDismissModal(); 
        });
    }, []);

    return(
        <div className="modal fade" id="emailPreview-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">  Email Template </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    <div className="client-container">
                      <Template templatePreview = {templatePreview.Template}  />
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary margin-0 margin-r5"
                        data-dismiss="modal">   
                        Close
                    </button>
                </div>
            </div>
            </div>
        </div>
    )
}


const EmailTemplateTable = (props) => {

    const{ onEditHandler, onDeleteHandler, onSendHandler, loading } = props;

    const [Templates,         setTemplates]         = useState([]);
    const [templatePreview,   setTemplatePreview]   = useState(null);
	const [searchTerm,    	  setSearchTerm]       	= useState('');

    useEffect(() => {
      if(props.Templates && props.Templates.length > 0 ){
		  setTemplates(props.Templates)
		}else{
			setTemplates([])
		} 
    },[props.Templates]);


	useEffect(() => {  
		let AllTemplates = props.Templates;
  
		if(searchTerm == '')  return setTemplates(AllTemplates);
	  
		const matchedData = AllTemplates.filter(c => {
		  let matchString = ` ${c.Name}`;
		  let matchFound = 0;
			
		  const searchArr = searchTerm.split(' ');
		  searchArr.forEach(term => {
			matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
		  });
			
		  return matchFound === searchArr.length;
		})
	  
		setTemplates(matchedData);
	},[searchTerm])


    useEffect(()=>{
      helper.SetScrollHeight();
    }); 
     return (
        <>
        <div className="kt-form kt-form--label-right kt-margin-b-10">
		{loading ? <Loading /> 
		:<>
			<div className='row align-items-center stickyFilter'>
				<div className="col-xl-12 order-2 order-xl-1 px-0">
					<div className="row align-items-center">
						<div className='col-md-4 kt-margin-b-20-tablet-and-mobile'>
							<div className="kt-input-icon kt-input-icon--left ml-2">
								<input
									type="text"
									className="form-control"
									placeholder="Search Email..."
									id="generalSearch"
									onChange={({target}) => setSearchTerm(target.value) } />
								<span className="kt-input-icon__icon kt-input-icon__icon--left">
									<span>
										<i className="la la-search"></i>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{Templates && Templates.length > 0 ? (
			<div className="row">
				<div className="col-sm-12">
					<div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
						<table className="kt-datatable__table">
							<thead
								className="kt-datatable__head"
								style={{ display: "block" }}>
								<tr className="kt-datatable__row block-row">
									<th className="kt-datatable__cell text-clip" width="75%">
										<span>Template</span>
									</th>
									<th className="kt-datatable__cell" width="10%">
										<span>Actions</span>
									</th>
								</tr>
							</thead>
						</table>
					</div>
				<div
					className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
					// onScroll={helper.setStickyHeader}
					>
					<table className="kt-datatable__table table-striped">
					<tbody
						className="kt-datatable__body"
						style={{ display: "block", minHeight:'40vh' }}>
						{Templates.map((t, i) => {
						return (
							<tr key={i} className="kt-datatable__row block-row ">
								<td className="kt-datatable__cell text-clip" width="85%" title={t.Name}>
								<div className="kt-user-card-v2">
									<img src={emailLogo} alt="image" style={{height:'35px'}}/>
									<div className="kt-user-card-v2__details text-clip ml-3">
									<span className="kt-user-card-v2__name text-clip">
										{t.Name}
									</span>
									</div>
								</div>
								</td>
							
								<td width="10%"  className="kt-datatable__cell ">
									<div className="dropdown">
										<span className="btn btn-sm btn-clean btn-icon btn-icon-md clickable" data-toggle="dropdown" aria-expanded="false">
											<i className="flaticon-more-1"></i>
										</span>
										<div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
											<span className="dropdown-item clickable"  onClick={() => setTemplatePreview(t)}>
												<i className="la la-file-text"></i> Preview
											</span>
											<span className="dropdown-item clickable" onClick={() => onSendHandler(t)}>
												<i className="la la-envelope"></i> Send
											</span>
											<span className="dropdown-item clickable" onClick={() => onEditHandler(t)}>
												<i className="la la-edit"></i> Edit
											</span>
											<span className="dropdown-item clickable" onClick={() => onDeleteHandler(t)}>
												<i className="la la-trash"></i> Delete
											</span>
										</div>
									</div>
								</td>
							</tr>
						);
						})}
					</tbody>
					</table>
				</div>
				</div>
			</div>
			) : (
				<div className="padding-20" style={{ margin:'auto'}}>
					<div className="empty-image-container">
						<img style={{ width: '80%' }} src={nofile} alt="no-client-img" />
						<h4 className="margin-t20" style={{ fontSize :'18px' }}> No Templates Found </h4>
					</div>
				</div>
			)}
			
			
		</>}
		</div>
		{templatePreview && (
			<PreviewModal
                onDismissModal={() => setTemplatePreview(null)}
                templatePreview = { templatePreview }
			/>
		)}
        </>
     )
}
export default EmailTemplateTable;