import { useEffect, useState } from "react";
import swal from 'sweetalert2';
import { postOfflineMonthlyPayment, updateMonthlyPayment } from "../api/paymentAPI";
import * as helper from "../global/helper";

const EditMemberMonthlyPayment = (props) => {
    
    const [memberDetails,                   setMemberDetails]         = useState({});

    useEffect(() => {
        $('#edit-Member-modal').modal({ backdrop: 'static' });
  
        $('#edit-Member-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          props.onDismissModal({}); 
        });
    }, []);

    useEffect(() => {
        if(props.memberDetails){
            const stringWithoutComma = props.memberDetails.BillAmount.replace(/,/g, "");
            setMemberDetails({...props.memberDetails, BillAmount: stringWithoutComma});
        }
    }, [props.memberDetails]);

    const updateMemberDetails = (field, value) => { 
        setMemberDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateMemberHandler = async(type) => {
        const btn = type == 'update' ? $('#btnUpdate') : $('#btnPay');
        var form = $('#updateMember');

        form.validate({
            rules: {
                txtBillAmount: {
                    required: true
                }   
            },
            messages: {
                txtBillAmount: {
                    required: "Please add amount"
                }
            }
        });

        if (!form.valid()) return;
        
        helper.StartProcessing(btn);
        try {
            // Remove the comma from the string
            const stringWithoutComma = memberDetails.BillAmount !== undefined && memberDetails.BillAmount !== null ? memberDetails.BillAmount.toString().replace(/,/g, "") : '';
            // Convert the modified string to a float
            const myFloat = parseFloat(stringWithoutComma);    
            
            // return
            if(isNaN(myFloat) || memberDetails.BillAmount == 0){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Bill amount is not correct',
                    buttonsStyling        : false,  
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return
            }
            if(type == 'update'){
                await updateMonthlyPayment({...memberDetails, BillAmount: myFloat});
            }else{
                const swalResult = await swal.fire({
                    title               : 'Are you sure?',
                    text                : "You won't be able to revert this!",
                    icon                : 'warning',
                    showCancelButton    : true,
                    buttonsStyling      : false,
                    reverseButtons      : true,
                    showLoaderOnConfirm : true,
                    confirmButtonClass  : 'btn btn-brand',
                    cancelButtonClass   : 'btn btn-secondary',
                    confirmButtonText   : 'Proceed',
                    preConfirm: async () => {
                        try {
                            await postOfflineMonthlyPayment({...memberDetails, BillAmount: myFloat});
                            $('#edit-Member-modal').modal('hide');
                            props.setMemberDetails({})
                                
                        } catch (err) {
                            swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                            });
                            return;
                        }
                    },
                });
                helper.StopProcessing(btn);
                if (!swalResult.value) return;
            };

            helper.StopProcessing(btn);
            
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: type == 'update' ? 'Bill Amount updated successfully!' : 'Payment added successfully',
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                animation: false,
                customClass: {
                    popup: 'margin-20',
                },
            });
            props.refetchReport();

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    return(
        <>
            <div className="modal fade"id="edit-Member-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Bill Amount</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="updateMember" >                
                                <div id="ControlHolder" className="kt-form kt-form--label-right bv-form" role="form">
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label col-4 text-right"> Bill Amount </label>
                                        <div className="col-4">
                                            <input 
                                                id="txtBillAmount" 
                                                name="txtBillAmount" 
                                                type="number" 
                                                className="form-control"
                                                value={memberDetails.BillAmount  || ''}
                                                onChange={(event) => updateMemberDetails('BillAmount', event.target.value)} 
                                            />
                                        </div>
                                        <div className="form-group col-3 pnlStatus ">
                                             <button className="btn btn-label-brand btn-block margin-0" id="btnUpdate"
                                             onClick={() => updateMemberHandler('update')}
                                            >
                                            Update Amount
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-0">
                                        <label className="col-form-label col-4 text-right"> Remark </label>
                                        <div className="col-4">
                                            <textarea 
                                                id="txtComment" 
                                                name="txtComment" 
                                                className="form-control" 
                                                rows="3"
                                                value={memberDetails.Comment || ''}
                                                onChange={(event) => updateMemberDetails('Comment', event.target.value)} 
                                            ></textarea>
                                        </div>
                                     </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                id="btnPay"
                                onClick={() => updateMemberHandler('pay')}
                                type="button"
                                className={`btn btn-success margin-0 `}
                            >                  
                                Pay Offine
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditMemberMonthlyPayment;