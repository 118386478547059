import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../components/global/header';
import SideMenu from '../components/global/sideMenu';
import Club from '../components/golfClub/club';
import Course from '../components/golfClub/course';
import NoticeBoard from '../components/golfClub/noticeBoard';
import TeeBox from '../components/golfClub/teeBox';
import AdminMenu from '../components/settings/adminMenu';
import AdminUser from '../components/settings/adminUser';
import EmailTemplate from '../components/settings/emailTemplate';
import AdminRole from '../components/settings/adminRole';
import IOSUpdate from '../components/appControl.js/iOSUpdate';
import AdminUpdate from '../components/appControl.js/adminUpdate';
import AndroidUpdate from '../components/appControl.js/androidUpdate';
import AndroidService from '../components/appControl.js/androidService';
import IOSService from '../components/appControl.js/iOSService';
import AdminService from '../components/appControl.js/adminService';
import HolidayCalender from '../components/golfClub/holidayCalender';
import TournamentCalender from '../components/golfClub/tournamentCalender';
import Users from '../components/users/users';
import Event from '../components/golfClub/event';
import ShotgunBlock from '../components/teeTime/shotgunBlock';
import ShotgunCalender from '../components/teeTime/shotgunCalender';
import ShotgunMaster from '../components/teeTime/shotgunMaster';
import ShotgunTemplate from '../components/teeTime/shotgunTemplate';
import StaggeredBlock from '../components/teeTime/staggeredBlock';
import StaggeredMaster from '../components/teeTime/staggeredMaster';
import StaggeredTemplate from '../components/teeTime/staggeredTemplate';
import EventRegistration from '../components/golfClub/eventRegistration';
import CourseClosed from '../components/golfClub/courseClosed';
import HoleInOne from '../components/golfClub/holeInOne';
import AdvertisementMaster from '../components/advertisment/advertisementMaster';
import TeeTimeBlocking from '../components/teeTime/teeTimeBlocking';
import TeeTimePublish from '../components/teeTime/teeTimePublish';
import BookingSheet from '../components/teeTime/bookingSheet';
import Guest from '../components/users/guest';
import ConciergeMaster from '../components/golfClub/conciergeMaster';
import CancellationSheet from '../components/teeTime/cancellationSheet';
import Feedback from '../components/golfClub/feedback';
import AdvertisementPost from '../components/advertisment/advertisementPost';
import Emailer from '../components/golfClub/emailer';
import TeeTimeNoShowReport from '../components/teeTime/teeTimeNoShowReport';
import SportsMaster from '../components/sports/sportsMaster';
import SportsReport from '../components/sports/sportReport';
import ScorecardMaster from '../components/golfClub/scoreCardMaster';
import InvoiceTemplate from '../components/settings/invoiceTemplate';
import MatMaster from '../components/mat/matMaster';
import MatTimeMaster from '../components/mat/matTimeMaster';
import MatTemplate from '../components/mat/matTemplate';
import MatBlocking from '../components/mat/matBlocking';
import MatBookingSheet from '../components/mat/matBookingSheet';
import MatCancellationSheet from '../components/mat/matCancellationSheet';
import FacilityMaster from '../components/sports/facilityMaster';
import TimeMaster from '../components/sports/timeMaster';
import SportsTemplate from '../components/sports/sportsTemplate';
import SportsPublish from '../components/sports/sportsPublish';
import SportsBookingSheet from '../components/sports/sportsBookingSheet';
import SportsCancellationSheet from '../components/sports/sportsCancellationSheet';
import AffiliateClub from '../components/golfClub/affiliateClub';
import MatPublish from '../components/mat/matPublish';
import SportsBlocking from '../components/sports/sportsBlocking';
import Dashboard from '../components/DashBoard/dashboard';
import { dashboardPeriods } from '../assets/scripts/periodSelection';
import Markers from '../components/sports/markers';
import GameScorecard from '../components/scorecard/gameScorecard';
import OnAccountPayment from '../components/payment/onAccountPayment';
import MonthlyPayment from '../components/payment/monthlyPayment';
import SubscriptionMaster from '../components/payment/subscriptionMaster';
import SubscriptionCharges from '../components/payment/subscriptionCharges';
import SubscriptionPayment from '../components/payment/subscriptionPayment';
import Onboarding from '../components/onboardingUser/onboarding';
import TaxType from '../components/fnb/taxType';
import TaxStructure from '../components/fnb/taxStructure';
import RateMaster from '../components/fnb/rateMaster';
import CheckInFacilityMaster from '../components/checkIn/checkInFacilityMaster';
import CheckInReport from '../components/checkIn/checkInReport';
import MenuCategory from '../components/fnb/menuCategory';
import MenuSubcategory from '../components/fnb/menuSubcategory';
import MenuItem from '../components/fnb/menuItem';
import Assessment from '../components/academy/assessment';
import CoachMaster from '../components/coach/coachMaster';
import Calender from '../components/coach/calendar';
import CoachBlock from '../components/coach/coachBlock';
import CoachBooking from '../components/coach/coachBooking';
import CoachBookCancellation from '../components/coach/coachBookCancellation';
import CoachBookReport from '../components/coach/coachBookReport';


const Admin = (props) => {

	const [headerTitle,                   setHeaderTitle]                       = useState('');
    const [menuID,                        setMenuID]                            = useState(null);
    const [sportType,                     setSportType]                         = useState(0)
	const [currentPeriod,                 setCurrentPeriod]                     = useState(dashboardPeriods[0]);
	const [fromDate,                      setFromDate]                          = useState(dashboardPeriods[0].from);
	const [toDate,                        setToDate]                            = useState(dashboardPeriods[0].to);
	const [sportsList,                    setSportList]                         = useState([]);
	const [fetchSportReport,              setFetchSportReport]                  = useState(false)
    
    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    useEffect(() => {
        if(sportsList && sportsList.length > 0) {
            setSportType(sportsList[0].ID);
        }
    }, [sportsList])

    useEffect(() => {
		if (currentPeriod.id != 6) {
			setFromDate(currentPeriod.from);
			setToDate(currentPeriod.to);                        
		}
	}, [currentPeriod]);


    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} title={toggleState} />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header 
                    onLogout={props.onAuthStateChange} 
                    title={headerTitle} 
                    setCurrentPeriod={setCurrentPeriod} 
                    currentPeriod={currentPeriod} 
                    setFromDate={setFromDate} 
                    fromDate={fromDate} 
                    setToDate={setToDate} 
                    toDate={toDate} 
                    setSportList={setSportList} 
                    sportsList={sportsList} 
                    setSportType={setSportType}
                    fetchSportReport = {v => setFetchSportReport(v)}
                /> 
                {menuID && <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                        <Route path='/dashboard' exact>
                            <Dashboard
                                title={toggleState}
                                sportType={sportType}
                                fromDate={fromDate} 
                                toDate={toDate} 
                                currentPeriod={currentPeriod} 
                                sportsList={sportsList} 
								fetchSportReportInHeader={fetchSportReport}
								setCustomReport={v => setFetchSportReport(v)}
                            />
                        </Route>
                    {/* Coach */}
                    <Route path="/coach-master">
                        <CoachMaster title={toggleState}/>
                    </Route>
                    <Route path="/calendar">
                        <Calender title={toggleState}/>
                    </Route>
                    <Route path="/coach-block">
                        <CoachBlock title={toggleState}/>
                    </Route>
                    <Route path="/coach-book">
                        <CoachBooking title={toggleState}/>
                    </Route>
                    <Route path="/coach-cancel-sheet">
                        <CoachBookCancellation title={toggleState}/>
                    </Route>
                    <Route path="/coach-booking-report">
                        <CoachBookReport title={toggleState}/>
                    </Route>
                    {/*Mat*/}
                    <Route path="/mat-sheet" exact>
                        <MatBookingSheet
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-publish" exact>
                        <MatPublish
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-cancel-sheet" exact>
                        <MatCancellationSheet
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-master" exact>
                        <MatMaster
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-time-master" exact>
                        <MatTimeMaster
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-template" exact>
                        <MatTemplate
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-block" exact>
                        <MatBlocking
                            title={toggleState}
                        />
                    </Route>
                    {/* Settings */}
                        <Route path="/admin-user" exact>
                            <AdminUser
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-role" exact>
                            <AdminRole
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-menu" exact>
                            <AdminMenu
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/email-template" exact>
                            <EmailTemplate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/invoice-template" exact>
                            <InvoiceTemplate
                                title={toggleState} 
                            />
                        </Route>
                    {/* Settings End*/}

                    {/*Scorecard*/}

                        <Route path="/scorecard" exact>
                            <GameScorecard
                                title={toggleState} 
                            />
                        </Route>
                    {/*Scorecard End*/}

                    {/*Academy*/}
                        <Route path="/assessment" exact>
                            <Assessment
                                title={toggleState} 
                            />
                        </Route>
                    {/*Academy End*/}

                    {/* Golf Club */}
                        <Route path="/notice-board" exact>
                            <NoticeBoard
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event" exact>
                            <Event
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event-registration" exact>
                            <EventRegistration
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/holiday-calender" exact>
                            <HolidayCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/tournament-calender" exact>
                            <TournamentCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/hole-in-one" exact>
                            <HoleInOne
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/feedback" exact>
                            <Feedback
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/club" exact>
                            <Club
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/teebox" exact>
                            <TeeBox
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course-closed" exact>
                            <CourseClosed
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course" exact>
                            <Course
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/scorecard-master" exact>
                            <ScorecardMaster
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/concierge-master" exact>
                            <ConciergeMaster
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/affiliate-club" exact>
                            <AffiliateClub 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/admin-emailer" exact>
                            <Emailer
                                title={toggleState} 
                            />
                        </Route>
                    {/* Golf Club End*/}

                    {/* Advertisement */}
                        <Route path="/advertisement-master" exact>
                            <AdvertisementMaster
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/advertisement-post" exact>
                            <AdvertisementPost
                                title={toggleState} 
                            />
                        </Route>
                    {/* Advertisement End */}
            
                    {/* Tee Time */}
                        <Route path="/tee-sheet">
                            <BookingSheet 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/tee-cancel-sheet">
                            <CancellationSheet 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/tee-time-blocking">
                            <TeeTimeBlocking 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-block">
                            <ShotgunBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-calendar">
                            <ShotgunCalender 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-master">
                            <ShotgunMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-template">
                            <ShotgunTemplate 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-block">
                            <StaggeredBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-master">
                            <StaggeredMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-template">
                            <StaggeredTemplate 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/teetime-publish">
                               <TeeTimePublish title={toggleState}/>
                        </Route>
                        <Route path="/no-show-report">
                               <TeeTimeNoShowReport title={toggleState}/>
                        </Route>
                    {/* Tee Time End */}

                    {/* Sports */}
                        <Route path="/sports-booking">
                               <SportsBookingSheet title={toggleState}/>
                        </Route>
                        <Route path="/sport-cancel-sheet">
                               <SportsCancellationSheet title={toggleState}/>
                        </Route>
                        <Route path="/sports-blocking">
                               <SportsBlocking title={toggleState}/>
                        </Route>
                        <Route path="/sports-master">
                               <SportsMaster title={toggleState}/>
                        </Route>
                        <Route path="/facility-master">
                               <FacilityMaster title={toggleState}/>
                        </Route>
                        <Route path="/time-master">
                               <TimeMaster title={toggleState}/>
                        </Route>
                        <Route path="/sports-template">
                               <SportsTemplate title={toggleState}/>
                        </Route>
                        <Route path="/sports-publish">
                               <SportsPublish title={toggleState}/>
                        </Route>
                        <Route path="/sports-report">
                               <SportsReport title={toggleState}/>
                        </Route>
                        <Route path="/markers">
                               <Markers title={toggleState}/>
                        </Route>
                    {/* Sports End */}

                    {/* F&B */}
                    <Route path="/menu-category" exact>
                        <MenuCategory
                            title={toggleState} 
                        />
                    </Route>
                    <Route path="/menu-subcategory" exact>
                        <MenuSubcategory
                            title={toggleState} 
                        />
                    </Route>
                    <Route path="/menu-item" exact>
                        <MenuItem
                            title={toggleState} 
                        />
                    </Route>
                    <Route path="/taxType-master" exact>
                        <TaxType
                            title={toggleState} 
                        />
                    </Route>
                    <Route path="/tax-structure" exact>
                        <TaxStructure
                            title={toggleState} 
                        />
                    </Route>
                    <Route path="/rate-master" exact>
                        <RateMaster
                            title={toggleState} 
                        />
                    </Route>
                    {/* F&B */}
                    {/* Payment */}
                        <Route path="/on-account-payment">
                            <OnAccountPayment title={toggleState}/>
                        </Route>
                        <Route path="/monthly-payment">
                            <MonthlyPayment title={toggleState}/>
                        </Route>
                        <Route path="/subscription-payment">
                            <SubscriptionPayment title={toggleState}/>
                        </Route>
                        <Route path="/subscription-master">
                            <SubscriptionMaster title={toggleState}/>
                        </Route>
                        <Route path="/subscription-charges">
                            <SubscriptionCharges title={toggleState}/>
                        </Route>
                        
                    {/* Payment */}
                    {/* CheckIn */}
                    <Route path="/checkIn-facility-master">
                        <CheckInFacilityMaster title={toggleState}/>
                    </Route>
                    <Route path="/checkIn-report">
                        <CheckInReport title={toggleState}/>
                    </Route>
                        
                    {/* CheckIn End*/}

                    {/* App Control */}
                        <Route path="/android-update" exact>
                            <AndroidUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/android-service" exact>
                            <AndroidService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-update" exact>
                            <IOSUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-service" exact>
                            <IOSService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-update" exact>
                            <AdminUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-service" exact>
                            <AdminService
                                title={toggleState} 
                            />
                        </Route>
                    {/* App Control End*/}

                    {/* Users */}
                        <Route path="/user" exact>
                            <Users
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/guest" exact>
                            <Guest
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/onboarding" exact>
                            <Onboarding
                                title={toggleState} 
                            />
                        </Route>
                    {/* Users End*/}

                    {/* Default */}
                        <Redirect to={'/' + menuID} />
                    
                    </Switch>
                </div>}
            </div>
            
        </div>
    );
};

export default withRouter(Admin);