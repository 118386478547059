import { useState } from "react";
import { useEffect } from "react";
import Dropzone from "../global/dropzone";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { uploadPublicFile } from "../api/fileAPI";
import { addAdminEmailAttachment } from "../api/golfClubAPI";

const AddAttachment = (props) => {

    const [attachmentDetails,                 setAttachmentDetails]              = useState({});
    const [attachment,                        setAttachment]                     = useState(null);

    useEffect(() => {
        $('#add-attachment-modal').modal({
            backdrop: 'static',
        });
        $('#add-attachment-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);   
        });
    })

    const attachmentHandler = async (d) => {
        setAttachment(d);
    };  

    const removeAttachmentHandler = async(e) => {
		e.preventDefault();	
        setAttachment(null)
	}

    const updateAttachmentDetails = (field, value) => { 
        setAttachmentDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addAttachmentHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');

        var form = $('#add-attachment-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                }               
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
            }
        });
        if(!attachment){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : `Please Select attachment`,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        
        if (!form.valid()) return;
        helper.StartProcessing(btn);
        try {
            let attachmentName = '';

            //Call upload API -->
            if(attachment){
                const res = await uploadPublicFile(attachment,'Attachment');
                attachmentName = res.fileName;            
            }

            await addAdminEmailAttachment({...attachmentDetails, FileName: attachmentName})
            helper.StopProcessing(btn);

            $('#add-attachment-modal').modal('hide');
            props.setRefetchAttachment(true);   

            swal.fire({
                icon: 'success',
                titleText: `attachment added successfully!`,
                showConfirmButton: false,
                timer: 1500,
            });
   
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setAttachment(null);
        setAttachmentDetails({});

        form.clearForm();
        form.validate().resetForm();

    }

    const closeDrawer = () =>{
        setAttachmentDetails({});
        setAttachment(null)
    }

    return(
        <>
            <div className="modal fade"id="add-attachment-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="attachment">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Attachment</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-attachment-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                             <input
                                                id="txtName"
                                                value={attachmentDetails.Name || ''}
                                                name="txtName"
                                                placeholder="Name"
                                                className="form-control margin-b10"
                                                type="text"
                                                onChange={(event) => updateAttachmentDetails('Name', event.target.value)}
                                            />   
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <textarea 
                                                id="txtDescription" 
                                                name="txtDescription" 
                                                className="form-control" 
                                                rows="3"
                                                placeholder="Description"
                                                value={attachmentDetails.Description || ''}
                                                onChange={(event) => updateAttachmentDetails('Description', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            {attachment  ? (
                                                <div className="kt-notification  margin-b0" style={{backgroundColor: '#f7f8fa' }} > 
                                                    <a className="kt-notification__item " >
                                                        <div className="kt-notification__item-icon margin-r10" >
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed" >
                                                                <img src={helper.getFileLogo(attachment.name || attachment)} alt="user-profile"  height="30px" width="30px"/>
                                                            </div>
                                                        </div>
                                                        <div className="kt-notification__item-details"  >
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {attachmentDetails.Name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"
                                                            style={{width: "40px", height:"40px"}}                    
                                                            onClick={removeAttachmentHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            ):(
                                                <Dropzone fileHandler={attachmentHandler} />
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addAttachmentHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAttachment;