import moment from 'moment';
import { getServerTs } from '../../components/api/adminAPI';
import swal from "sweetalert2";

let serverTs;

setTimeout(async () => { 
    try {
        serverTs  = await getServerTs();  
    } catch (error) {
        swal.fire({
            icon              : "error",
            title             : "Something went wrong..!",
            text              : 'Please Reload...',
            buttonsStyling    : false,
            confirmButtonClass: "btn btn-brand",
          });
    }
},100);


const currentMonthStart     = moment(serverTs).startOf('month').format('YYYY-MM-DD');
const currentMonthEnd       = moment(serverTs).endOf('month').format('YYYY-MM-DD');
const prevMonthStart        = moment(serverTs).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
const prevMonthEnd          = moment(serverTs).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
const nextMonthStart        = moment(serverTs).add(1, 'month').startOf('month').format('YYYY-MM-DD');
const nextMonthEnd          = moment(serverTs).add(1, 'month').endOf('month').format('YYYY-MM-DD');
const prevThreeStart        = moment(serverTs).subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
const nextThreeEnd          = moment(serverTs).add(3, 'months').endOf('month').format('YYYY-MM-DD');

const currentYearStart      = moment(serverTs).startOf('year').format('YYYY-MM-DD');
const currentYearEnd        = moment(serverTs).endOf('year').format('YYYY-MM-DD');
const prevYearStart         = moment(serverTs).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
const prevYearEnd           = moment(serverTs).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
const nextYearStart         = moment(serverTs).add(1, 'year').startOf('year').format('YYYY-MM-DD');
const nextYearEnd           = moment(serverTs).add(1, 'year').endOf('year').format('YYYY-MM-DD');
const prevThreeYearStart    = moment(serverTs).subtract(3, 'years').startOf('year').format('YYYY-MM-DD');
const nextThreeYearEnd      = moment(serverTs).add(3, 'years').endOf('year').format('YYYY-MM-DD');

const prevSixMonthStart     = moment(serverTs).subtract(6, 'months').startOf('month').format('YYYY-MM-DD');

const today = moment(serverTs).format('YYYY-MM-DD')

// Past 7 days
const past7DayStart = moment(serverTs).subtract(6, 'days').format('YYYY-MM-DD');

// Past 15 days
const past15DayStart = moment(serverTs).subtract(14, 'days').format('YYYY-MM-DD');

// Past 30 days
const past30DayStart = moment(serverTs).subtract(29, 'days').format('YYYY-MM-DD');

export const scorecardPeriods = [
    {
        id: 13,
        name: 'Today',
        from: today,
        to: today
    },
    {
        id: 14,
        name: 'Past 7 days',
        from: past7DayStart,
        to: today
    },
    {
        id: 15,
        name: 'Past 15 days',
        from: past15DayStart,
        to: today
    },
    {
        id: 16,
        name: 'Past 30 days',
        from: past30DayStart,
        to: today
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    },
]

export const dashboardPeriods = [
    {
        id: 1,
        name: 'Current Month',
        from: currentMonthStart,
        to: currentMonthEnd
    },  
    {
        id: 3,
        name: 'Previous Month',
        from: prevMonthStart,
        to: prevMonthEnd
    },
    {
        id : 5,
        name : 'Previous 3 Months',
        from : prevThreeStart,
        to : prevMonthEnd
    },
    {
        id: 7,
        name: 'Current Year',
        from: currentYearStart,
        to: currentYearEnd
    },
    {
        id: 9,
        name: 'Previous Year',
        from: prevYearStart,
        to: prevYearEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }
]

export const expiredPeriods = [
    {
        id: 1,
        name: 'Current Month',
        from: currentMonthStart,
        to: currentMonthEnd
    },  
    {
        id : 5,
        name : 'Previous 3 Months',
        from : prevThreeStart,
        to : prevMonthEnd
    },
    {
        id : 12,
        name : 'Previous 6 Months',
        from : prevSixMonthStart,
        to : prevMonthEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }
]

export const periods = [
    {
        id: 1,
        name: 'Current Month',
        from: currentMonthStart,
        to: currentMonthEnd
    },
    {
        id: 2,
        name: 'Next Month',
        from: nextMonthStart,
        to: nextMonthEnd
    },
    {
        id: 3,
        name: 'Previous Month',
        from: prevMonthStart,
        to: prevMonthEnd
    },
    {
        id: 4,
        name: 'Next 3 Months',
        from: nextMonthStart,
        to: nextThreeEnd
    },
    {
        id: 5,
        name: 'Previous 3 Months',
        from: prevThreeStart,
        to: prevMonthEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }
]

export const prevPeriods = [
    {
        id: 1,
        name: 'Current Month',
        from: currentMonthStart,
        to: currentMonthEnd
    },
    {
        id: 3,
        name: 'Previous Month',
        from: prevMonthStart,
        to: prevMonthEnd
    },
    {
        id: 5,
        name: 'Previous 3 Months',
        from: prevThreeStart,
        to: prevMonthEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }

]

export const yearPeriods = [
    {
        id: 7,
        name: 'Current Year',
        from: currentYearStart,
        to: currentYearEnd
    },
    {
        id: 8,
        name: 'Next Year',
        from: nextYearStart,
        to: nextYearEnd
    },
    {
        id: 9,
        name: 'Previous Year',
        from: prevYearStart,
        to: prevYearEnd
    },
    {
        id: 10,
        name: 'Next 3 Years',
        from: nextYearStart,
        to: nextThreeYearEnd
    },
    {
        id: 11,
        name: 'Previous 3 Years',
        from: prevThreeYearStart,
        to: prevYearEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }
]

export const prevYearPeriods = [
    {
        id: 7,
        name: 'Current Year',
        from: currentYearStart,
        to: currentYearEnd
    },
    {
        id: 9,
        name: 'Previous Year',
        from: prevYearStart,
        to: prevYearEnd
    },
    {
        id: 5,
        name: 'Previous 3 Years',
        from: prevThreeYearStart,
        to: prevYearEnd
    },
    {
        id: 6,
        name: 'Custom',
        from: null,
        to: null
    }
]