import { useState, useEffect } from "react";
import Select from 'react-select';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getActiveSportsMaster, getViewSportTimeMasterAll, postSportTimeMaster } from "../api/sportsAPI";
import RightSideDetails from "../global/rightSideDetails";

const TimeMaster = (props) => {

    const [timeMasterDetails,   setTimeMasterDetails]       = useState({});
    const [type,                setType]                    = useState({'SportID':  0});
    const [timeMaster,          setTimeMaster]              = useState([]);
    const [typeOption,          setTypeOption]              = useState([{value: 'Weekday', label: 'Weekday'}, {value: 'Weekend', label: 'Weekend'}]);
    const [sports,              setSports]                  = useState([]);
    const [rightSideDetails,    setRightSideDetails]        = useState(false);
    const [isInit,              setIsInit]                  = useState(true);

    useEffect(() => {
        props.title('Time Master', 'time-master')
        fetchSports();
        fetchSportTimeMaster();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            $('#txtStartDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtStartDate').on('change', function (event) {
                updateTimeMasterDetails('StartDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtEndDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtEndDate').on('change', function (event) {
                updateTimeMasterDetails('EndDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
           
        }else{
            setTimeMasterDetails({});
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable(type);        
        }
    }, [timeMaster]);

    useEffect(() => {
        if(type && !isInit){
            $('#tblTime').KTDatatable().destroy(); 
            fillDataTable(type);
        }
    },[type])

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const  fetchSportTimeMaster = async() => {
        try {
            const response = await getViewSportTimeMasterAll();
            isInit ? setIsInit(false) : $('#tblTime').KTDatatable().destroy(); 
            setTimeMaster(response);
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const updateTimeMasterDetails = (field, value) => {
        setTimeMasterDetails((d) => {
            return {...d, [field]: value};
        })
    }

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            setSports([{ID: 0, Name: 'All'}, ...response])

            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    }

    function fillDataTable(t) {
        var TimeMasterList = [];
        if (t.SportID == 0 ) {
            TimeMasterList = timeMaster;
        }else{
            TimeMasterList = timeMaster.filter(i => i.SportID == t.SportID);
        }
        const dataTable = $('#tblTime').KTDatatable({
            data: {
                saveState: false,
                source: TimeMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.StartDate).format('YYYY-MM-DD')).isSame(moment(row.EndDate).format('YYYY-MM-DD')) ? moment(row.StartDate).format('DD MMM YYYY') : moment(row.StartDate).format('DD MMM YYYY') + ' - ' + moment(row.EndDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Type',
                    template: function (row) {
                        if (row.Type == 'Weekend') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr30 padding-tb10">Weekend</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--info kt-badge--pill padding-lr30 padding-tb10"> Weekday </span>'
                        }
                    },
                    width: 180
                },{
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTime .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTimeMasterDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true); 
            }    
        });
    }

    const addTimeMasterHandler = async() => {
        const btn = $('#btnSave');
        var form = $('#add-time-master');

        form.validate({
            rules: {
                ddlSport: {
                    required: true
                },
                ddlType: {
                    required: true
                },
                ddlMaximumBooking: {
                    required: true
                },          
                txtStartDate: {
                    required: true
                },          
                txtEndDate: {
                    required: true
                }          
            },
            messages: {
                ddlSport: {
                    required: "Please select sport"
                },
                ddlType: {
                    required: "Please select type"
                },
                txtStartDate: {
                    required: "Please select StartDate"
                },
                txtEndDate: {
                    required: "Please select EndDate"
                }
            }
        });

        if (!form.valid()) return;
        if((moment(timeMasterDetails.StartDate).isAfter(timeMasterDetails.EndDate)) || timeMaster.find((d) => d.ID != timeMasterDetails.ID && d.SportID == timeMasterDetails.SportID && d.Type == timeMasterDetails.Type && (moment(timeMasterDetails.StartDate) < moment(d.StartDate) && moment(timeMasterDetails.EndDate) > moment(d.EndDate)  || moment(timeMasterDetails.StartDate).isBetween(d.StartDate, d.EndDate) || moment(timeMasterDetails.EndDate).isBetween(d.StartDate, d.EndDate) || moment(timeMasterDetails.StartDate).isSame(d.StartDate) || moment(d.StartDate).isSame(timeMasterDetails.EndDate) || moment(timeMasterDetails.EndDate).isSame(d.EndDate) || moment(d.EndDate ).isSame(timeMasterDetails.StartDate)))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        }
        
        helper.StartProcessing(btn);
        try {
            await postSportTimeMaster(timeMasterDetails);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchSportTimeMaster();
            if (timeMasterDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Time Master updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Time Master added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0 padding-tb20">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0" style={{display: 'flex'}}>
                                        <div className="col-form-label col-1"></div>
                                        <label className="col-form-label col-2"> Sport </label>
                                        <div className="kt-input-icon padding-r10 margin-lr20 col-7">
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || {label: 'All', value: type.SportID}}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                        <div className="col-form-label col-1"></div>
                                        <div className="kt-input-icon kt-input-icon--left col-7">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Master</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTime" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && (<RightSideDetails title="Time Master" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form id="add-time-master" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Sports <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-4">
                                                    <Select 
                                                        id="ddlSport" 
                                                        name="ddlSport"
                                                        className="multi-custom"
                                                        placeholder="Select Sports"
                                                        value={(sports && sports.length > 0 && timeMasterDetails.SportID) && {label: sports.find((m) => m.ID == timeMasterDetails.SportID).Name ,value: timeMasterDetails.SportID}}
                                                        onChange={(event) => updateTimeMasterDetails('SportID', event.value)}
                                                        options={sports.slice(1).map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Type </label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <Select 
                                                        id="ddlType" 
                                                        name="ddlType"
                                                        className="multi-custom "
                                                        placeholder="Select Type"
                                                        value={timeMasterDetails && typeOption.find((f) => f.value == timeMasterDetails.Type) || ''}
                                                        onChange={(event) => updateTimeMasterDetails('Type', event.value)}
                                                        options={typeOption}
                                                    />
                                                </div>                                      
                                            </div>                                      
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Start Date <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="input-group date">
                                                        <input
                                                            id="txtStartDate"
                                                            name="txtStartDate"
                                                            type="text"
                                                            className="form-control date-input" 
                                                            readOnly={true}
                                                            placeholder="Select start date"
                                                            value={timeMasterDetails.StartDate ? moment(timeMasterDetails.StartDate).format('DD-MM-YYYY') : ''} 
                                                            />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> End Date <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="input-group date">
                                                        <input
                                                            id="txtEndDate"
                                                            name="txtEndDate"
                                                            type="text"
                                                            className="form-control date-input" 
                                                            readOnly={true}
                                                            placeholder="Select end date"
                                                            value={timeMasterDetails.EndDate ? moment(timeMasterDetails.EndDate).format('DD-MM-YYYY') : ''} 
                                                            />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addTimeMasterHandler}> Save </button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}> Cancel </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>           
            </div>
        </>
    )
}

export default TimeMaster;