import subscription_expired from '../../assets/images/subscription_expired.png'

const UserSubscription = (props) =>{

    const {subscriptionValidity, setSubscriptionDetails, setShowSubModal} = props

    function calculateProgress(startDate, endDate) {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Calculate the difference between start and end dates in milliseconds
        const totalDuration = end - start;
        
        // Calculate the progress duration in milliseconds
        const progressDuration = currentDate - start;
        
        // Calculate the progress percentage
        const progressPercentage = (progressDuration / totalDuration) * 100;
        
        // Ensure progress percentage is within 0-100 range
        return `${Math.min(100, Math.max(0, progressPercentage))}%`;
    }
    
    return(
        <>
            <div className="padding-lr10 padding-tb30">
                <div>
                    {subscriptionValidity.length > 0 ? subscriptionValidity.map((subscription, index) => (
                        <div className="kt-portlet" key={index} onClick={() => {setSubscriptionDetails(subscription) ,setShowSubModal(true)}}  style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 25%)'}} onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 20px 0px rgb(82 63 105 / 40%)'} onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 13px 0px rgb(82 63 105 / 25%)'}>
                            <div className="kt-portlet__body">
                                <div className="kt-section kt-section--space-md" style={{marginBottom: '2px'}}>
                                    <div className="kt-widget24 kt-widget24--solid">
                                        <div className="kt-widget24__details">
                                            <div className="kt-widget24__info">
                                                <a href="#" className="kt-widget24__title" title="Click to edit">
                                                    {subscription.SubscriptionName}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span className={`kt-badge kt-badge--${subscription.Status == "Active" ? 'success' : 'brand'}  kt-badge--inline kt-badge--pill padding-lr10`}>
                                                        {subscription.Status}
                                                    </span>
                                                </a>
                                                <span className="kt-widget24__desc">
                                                    {subscription.Code}
                                                </span><br/>
                                                <span className="kt-widget24__desc margin-tb5 padding-r100">
                                                    {subscription.Description}
                                                </span>
                                            </div>
                                            <span className={`kt-widget24__stats kt-font-${subscription.Status == "Active" ? 'success' : 'brand'} padding-l100`}>
                                                ₹{subscription.Total}
                                            </span>
                                        </div>
                                        <div className="progress progress--sm">
                                            <div className={`progress-bar kt-bg-${subscription.Status == "Active" ? 'success' : 'brand'}`} role="progressbar" style={{ width: calculateProgress(subscription.SubscriptionStartsFrom, subscription.SubscriptionExpiresOn) }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div className="kt-widget24__action">
                                            <span className="kt-widget24__change">
                                                {moment(subscription.SubscriptionStartsFrom).format('DD-MMM-YYYY')}
                                            </span>
                                            <span className="kt-widget24__number">
                                                {moment(subscription.SubscriptionExpiresOn).format('DD-MMM-YYYY')}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    )): (
                        <div style={{textAlign: 'center'}} className="padding-t20">
                            <img src={subscription_expired} alt="subscription-expired" height="255" width="350" style={{opacity:'0.6', color: 'grey'}}></img>
                            <h5>No Subscription</h5>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
} 

export default UserSubscription;


