import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import { deleteCoachTimeBlock, getCoachBook, getCoachMasterAll, getSportTaggingByCoachID, postCoachTimeBlock } from '../api/coachAPI';
import GetBookingFilter from './GetBookingFilter';

const CoachBlock = (props) => {

    const [selectedDate,            setSelectedDate]                = useState(moment().toDate());
    const [rightSideDetails,        setRightSideDetails]            = useState(false);
    const [coachList,               setCoachList]                   = useState([]);
    const [sportList,               setSportList]                   = useState([]);
    const [selectedCoach,           setSelectedCoach]               = useState(null);
    const [selectedSport,           setSelectedSport]               = useState(null);
    const [status,                  setStatus]                      = useState({});
    const [serverTime,              setserverTime]                  = useState('')
    const [bookList,                setBookList]                    = useState([]);
    const [slot,                    setSlot]                        = useState([]);
    const [avaiableSlot,            setAvailableSlot]               = useState([]);

    useEffect(() => {
        props.title("Block Booking", "coach-block");
    }, []);

    useEffect(() => {
        if (selectedCoach && selectedDate && selectedSport) {
            fetchCoachBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedCoach.ID, selectedSport.SportID)
        }

        return () => setSlot([])
    }, [selectedCoach, selectedDate, selectedSport])

    useEffect(() => {
        if (selectedCoach) {
            setSelectedSport(null)
        }
    }, [selectedCoach])

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setStatus({})
            setSlot([])
        }
    }, [rightSideDetails]);

    const updateAllSlotDetails = (checked) => {
        const values = [...avaiableSlot]
        if (checked) {
            setSlot([...values])
        } else {
            setSlot([])
        }
    }

    const updateSlotDetails = (value, checked) => {   
        if(checked){
            setSlot(p =>  [...p, value])
        }else{
            setSlot((a) => {
                const nA = a.filter((b, i) => b !== value);
                return nA;
            })
        }
    };

    const updateStatusDetails = (field, value) => {   
        setStatus((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchCoachBook = async (selectedDate, dayName, coachID, sportID) => {
        try {
            const response = await getCoachBook({selectedDate, dayName, coachID, sportID});
            setBookList(response.booking);
            const availSlot = response.booking.filter(b => b.ID == 0)
            setAvailableSlot(availSlot)
            setserverTime(response.serverTimestamp);
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)  
        }
    }

    const openDrawer = () =>{
        if(slot.length > 0){
            setRightSideDetails(true);
        }else{
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please select atleast one slot',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return  
        }
    }

    const deleteSlot = async(id) => {   
        try {
            await deleteCoachTimeBlock(id)  
            swal.fire({
                icon: 'success',
                titleText: 'Booking Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            setSlot([])
            fetchCoachBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedCoach.ID, selectedSport.SportID)
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)  
            return;
        }
    };

    const addCoachTimeBlock = async () => {
        const btn = $('#btnSave')
        var form = $('#ModalHolder');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                }        
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                }
            }
        });
        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            await postCoachTimeBlock({Slots:slot, SelectedDate: moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'), Status: status.Status})
            helper.StopProcessing(btn);
            swal.fire({
                icon: 'success',
                titleText: 'Blocking Submitted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            setSlot([]);
            fetchCoachBook(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedCoach.ID, selectedSport.SportID)
            setRightSideDetails(false);
        } catch (error) {
            helper.StopProcessing(btn);
            helper.reuseableSwal('error', 'Error!', error.message)  
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className='row'>
                                    <GetBookingFilter
                                        selectedDate={selectedDate}
                                        selectedCoach={selectedCoach}
                                        selectedSport={selectedSport}
                                        setSelectedDate={setSelectedDate}
                                        setSelectedCoach={setSelectedCoach}
                                        setSelectedSport={setSelectedSport}
                                    />
                                </div>
                            </div>
                            <table id="table-book" className="table table-bordered table-hover margin-b30 ">
                                <thead className="light-grey-background">
                                    <tr>
                                        {/* <th>Session</th> */}
                                        <th style={{ verticalAlign: 'middle' }}>Slot</th>
                                        <th style={{ verticalAlign: 'middle' }}>Status</th>
                                        <th className="text-center"><span className='margin-l20'>Action</span>
                                            {avaiableSlot.length > 0 && (
                                                <label className="kt-checkbox kt-checkbox--brand margin-l10"> 
                                                    <input 
                                                        type="checkbox" 
                                                        className="book-check" 
                                                        data-templateid="162"
                                                        checked={(avaiableSlot && slot && bookList.length > 0 && (avaiableSlot.length != 0 && slot.length != 0)) && avaiableSlot.length == slot.length}
                                                        onChange={(event) => updateAllSlotDetails(event.target.checked)}
                                                    /> &nbsp;    	
                                                    <span></span>    
                                                </label>
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                {bookList && bookList.length > 0 ? bookList.map((m, i) => (
                                    <tbody key={i} style={{borderTop: 'none'}}>
                                        <tr >
                                            <td>{m.StartTime} To {m.EndTime}</td>
                                            <td>
                                                <div className={`badge badge-${m.Status == null ? 'success' : m.Status == 'Booked' ? 'label-danger' : 'danger'}`}>
                                                    <span>{m.Status == null ? 'Available' : m.Status == 'Booked' ? 'User Booking' : m.Status}</span>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                            {m.Status == null ? (
                                                <label className="kt-checkbox kt-checkbox--brand margin-0"> 
                                                    <input 
                                                        type="checkbox" 
                                                        className="book-check" 
                                                        data-templateid="162"
                                                        checked={slot.includes(m)}
                                                        onChange={(event) => updateSlotDetails(m,  event.target.checked)}
                                                    /> &nbsp;    	
                                                    <span></span>    
                                                </label> 
                                            ) : (
                                                <>
                                                    {(moment(serverTime).isBefore(moment(m.BookDate)) && m.Status != 'Booked') && (
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r15" 
                                                            onClick={() => deleteSlot(m.ID)}
                                                        >     
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>  
                                                        </button>        
                                                    )}
                                                </>
                                            )}
                                            </td>
                                        </tr>
                                    </tbody>
                                )) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="3" className='text-center'>
                                                <p className='padding-t20'>No Slot Found</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                {rightSideDetails && <RightSideDetails title="Block Booking" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={slot.map(a => ' '+ a.StartTime + ' To ' + a.EndTime)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={status.Status || ''}
                                                            onChange={(event) => updateStatusDetails('Status', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCoachTimeBlock}>Save Booking</button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>}
                            </div>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-8 ml-lg-auto">
                                        <button id="btnBook" type="button" className="btn btn-brand d-inline-block" onClick={() => openDrawer()}>Block Coach Time</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoachBlock