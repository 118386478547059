import { useState } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import { useEffect } from "react";
import Dropzone from "../global/dropzone";
import { postMemberDocument } from "../api/usersAPI";
import { uploadPrivateFile } from "../api/fileAPI";

const AddDocument  = (props) =>{

    const [documentDetails,                 setDocumentDetails]              = useState({ShowOnApp: 'Y'});
    const [document,                        setDocument]                     = useState(null);
    const [documentTypeOption,              setdocumentTypeOption]           = useState([{ value:  'Andhar Card', label: 'Andhar Card'},  { value:  'Pan Card', label: 'Pan Card'}, { value:  'Agreement', label: 'Agreement'},]);

    useEffect(() => {
        $('#add-documents-modal').modal({
            backdrop: 'static',
        });
        $('#add-documents-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);   
        });
    })

    useEffect(() => {
        if(props.editDocument){
            setDocumentDetails(props.editDocument)
            setDocument(props.editDocument.FileName.slice(props.editDocument.FileName.lastIndexOf('/') + 1))
        }
    },[props.editDocument])

    const documentHandler = async (d) => {
        setDocument(d);
    };  

    const removeDocumentHandler = async(e) => {
		e.preventDefault();	
        setDocument(null)
	}

      
    const updateDocumentDetails = (field, value) => { 
        setDocumentDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addDocumentHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');

        var form = $('#add-document-form');
        
        form.validate({
            rules: {
                ddlDocumentType: {
                    required: true,
                }                
            },
            messages: {
                ddlDocumentType: {
                    required: "Please select DocumentType"
                }
            }
        });
        if(!document){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : `Please Select Document`,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        
        if (!form.valid()) return;
        helper.StartProcessing(btn);
        try {
            let userPhoto = '';

            //Call upload API -->
            if(document){
                const res = await uploadPrivateFile(document, 'Document');
                userPhoto = res.fileName;            
            }else{
                if(documentDetails.FileName) userPhoto = documentDetails.FileName.split('/Document/')[1];
            }

            await postMemberDocument({...documentDetails, FileName: userPhoto, GroupID: props.GroupID})
            helper.StopProcessing(btn);

            $('#add-documents-modal').modal('hide');
            props.refetchDocuments()


            swal.fire({
                icon: 'success',
                titleText: `Document ${documentDetails.ID ? "updated" : "added"} successfully!`,
                showConfirmButton: false,
                timer: 1500,
            });
   
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setDocument(null);
        setDocumentDetails({ShowOnApp: 'Y'});
        props.setEditDocument(null)


        form.clearForm();
        form.validate().resetForm();

    }
    
    const closeDrawer = () =>{
        setDocumentDetails({ShowOnApp: 'Y'});
        setDocument(null)
        props.setEditDocument(null)
    }

    return(
        <>
            <div className="modal fade"id="add-documents-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Documents</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-document-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <Select 
                                                id="ddlDocumentType" 
                                                name="ddlDocumentType"
                                                className="multi-custom"
                                                placeholder="Select Document Type"
                                                value={documentDetails && documentTypeOption.find((m) => m.value == documentDetails.DocumentType )  || ''}
                                                onChange={(event) => updateDocumentDetails('DocumentType', event.value)}
                                                options={documentTypeOption}

                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <textarea 
                                                id="txtRemark" 
                                                name="txtRemark" 
                                                className="form-control" 
                                                rows="3"
                                                placeholder="Remark"
                                                value={documentDetails.Remark || ''}
                                                onChange={(event) => updateDocumentDetails('Remark', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            {document  ? (
                                                <div className="kt-notification  margin-b0" style={{backgroundColor: '#f7f8fa' }} > 
                                                    <a className="kt-notification__item " >
                                                        <div className="kt-notification__item-icon margin-r10" >
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed" >
                                                                <img src={helper.getFileLogo(document.name || document)} alt="user-profile"  height="30px" width="30px"/>
                                                            </div>
                                                        </div>
                                                        <div className="kt-notification__item-details"  >
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {documentDetails.DocumentType}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"
                                                            style={{width: "40px", height:"40px"}}                    
                                                            onClick={removeDocumentHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            ):(
                                                <Dropzone fileHandler={documentHandler} />
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-6">Show On App</label>
                                        <div className="col-xl-6 col-lg-6">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input
                                                        id="chkActive"
                                                        name="chkActive"
                                                        type="checkbox"
                                                        defaultChecked={documentDetails.ShowOnApp == 'N' ? '' : 'checked'}
                                                        onChange={(event) => updateDocumentDetails('ShowOnApp', event.target.checked ? 'Y' : 'N')} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addDocumentHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                {props.editDocument.FileName ? 'Update' : 'Attach'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddDocument