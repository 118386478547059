import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getFnbMenuCategory, getFnbMenuItemByCategoryID, getFnbMenuSubCategoryByCategoryID, postFnbMenuItem } from "../api/fnbAPI";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import ImageCropper from "../global/imageCropper";
import defaultItemImg from '../../assets/images/item.png'
import Select from 'react-select';
import { uploadPublicFile } from "../api/fileAPI";

const MenuItem = (props) => {

    const [menuCategory,           setMenuCategory]            = useState({});
    const [menuCategoryList,       setMenuCategoryList]        = useState([]);
    const [isInit,                 setIsInit]                  = useState(true);
    const [rightSideDetails,       setRightSideDetails]        = useState(false);
    const [allSubcategories,       setAllSubcategories]        = useState([]);
    const [allItems,               setAllItems]                = useState([]);
    const [itemImageBlob,          setItemImageBlob]           = useState(null);
    const [cropperData,            setCropperData]             = useState(null);
    const [isEdit,                 setIsEdit]                  = useState(false);
    const [foodType,               setFoodType]                = useState([{value: "Veg", label: "Veg"},{value: "Jain", label: "Jain"}, {value: "Non-Veg", label: "Non-Veg"}])
    const [itemDetails,            setItemDetails]             = useState({Active: "Y"});
    const [isEditable,             setIsEditable]              = useState();

    useEffect(() => {
        props.title("Menu Item", "menu-item");
        fetchMenuCategory();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setMenuCategory({}), setItemImageBlob(null), setIsEdit(false), setIsEditable(), setItemDetails({});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [menuCategoryList]);

    useEffect(() => {       
        if (allItems) AllowSortable();
    }, [allItems]);

    const fetchMenuCategory = async() => {
        try {
            const master = await getFnbMenuCategory();
            isInit ? setIsInit(false) : $('#tblMenuCategory').KTDatatable().destroy(); 
            setMenuCategoryList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchSubcategoryByMenuD = async(id) => {
        try {
            const master = await getFnbMenuSubCategoryByCategoryID(id)
            setAllSubcategories(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    };

    const fetchMenuItemByMenuD = async(id) => {
        try {
            const items = await getFnbMenuItemByCategoryID(id)
            setAllItems(items);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    };

    function fillDataTable() {

        const dataTable = $('#tblMenuCategory').KTDatatable({
            data: {
                saveState: false,
                source: menuCategoryList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Image && row.Image != '') ? `<img src="${row.Image}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblMenuCategory .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setMenuCategory(dataTable.dataSet[index]);  
                fetchSubcategoryByMenuD(dataTable.dataSet[index].ID);
                fetchMenuItemByMenuD(dataTable.dataSet[index].ID);
                setRightSideDetails(true);           
            } 
        });

    };

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setItemImageBlob(val),
            header : 'Upload Profile Photo'
        })
    };

    const updateMarkerDetails = (field, value) => {   
        setItemDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addMarkerHandler = async(e) => {
        e.preventDefault()

        var form = $('#add-item-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true,
                },  
                ddlType: {
                    required: true,
                }                         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                ddlType: {
                    required: "Please select type"
                }
            }
        });
        if (!form.valid()) return;

        const btn = $('#btnAdd');

        helper.StartProcessing(btn);

        try {
            let itemImage = '';
            //Call upload API -->
            if(itemImageBlob){
                const res = await uploadPublicFile(itemImageBlob, 'Content');
                itemImage = res.fileName;  
            }else{
                if(itemDetails.Image) itemImage = itemDetails.Image.split('/Content/')[1];
            }
            if(!isEdit) itemDetails.Index = allItems.length + 1;       

            const item = {
                ID:                    itemDetails.ID || 0,
                CategoryID:            menuCategory.ID,
                SubcategoryID:         isEditable,
                Type:                  itemDetails.Type,
                Code:                  itemDetails.Code,
                Name:                  itemDetails.Name,
                Image:                 itemImage || itemDetails?.Image || '',
                Index:                 itemDetails.Index,
                Active:                itemDetails.Active
            }
            saveBlocks([item]);                  
            helper.StopProcessing(btn);
            setItemImageBlob(null)
            setIsEdit(false)
            fetchMenuItemByMenuD(menuCategory.ID);
            $('.chkDisplay').prop("checked", true );
            $('.chkActive').prop("checked", true );
            setItemDetails({Active: "Y"})
            
            //Clear State ->
            clearAction();
            if (itemDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: `${sports.MarkerDisplayName} updated successfully!`,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: `${sports.MarkerDisplayName} added successfully!`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            helper.StopProcessing(btn);
            return;
        }
    };

    const saveBlocks = async(itemList) => {
        if(itemList.length <= 0) return;
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            const res = await postFnbMenuItem(itemList);
            setAllItems(res);
                    
            swal.close()
        } catch (err) {
            swal.close()
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    };

    const removePhotoHandler = async(e) => {
		e.preventDefault();
        setItemDetails((d) => {
            return { ...d, Image: '' };
        });
		setItemImageBlob(null);
	};

    const clickHandler = (id) => {
        if(id == isEditable){
            setIsEditable()    
        }else{
            setIsEditable(id);
            setItemDetails({Active: "Y"});
            setIsEdit(false);
        }
    };

    const editHandler = (id) => {
        setIsEdit(true);
        const d = allItems.find(a => a.ID == id);
        setItemDetails(d)
    };

    const onCheck = async (value) => {
        saveBlocks([value]);                        

    };

    //Sorting -->
    const AllowSortable =() =>{

        const config = (event, ui) =>{
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    };

    
    const sortAndSave = (param)=> {
        if(param.length <= 0) return;      
        let itemList = [];        

        setAllItems( d => {
            const sortedItems = [];
            
            for (const [i,id] of Object.entries(param)) {
                let f = d.find( a => a.ID == id);
                if(!!f){
                    f.Index = parseInt(i)+1;                    
                    sortedItems.push(f);
                }
            };

            itemList = sortedItems;
            return d;
        });
                    
        if(itemList.length > 0) saveBlocks(itemList);                              
    };

    const clearAction = () => {       
        setItemDetails({Active: "Y"});
        setIsEdit(false);
    }; 

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Menu..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMenuCategory" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Menu Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        {menuCategory.Image && menuCategory.Image != '' ? 
                                                            <img className="kt-widget__pic kt-widget__pic" src={menuCategory.Image} alt="user-profile"/> :
                                                            <div
                                                                className={`kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-`}>
                                                                {menuCategory.Name.slice(0, 1)}
                                                            </div>
                                                        }   
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {menuCategory.Name}{' '}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={`kt-badge kt-badge--${menuCategory.Active == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                        {menuCategory.Active == 'Y' ? 'Active' : 'Inactive'}
                                                                    </span> 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body">
                                        {!!isEditable && (
                                            <form className="kt-form" id="add-item-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-5" role="form">
                                                    <div className="row padding-t10">
                                                        <div className="col-2 form-group">
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed  offset-2" id="kt_user_add_avatar">
                                                                <div className="kt-avatar__holder" 
                                                                    style={{ backgroundImage : `url( ${itemImageBlob ? URL.createObjectURL(itemImageBlob): itemDetails.Image ? itemDetails.Image : defaultItemImg})` ,width: "105px", height:"105px" } }>    
                                                                </div>
                                                                <label 
                                                                    className="kt-avatar__upload" 
                                                                    data-toggle="kt-tooltip"  
                                                                    data-original-title="Change avatar"
                                                                    onClick ={openCropper}
                                                                >
                                                                    <i className="fa fa-pen"></i>
                                                                </label>
                                                                {(!!itemImageBlob || itemDetails?.Image) && (
                                                                    <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                        <i className="fa fa-times"></i>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-3 form-group padding-t20">
                                                            <input 
                                                                id="txtName" 
                                                                name="txtName" 
                                                                type="text" 
                                                                className="form-control"
                                                                placeholder="Name"
                                                                value={itemDetails.Name || ''}
                                                                onChange={(event) => updateMarkerDetails('Name',  helper.camelCase(event.target.value))} 
                                                            />
                                                        </div>
                                                        <div className="col-1 form-group padding-t20">
                                                            <input 
                                                                id="txtCode" 
                                                                name="txtCode" 
                                                                type="text" 
                                                                placeholder="Code"
                                                                className="form-control" 
                                                                value={itemDetails.Code || ''}
                                                                onChange={(event) => updateMarkerDetails('Code', event.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-3 form-group padding-t20">
                                                            <div className="input-group ddlCoursePnl">
                                                                <Select 
                                                                    id="ddlType" 
                                                                    name="ddlType"
                                                                    className="multi-custom width-95p"
                                                                    placeholder="Select Type"
                                                                    value={ itemDetails.Type && {label: foodType.find((m) => m.label == itemDetails.Type ).label ,value: itemDetails.Type } || ''}
                                                                    onChange={(event) => updateMarkerDetails('Type', event.value)}
                                                                    options={foodType}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={`col-3 padding-l20 padding-t20`}>
                                                            {(!isEdit) ? (
                                                                <button id="btnAdd" type="button" className={`btn btn-label-brand btn-block`}onClick={addMarkerHandler} ><i className="la la-plus"></i>Add</button>
                                                                ):(
                                                                <div className="btn-group height-38">
                                                                    <button id="btnAdd" type="button" className={`btn btn-label-success d-inline-block margin-0`} onClick={addMarkerHandler} > Update </button>
                                                                    <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                                </div>
                                                            )} 
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                            {(allSubcategories && allSubcategories.length > 0) && (
                                                allSubcategories.map((m, i) => (
                                                    <div className="card" key={i}>
                                                        <div className="card-header" id="menuHeading">
                                                            <div className="card-title collapsed" data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Name)}`} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Name)} onClick={() => clickHandler(m.ID)}>
                                                                {/* <span dangerouslySetInnerHTML={{__html: m.Icon}}/> */}
                                                                <span className="margin-l10">{m.Name}</span>
                                                            </div>
                                                        </div>
                                                        <div id={helper.SanitizeUrl(m.Name)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                            <div className="card-body">
                                                                <div className="kt-widget4">
                                                                <table id="table-block" className="table table-bordered table-hover margin-b10">
                                                                    <tbody>
                                                                        {(allItems && allItems.length > 0) && allItems.filter(f => f.SubcategoryID == m.ID).length > 0 ? allItems.filter(f => f.SubcategoryID == m.ID).map((s, i) => (
                                                                            <tr className="dragableRow" key={i} data-id={s.ID}>
                                                                                <td width="60%"className="padding-l20 kt-datatable__cell text-clip">
                                                                                    <div className="kt-user-card-v2">
                                                                                        <div className="kt-user-card-v2__pic">
                                                                                            {s.Image && s.Image != '' ? 
                                                                                            <img src={s.Image} alt="user-profile" /> : 
                                                                                            <img src={defaultItemImg} alt="user-profile" />}
                                                                                        </div>
                                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                                            <span className="kt-user-card-v2__name text-clip"> {s.Name} </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td width="20%"> <span className="kt-user-card-v2__name text-clip"> {s.Code} </span></td>
                                                                                <td width="20%"><span className={`btn btn-outline-${s?.Type == 'Veg' || s?.Type == 'Jain' ? 'success' : 'danger'} btn-elevate btn-square btn-icon btn-xs`} style={{height: '20px', width: '20px', pointerEvents: 'none'}}><i className="fa fa-circle"></i></span> &nbsp;{s.Type}</td>
                                                                                <td className="text-center padding-b0" width="10%">
                                                                                    <span className="kt-switch kt-switch--md kt-switch--icon margin-lr10">
                                                                                        <label>
                                                                                            <input 
                                                                                                id="chkActive" 
                                                                                                name="chkActive" 
                                                                                                type="checkbox" 
                                                                                                defaultChecked={s.Active == 'N' ? '' : 'checked'}
                                                                                                onChange={(event) => onCheck({...s, Active: event.target.checked ? 'Y' : 'N'})} 
                                                                                            />
                                                                                            <span></span>
                                                                                        </label>
                                                                                    </span>
                                                                                </td>
                                                                                <td className="text-center" width="10%">
                                                                                    <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(s.ID)}><i className="flaticon2-edit"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        )) : (
                                                                            <tr>
                                                                                <td colSpan={3}> 
                                                                                    <div className="padding-10"> 
                                                                                        <h5 className="text-center text-muted"> No Items..! </h5> 
                                                                                    </div>
                                                                                </td>
                                                                        </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
};

export default MenuItem;