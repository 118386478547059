import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { postSubscriptionCharges } from "../api/paymentAPI";

const AddChargesModal = (props) => {

    const {subscriptionChargesList, subscriptionChargesDetails, setSubscriptionChargesDetails, SubscriptionID, refetchCharges} = props;

    const [chargeDetails,               setChargeDetails]            = useState({});    

    useEffect(() => {
        $('#add-charges-modal').modal({
            backdrop: 'static',
        });
        $('#add-charges-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date()
        });
        $('#txtFromDate').on('change', function (event) {
            updateChargeDetails('FromDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date()
        });
        $('#txtToDate').on('change', function (event) {
            updateChargeDetails('ToDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });
        $('#txtSubscriptionExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionExpiresOn').on('change', function (event) {
            updateChargeDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });  
        $('#txtSubscriptionStartsFrom').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionStartsFrom').on('change', function (event) {
            updateChargeDetails('SubscriptionStartsFrom',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });  
    }, [])

    useEffect(() => {
        if (Object.keys(subscriptionChargesDetails).length > 0) {
            if(!moment(moment(subscriptionChargesList.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(subscriptionChargesDetails.FromDate)){
                $('#txtFromDate').attr('disabled', true);
                $('#txtCharge').attr('disabled', true);
                $('#txtGST').attr('disabled', true);
            };
        setChargeDetails({...subscriptionChargesDetails, Charge: subscriptionChargesDetails.Charge.replace(/,/g, ""), GSTAmount: subscriptionChargesDetails.GSTAmount.replace(/,/g, ""), Total: subscriptionChargesDetails.Total.replace(/,/g, "")});
        }
      }, [subscriptionChargesDetails]);
      
    useEffect(() => {
    if (chargeDetails && chargeDetails.Charge && chargeDetails.GST) {
        const { Charge, GST } = chargeDetails;
        
        // Calculate GSTAmount and Total using Math.floor for rounding down
        const GSTAmount = Math.floor((Charge * GST) / 100);
        const Total = GSTAmount + Math.floor(Charge);
        
        // Update only if there's a change to prevent unnecessary re-renders
        if (GSTAmount !== chargeDetails.GSTAmount || Total !== chargeDetails.Total) {
            setChargeDetails(d => ({ ...d, GSTAmount, Total }));
        }
    };
        
    }, [subscriptionChargesDetails, Math.round(parseFloat(chargeDetails.Charge)), Math.round(chargeDetails.GST)]);
    
    const addChargeHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-charges-form');
        
        form.validate({
            rules: {         
                txtFromDate: {
                    required: true
                },          
                txtToDate: {
                    required: true
                },
                txtSubscriptionStartsFrom: {
                    required: true,
                },         
                txtSubscriptionExpiresOn: {
                    required: true,
                },         
                txtCharge: {
                    required: true
                },          
                txtGST: {
                    required: true
                }          
            },
            messages: {
                txtFromDate: {
                    required: "Please select from Date"
                },
                txtToDate: {
                    required: "Please select to Date"
                },
                txtSubscriptionStartsFrom: {
                    required: "Please select subscription starts from"
                },
                txtSubscriptionExpiresOn: {
                    required: "Please select subscription expires On"
                },
                txtCharge: {
                    required: "Please enter charge"
                },
                txtGST: {
                    required: "Please enter gst"
                }
            }
        });
        
        if (!form.valid()) return;
        
        if((moment(chargeDetails.SubscriptionStartsFrom).isAfter(chargeDetails.SubscriptionExpiresOn)) || (moment(chargeDetails.FromDate).isAfter(chargeDetails.ToDate)) || subscriptionChargesList.SubscriptionChargesList.find((d) => d.ID != chargeDetails.ID  && (moment(chargeDetails.FromDate) < moment(d.FromDate) && moment(chargeDetails.ToDate) > moment(d.ToDate)  || moment(chargeDetails.FromDate).isBetween(d.FromDate, d.ToDate) || moment(chargeDetails.ToDate).isBetween(d.FromDate, d.ToDate) || moment(chargeDetails.FromDate).isSame(d.FromDate) || moment(d.FromDate).isSame(chargeDetails.ToDate) || moment(chargeDetails.ToDate).isSame(d.ToDate) || moment(d.ToDate ).isSame(chargeDetails.FromDate)))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        }
        helper.StartProcessing(btn);

        try {

            const charges = {
                ID:                        chargeDetails?.ID,
                SubscriptionID:            SubscriptionID,
                Charge:                    chargeDetails.Charge,
                GST:                       chargeDetails.GST,
                Total:                     chargeDetails.Total,
                FromDate:                  chargeDetails.FromDate,
                ToDate:                    chargeDetails.ToDate,
                SubscriptionStartsFrom:    chargeDetails.SubscriptionStartsFrom,
                SubscriptionExpiresOn:     chargeDetails.SubscriptionExpiresOn
            };
            await postSubscriptionCharges(charges)
            helper.StopProcessing(btn);

            $('#add-charges-modal').modal('hide');
            if (chargeDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Charges updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Charges added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            refetchCharges()
            
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setSubscriptionChargesDetails({});

        form.clearForm();
        form.validate().resetForm();
    }

    const updateChargeDetails = (field, value) => { 
        setChargeDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        setSubscriptionChargesDetails({});
    }

    return(
        <>
            <div className="modal fade"id="add-charges-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Charges</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-charges-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-6 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Payment From Date</label>
                                            <div className="input-group date">
                                                <input
                                                    id="txtFromDate"
                                                    name="txtFromDate"
                                                    type="text"
                                                    className="form-control date-input"
                                                    placeholder="Select date"
                                                    readOnly={true}
                                                    value={chargeDetails.FromDate ? moment(chargeDetails.FromDate).format('DD-MM-YYYY') : ''}
                                                />

                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Payment To Date</label>
                                            <div className="input-group date">
                                                <input
                                                    id="txtToDate"
                                                    name="txtToDate"
                                                    type="text"
                                                    className="form-control date-input" 
                                                    readOnly={true}
                                                    placeholder="Select to date"
                                                    value={chargeDetails.ToDate ? moment(chargeDetails.ToDate).format('DD-MM-YYYY') : ''} 
                                                    />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <label>Subscription Starts From</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtSubscriptionStartsFrom" 
                                                    name="txtSubscriptionStartsFrom" 
                                                    type="text" 
                                                    placeholder="Select starts from"
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={chargeDetails.SubscriptionStartsFrom ? moment(chargeDetails.SubscriptionStartsFrom).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 form-group">
                                            <label>Subscription Expires On</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtSubscriptionExpiresOn" 
                                                    name="txtSubscriptionExpiresOn" 
                                                    type="text" 
                                                    placeholder="Select expires on"
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={chargeDetails.SubscriptionExpiresOn ? moment(chargeDetails.SubscriptionExpiresOn).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Charge</label>
                                            <input 
                                                id="txtCharge" 
                                                name="txtCharge" 
                                                type="number" 
                                                className="form-control"
                                                value={chargeDetails.Charge  || ''}
                                                onChange={(event) => updateChargeDetails('Charge', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-2 form-group" style={{marginBottom: '1rem'}}>
                                            <label>GST %</label>
                                            <input 
                                                id="txtGST" 
                                                name="txtGST" 
                                                type="number" 
                                                className="form-control"
                                                value={chargeDetails.GST  || ''}
                                                onChange={(event) => updateChargeDetails('GST', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-2 form-group" style={{marginBottom: '1rem'}}>
                                            <label>GST Amount</label>
                                            <input 
                                                id="txtGSTAmount" 
                                                name="txtGSTAmount" 
                                                type="number" 
                                                className="form-control"
                                                value={chargeDetails.GSTAmount  || ''}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-4 form-group" style={{marginBottom: '1rem'}}>
                                            <label>Total</label>
                                            <input 
                                                id="txtTotal" 
                                                name="txtTotal" 
                                                type="number" 
                                                className="form-control"
                                                value={chargeDetails.Total  || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addChargeHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                {subscriptionChargesDetails.ID ? 'Update Charges' : 'Add Charges'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddChargesModal;