import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import { getMatTimeMasterAll, postTimeMatMaster } from '../api/matAPI';
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const MatTimeMaster = (props) => {

    const [isInit,                      setIsInit]                      = useState(true);
    const [rightSideDetails,            setRightSideDetails]            = useState(false);
    const [allTimeMaster,               setAllTimeMaster]               = useState([]);
    const [matTimeMaster,               setMatTimeMaster]               = useState({});
    const [isMasterStarted,             setIsMasterStarted]             = useState(false);

    useEffect(() => {
        props.title("Time Master", "mat-time-master")       
        fetchMatTimeMaster();
    }, []);

    const currentDate = moment().format('YYYY-MM-DD')

    useEffect(() => {
        if (matTimeMaster.ID) {
            const startDate = moment(matTimeMaster.StartDate).format('YYYY-MM-DD')
            setIsMasterStarted(moment(startDate).isSameOrBefore(currentDate) ? true : false)
        }
    }, [matTimeMaster])

    useEffect(() => {       
        if (rightSideDetails) {
            Config.Core.OpenDrawer();
            $('.date-input').datepicker({ format: 'dd-mm-yyyy', minDate: new Date()});      
            $('#txtStartDate').on('change', ({target}) => updateMatTimeDetails('StartDate',  moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
            $('#txtEndDate').on('change', ({target}) => updateMatTimeDetails('EndDate',  moment(target.value, 'DD-MM-YYYY').format('YYYY-MM-DD')));  
        
        }else{
            setMatTimeMaster({Type: 'Weekday'})
            setIsMasterStarted(false)
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [allTimeMaster]);

    
    const fetchMatTimeMaster = async() => {
        try {
            const calender = await getMatTimeMasterAll()   
            isInit ? setIsInit(false) : $('#tblMaster').KTDatatable().destroy(); 
            setAllTimeMaster(calender); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fillDataTable = () => {
        const dataTable = $('#tblMaster').KTDatatable({
            data: {
                saveState: false,
                source   : allTimeMaster,
                pageSize : 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable  : true,
            pagination: true,
            search    : { input: $('#generalSearch')},
            columns: [               
                {  field: 'Name',  title: 'Name', width:250 },
                {
                    field: 'Type',
                    title: 'Type',
                    template: function (row) {
                        if (row.Type == 'Weekend') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr30 padding-tb10">Weekend</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--info kt-badge--pill padding-lr30 padding-tb10"> Weekday </span>'
                        }
                    },
                    width: 120,
                },
                {
                    field: 'StartDate',
                    title: 'Start Date',
                    width: 120,
                    template: (row) => moment(row.StartDate).format('DD-MMM-YYYY'),  
                },
                {
                    field: 'EndDate',
                    title: 'End Date', width:120,
                    template: (row) => moment(row.EndDate).format('DD-MMM-YYYY'),                                    
                },
            ]
        });
    
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setMatTimeMaster(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });    
    }

    const updateMatTimeDetails = (field, value) => {
        setMatTimeMaster(m => ({
            ...m,
            [field]: value
        }))
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const addMatTimeMasterHandler = async () => {
        const btn  = $('#btnSave');
        const form = $('#addMasterForm');

        form.validate({
            rules: {
                Name        : { required: true }, 
                txtStartDate: { required: true },        
                txtEndDate  : { required: true }      
            },
            messages: {
                Name        : { required: "Please enter Name" },
                txtStartDate: { required: "Please select Start Date"},
                txtEndDate  : { required: "Please select End Date"}
            }
        });

        if (!form.valid()) return;
        //Validation Goes Here -->
                
        helper.StartProcessing(btn);
        try {
            await postTimeMatMaster(matTimeMaster);
            
            helper.StopProcessing(btn);
            
            closeDrawer();
            fetchMatTimeMaster();
            setMatTimeMaster({Type: 'Weekday'})
            if (matTimeMaster.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Master updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,                                       
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Master added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }


    return (
        <>

            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i> New Master </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && (
                                <RightSideDetails title="Details" onCloseDrawer={() => closeDrawer()}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="addMasterForm">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Type</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <select
                                                                className='form-control'
                                                                value={matTimeMaster.Type}
                                                                onChange={(e) => updateMatTimeDetails('Type', e.target.value)}
                                                            >
                                                                <option value='Weekday'>Weekday</option>
                                                                <option value='Weekend'>Weekend</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Name</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input
                                                                label="Name"
                                                                className="form-control"
                                                                type="text"
                                                                id="name"
                                                                value={matTimeMaster.Name || ''}
                                                                name="Name"
                                                                onChange={(event) => updateMatTimeDetails('Name', event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Start Date <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtStartDate" 
                                                                    name="txtStartDate" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    readOnly={true}     
                                                                    disabled={isMasterStarted}                                                    
                                                                    placeholder="Select date"                                                           
                                                                    value={matTimeMaster.StartDate ? moment(matTimeMaster.StartDate).format('DD-MM-YYYY') : ''} 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>                    
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">End Date <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtEndDate" 
                                                                    name="txtEndDate" 
                                                                    type="text"
                                                                    className="form-control date-input" 
                                                                    readOnly={true}                                                         
                                                                    placeholder="Select date" 
                                                                    value={matTimeMaster.EndDate ? moment(matTimeMaster.EndDate).format('DD-MM-YYYY') : ''} 
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>   
                                                </div>
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                            <div className="col-lg-9 ml-lg-auto">
                                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addMatTimeMasterHandler}>Save</button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MatTimeMaster