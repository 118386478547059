import React, { useState, useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import { getActiveSportsMaster, getSportMasterAll, postSportsMaster } from "../api/sportsAPI";
import Select from 'react-select';
import { getCoachBook, getCoachBookByCoachIDAndSportID, getCoachMasterAll, getSportBookByCoachID, getSportTaggingByCoachID, getSubCoach, postCoach, updateSportTaggedActive } from "../api/coachAPI";
import defaultUserImg from '../../assets/images/user.jpg'
import Dropzone from "../global/dropzone";
// import AddCoach from "./AddCoach";
import '../../assets/scripts/jquery-validation.init';
// import AddSubCoachModal from "./AddSubCoachModal";
import NoData from "../global/noData";
import FileImg from '../../assets/images/no-file.svg'
import { getAuthState } from "../../assets/scripts/login-util";

const CoachMaster = (props) => {

    const [coach,               setCoach]               = useState({Active: 'Y', BannerType: 'Image'});
    const [coachList,           setCoachList]           = useState([]);
    const [sports,              setSports]              = useState([]);
    const [sportsTagged,        setSportsTagged]        = useState([]);
    const [multiSport,          setMultiSport]          = useState([]);
    const [selectedSport,       setSelectedSport]       = useState(null);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [removeCk,            setRemoveCk]            = useState(false);
    const [addedSportID,        setAddedSportID]        = useState([])
    const [deletedSportID,      setDeletedSportID]      = useState([])
    const [bookingList,         setBookingList]         = useState([])

    //<--- Cropper -->
    const [profileBlob,         setProfileBlob]         = useState(null);
    const [cropperData,         setCropperData]         = useState(null);
    const [bannerPhotoBlob,     setBannerPhotoBlob]     = useState(null);
    const [file,                setFile]                = useState(null); 

  const userDetails = getAuthState();

    useEffect(() => {         
        props.title("Coach Master", "coach-master")
        fetchCoachMaster();
    }, []);

    useEffect(() => {
        if ((sports && sports.length > 0) && (sportsTagged && sportsTagged.length > 0)) {
            const options = sportsTagged.map(t => {
                const sport = sports.find(s => s.ID == t.SportID);
                return { ...sport, SportTaggedActive: t.Active }
            })
            setMultiSport(options)
        }
    }, [sports, sportsTagged]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [coachList]);

    useEffect(() => {
        if (removeCk) {
            setRightSideDetails(true)
        } else if (!removeCk) {
            window.removeCkeditor("editorContent");
            setRightSideDetails(false)
        }
    }, [removeCk]);

    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
            window.initCkeditor("#editorContent");
            fetchSportsMaster();
            if (coach && coach.About) {
                window.setEditorValue("#editorContent", coach.About);
            }
        } else {
            setCoach({Active: 'Y', BannerType: 'Image'}) 
            setMultiSport([]) 
            setAddedSportID([]) 
            setSportsTagged([])
            setDeletedSportID([]) 
            setSelectedSport(null) 
            setFile(null)
            setProfileBlob(null)
            setBannerPhotoBlob(null)
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if (coach) {
            // if (coach.ID) fetchSportTaggingByCoachID(coach.ID)
            if (coach.About) window.setEditorValue("#editorContent", coach.About)
        }
    }, [coach])


    function fillDataTable() {

        const dataTable = $('#tblCoach').KTDatatable({
            data: {
                saveState: false,
                source: coachList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.ProfilePhoto && row.ProfilePhoto != '') ? `<img src="${row.ProfilePhoto}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblCoach .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setCoach(dataTable.dataSet[index]);  
                fetchSportTaggingByCoachID(dataTable.dataSet[index].ID)
                fetchCoachBookByCoachIDAndSportID(dataTable.dataSet[index].ID)
                setRemoveCk(true);
            }    
        });

    }

    const fetchCoachMaster = async() => {
        try {
            const coachMaster =  await getCoachMasterAll();
            isInit ? setIsInit(false) : $('#tblCoach').KTDatatable().destroy();
            setCoachList(coachMaster)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    
    const fetchSportsMaster = async() => {
        try {
            const response = await getSportMasterAll();
            setSports(response)
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchSportTaggingByCoachID = async (coachID) => {
        try {
            const response = await getSportTaggingByCoachID(coachID);
            setSportsTagged(response)
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchCoachBookByCoachIDAndSportID = async (coachID) => {
        try {
            const response = await getSportBookByCoachID(coachID);
            setBookingList(response)
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }


    const openCropper = () => {
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setProfileBlob(val),
            header : 'Upload Profile Photo'
        })
    }

    const openBannerCropper = () => {
        setCropperData({
            imageRatio  : { width : 500, height : 300},
            dataHandler : (val) =>  setBannerPhotoBlob(val),
            header : 'Upload Banner'
        })
    }

    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setProfileBlob(null)
	}

    const fileHandler = async (f) => {
        setFile(f)
    }

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
    }

    const removeSportHandler = (index) => {
        const deletedSport = multiSport.find((m, i) => i == index)
        if (coach.ID) {
            setDeletedSportID(a => [...a, deletedSport])
        }
        const filteredSports = multiSport.filter((m, i) => i != index)
        setMultiSport(filteredSports)

    }

    const updateCoachDetails = (field, value) => {   
        setCoach((d) => {
            return { ...d, [field]: value };
        });
    };

    const addSportHandler =  (e) => {

        e.preventDefault();

        if (selectedSport) {
            const alreadySelected = multiSport.find((a) => a.ID == selectedSport.ID)
            if(!alreadySelected) {
                if (coach.ID) {
                    setAddedSportID(a => [...a, selectedSport])
                }
                setMultiSport(a => [...a, selectedSport]);
                setSelectedSport(null)
            }
            
        }
    }

    const addCoachHandler = async (e) => {
        e.preventDefault()
        const btn = $('#btnSave');
        var form = $('#add-coach-form');

        const About = window.getEditorValue("#editorContent");

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },
                txtEmail: {
                    required: true,
                    email: true
                },
                        
            },
            messages: {
                txtName: {
                    required: "Please name"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        if (!coach.BannerImage && !coach.BannerVideo && (!file && !bannerPhotoBlob)) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : "Please add banner image or video",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return;
        }
    
        if (multiSport.length == 0) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : "Please add atleast one sport",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return;
        }

        try {
            let coachImage = '';
            let bannerImage = '';
            let bannerVideo = '';
            if (profileBlob) {
                const res = await uploadPublicFile(profileBlob, 'User');
                coachImage = res.fileName;
            }else{
                if(coach.ProfilePhoto) coachImage = coach.ProfilePhoto.split('/User/')[1];
            }
            if (bannerPhotoBlob) {
                const res = await uploadPublicFile(bannerPhotoBlob, 'Content');
                bannerImage = res.fileName;
            }else{
                if(coach.BannerImage) bannerImage = coach.BannerImage.split('/Content/')[1];
            }
            if (file) {
                const res = await uploadPublicFile(file, 'Content');
                bannerVideo = res.fileName;
            }else{
                if(coach.BannerVideo) bannerVideo = coach.BannerVideo;
            }
            // console.log('bannervideo', coach.BannerVideo)
            // console.log('bannerimage', coach.BannerImage)
            // console.log('video', bannerVideo)
            // console.log('image', bannerImage)
            await postCoach({
                ...coach,
                SportIDs: multiSport.map(m => m.ID),
                AddedSportIDs: addedSportID.map(m => m.ID),
                DeletedSportIDs: deletedSportID.map(m => m.ID),
                ProfilePhoto: coachImage,
                BannerType: bannerVideo ? 'Video' : 'Image', 
                BannerImage: bannerImage, 
                BannerVideo: bannerVideo, 
                About
            });
            helper.StopProcessing(btn);
            fetchCoachMaster()
            closeDrawer();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const updateActive = async (sportId, active) => {
        try {
            const coachSport = sportsTagged.find((m, i) => m.SportID == sportId)
            await updateSportTaggedActive(coachSport.ID, active);
        } catch (err) {
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRemoveCk(false);
        }, 200);        
    }


    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Coach..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button  onClick={() => setRemoveCk(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Coach</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblCoach" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && 
                                <RightSideDetails title="Coach Details" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="add-coach-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtName" 
                                                                name="txtName" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={coach.Name || ''}
                                                                onChange={(event) => updateCoachDetails('Name', event.target.value)}  
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Short Description</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtShortDescription" 
                                                                name="txtShortDescription" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={coach.ShortDescription || ''}
                                                                onChange={(event) => updateCoachDetails('ShortDescription', event.target.value)}  
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Coaching Type</label>
                                                        <div className="col-xl-6 col-lg-6">
                                                            <Select 
                                                                id="ddlSport" 
                                                                name="ddlSport"
                                                                className="multi-custom multi__control"
                                                                placeholder="Select Coaching Type"
                                                                value={selectedSport && {label: selectedSport.Name, value: selectedSport}}
                                                                onChange={(e) => setSelectedSport(e.value)}
                                                                options={sports.map(c => ({
                                                                    label: c.Name,
                                                                    value: c
                                                                }))}
                                                            />
                                                        </div>
                                                        <div className="col-xl-2 col-lg-2">
                                                            <button
                                                                id="btnAddAgent"
                                                                className="btn btn-label-brand btn-block margin-0"
                                                                onClick={addSportHandler}
                                                            >
                                                                <i className="la la-plus" />
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {(multiSport && multiSport.length > 0) && (
                                                        <div className='form-group row'>
                                                            <div className='offset-lg-3 offset-xl-3 col-xl-6 col-lg-6'>
                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                    <table className="kt-datatable__table table-striped">
                                                                        <tbody className="kt-datatable__body" style={{display: 'block'}}>
                                                                            {multiSport && (
                                                                                multiSport.map((a, i) =>
                                                                                <tr key={i} className="kt-datatable__row block-row">
                                                                                    <td className="kt-datatable__cell text-clip">
                                                                                        <div className="kt-user-card-v2">
                                                                                            <div className="kt-user-card-v2__pic">
                                                                                                {(a.Logo && a.Logo != '') ? <img src={a.Logo} alt="event-thumbail" /> : <div className="kt-badge kt-badge--xl kt-badge--info">{a.Name.substring(0, 1)}</div>}
                                                                                            </div>
                                                                                            <div className="kt-user-card-v2__details text-clip">
                                                                                                <span className="kt-user-card-v2__name text-clip">{a.Name}</span> 
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    {bookingList.find(b => b.SportID == a.ID && b.Count > 0) ? (
                                                                                        <td className="text-right padding-r20">     
                                                                                            <span className="kt-switch kt-switch--icon">
                                                                                                <label>
                                                                                                    <input 
                                                                                                        id="chkActive" 
                                                                                                        name="chkActive" 
                                                                                                        type="checkbox"
                                                                                                        defaultChecked={a.SportTaggedActive && a.SportTaggedActive == 'N' ? '' : 'checked'}
                                                                                                        onChange={(event) => updateActive(a.ID, event.target.checked ? 'Y' : 'N')} 
                                                                                                    />
                                                                                                    <span></span>
                                                                                                </label>
                                                                                            </span>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td className="text-right padding-r20">     
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => removeSportHandler(i)}
                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                data-toggle="kt-popover"
                                                                                                data-trigger="hover"
                                                                                                data-placement="left"
                                                                                                data-content="Delete Recipient">
                                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                    )}
                                                                                </tr>)
                                                                            )}
                                                                        </tbody>
                                                                    </table>       
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Email<span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtEmail" 
                                                                name="txtEmail" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={coach.Email || ''}
                                                                onChange={(event) => updateCoachDetails('Email', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Mobile<span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <input 
                                                                id="txtMobile" 
                                                                minLength='10'
                                                                maxLength='10'
                                                                name="txtMobile" 
                                                                type="text" 
                                                                className="form-control"
                                                                value={coach.Mobile || ''}
                                                                onChange={(event) => updateCoachDetails('Mobile', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Profile<span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed" id="kt_user_add_avatar">
                                                                <div className="kt-avatar__holder" 
                                                                    style={{ backgroundImage : `url( ${profileBlob ? URL.createObjectURL(profileBlob): coach.ProfilePhoto ? coach.ProfilePhoto : defaultUserImg})` ,width: "120px", height:"120px" } }>    
                                                                </div>
                                                                <label 
                                                                    className="kt-avatar__upload" 
                                                                    data-toggle="kt-tooltip"  
                                                                    data-original-title="Change avatar"
                                                                    onClick ={openCropper}
                                                                >
                                                                    <i className="fa fa-pen"></i>
                                                                </label>
                                                                {(!!profileBlob || coach.ProfilePhoto) && (
                                                                    <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                        <i className="fa fa-times"></i>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                        <label className="col-form-label col-lg-3">Banner Type</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <select
                                                                className="form-control kt-selectpicker"
                                                                id="ddlBannerType"
                                                                value={coach.BannerType || ''}
                                                                onChange={(event) => updateCoachDetails('BannerType', event.target.value)}>                                                 
                                                                <option value="Image">Image</option>
                                                                {/* <option value="Video">Video</option> */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {coach.BannerType == 'Image' && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3"> Banner Image </label>
                                                            <div className="col-xl-6 col-lg-8">                                                        
                                                                {(!!bannerPhotoBlob || coach.BannerImage) &&(<>
                                                                    <img height={150} src={bannerPhotoBlob ? URL.createObjectURL(bannerPhotoBlob) : coach.BannerImage && (coach.BannerImage) }/>
                                                                </>) }
                                                                <p className="mt-3">
                                                                    <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openBannerCropper}>
                                                                    {(!!bannerPhotoBlob || coach.BannerImage) ? 'Change Image' : 'Upload Image'}
                                                                    </button>
                                                                </p>
                                                                <p className="">(500 W x 300 H pixels)</p>                                                       
                                                            </div>
                                                        </div>
                                                    )}
                                                    {coach.BannerType == 'Video' && (
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3"> Banner Video </label>
                                                            <div className="col-xl-6 col-lg-8">  
                                                                {(!file && (coach && !coach.BannerVideo)) && (<Dropzone classNames={'margin-l0 margin-r0 height-150'} fileHandler={fileHandler} from={coach.BannerType === 'Video' && 'AddCoach'} type={coach.BannerType === 'Video' && 'video'} accept="image/* pdf"/>)}
                                                                {(file || (coach && coach.BannerVideo)) && (
                                                                    <React.Fragment>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                                                    <a className="kt-notification__item">
                                                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file ? file : {link:coach.BannerVideoLink})}>
                                                                                            <img height="30px" width="30px" src={helper.getFileLogo(file ? file.name : coach.BannerVideo)} />
                                                                                        </div>
                                                                                        <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file ? file : {link:coach.BannerVideoLink})}>
                                                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                                                {file ? file.name : coach.BannerVideo}
                                                                                            </div>
                                                                                        </div>
                                                                                        <button 
                                                                                            type="button"                    
                                                                                            onClick={removeFileHandler}
                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                        </button>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3"> About </label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <textarea name="editorContent" id="editorContent" />
                                                        </div>
                                                    </div>
                                                    {userDetails.user.userType == 'Admin' &&
                                                        <div className="form-group row" style={{marginBottom: '1rem'}}>
                                                            <label className="col-form-label col-lg-3">Active</label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <span className="kt-switch kt-switch--icon">
                                                                    <label>
                                                                        <input 
                                                                            id="chkActive" 
                                                                            name="chkActive" 
                                                                            type="checkbox"
                                                                            defaultChecked={coach.Active && coach.Active == 'N' ? '' : 'checked'}
                                                                            onChange={(event) => updateCoachDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                        />
                                                                        <span></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div> 
                                                    }
                                                </div>
                                            </form>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                    <div className="row">
                                                        <div className="col-lg-7 ml-lg-auto">
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addCoachHandler}>Save</button>
                                                            <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </> 
    )
}

export default CoachMaster