import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { uploadPublicFile } from "../api/fileAPI";
import ImageCropper from "../global/imageCropper";
import { getFnbMenuCategory, postFnbMenuCategory } from "../api/fnbAPI";

const MenuCategory = (props) => {
    const [menuCategory,           setMenuCategory]            = useState({Active: 'Y'});
    const [menuCategoryList,       setMenuCategoryList]        = useState([]);
    const [isInit,                 setIsInit]                  = useState(true);
    const [rightSideDetails,       setRightSideDetails]        = useState(false);
    
    //<--- Cropper -->
    const [menuCategoryImageBlob,  setMenuCategoryImageBlob]   = useState(null);
    const [cropperData,            setCropperData]             = useState(null);

    useEffect(() => {
        props.title("Menu Category", "menu-category")
        fetchMenuCategory();
    }, []);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setMenuCategory({Active: 'Y'}, setMenuCategoryImageBlob(null));
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [menuCategoryList]);

    const fetchMenuCategory = async() => {
        try {
            const master = await getFnbMenuCategory()
            isInit ? setIsInit(false) : $('#tblMenuCategory').KTDatatable().destroy(); 
            setMenuCategoryList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    function fillDataTable() {

        const dataTable = $('#tblMenuCategory').KTDatatable({
            data: {
                saveState: false,
                source: menuCategoryList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Image && row.Image != '') ? `<img src="${row.Image}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblMenuCategory .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setMenuCategory(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    };

    const updateMenuCategoryDetails = (field, value) => {   
        setMenuCategory((d) => {
            return { ...d, [field]: value };
        });
    };

    const addMenuHandler = async() => {
        const btn = $('#btnSave');
        var form = $('#add-menuCategory-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },       
                txtIndex: {
                    required: true,
                    number: true
                }       
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtIndex: {
                    required: "Please enter index"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let menuImage = '';
            //Call upload API -->
            if(menuCategoryImageBlob){
                const res = await uploadPublicFile(menuCategoryImageBlob, 'Content');
                menuImage = res.fileName;  
            }else{
                if(menuCategory.Image) menuImage = menuCategory.Image.split('/Content/')[1];
            }
            await postFnbMenuCategory({...menuCategory, Image: menuImage || ''});
            helper.StopProcessing(btn);
            closeDrawer();
            fetchMenuCategory();
            if (menuCategory.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Menu updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Menu added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {          
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };
    
    const openCropper = () => {
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setMenuCategoryImageBlob(val),
            header : 'Upload Menu Image'
        })
    };
    
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Menu..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Menu</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblMenuCategory" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Menu Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-menuCategory-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={menuCategory.Name || ''}
                                                        onChange={(event) => updateMenuCategoryDetails('Name', event.target.value)}   
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Image</label>
                                                <div className="col-sm-9">                                                        
                                                    {(!!menuCategoryImageBlob || menuCategory.Image) &&(<>
                                                        <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                            <div className="kt-avatar__holder border-none shadow-none" 
                                                                style={{ backgroundImage : `url( ${menuCategoryImageBlob ? URL.createObjectURL(menuCategoryImageBlob) : menuCategory.Image && (menuCategory.Image) })` } }>    
                                                            </div>
                                                        </div>
                                                    </>) }

                                                    <p className="">
                                                        <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                            {(!!menuCategoryImageBlob || menuCategory.Image) ? 'Change Image' : 'Upload Image'}
                                                        </button>
                                                    </p>
                                                    <p className="">(200 W x 200 H pixels)</p>                                                       
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Index <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtIndex" 
                                                        name="txtIndex" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={menuCategory.Index || menuCategory.Index == 0 ? menuCategory.Index : ''}
                                                        onChange={(event) => updateMenuCategoryDetails('Index', event.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Active</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox"
                                                                defaultChecked={menuCategory.Active == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updateMenuCategoryDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block"  onClick={addMenuHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
};

export default MenuCategory;