import React,{ useEffect, useState } from 'react';
import FileImg from '../../assets/images/no-file.svg'
import Loading from "../global/loading";
import * as helper from '../global/helper';
import NoData from '../global/noData';
import { getAdminEmailAttachment } from '../api/golfClubAPI';


const AttachmentModal = (props) => {


    const [attachedFiles, setAttachedFiles] = useState([]);
    const [searchString,  setSearchString]  = useState('');
    const [loading,       setLoading]       = useState(false);
    const [files,         setFiles]         = useState([]);
    const [filteredFiles, setFilteredFiles] = useState([]);

    useEffect(() => {
        let ignore = false;
    
        $('#add-document-modal').modal({
          backdrop: 'static'
        })
    
        $('#add-document-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');
          $(props.from).removeClass('modal-blur');
          props.onDismissModal(false);
        });
        $(props.from).addClass('modal-blur');  
    
        const fetchAttachement = async () => {
          try {
            setLoading(true);
            const response = await getAdminEmailAttachment();
            setFiles(response);
            setLoading(false); 
          } catch (err) {
            setLoading(false);
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
          }
        };
    
        if (!ignore) {        
            fetchAttachement();
        }
        return () => {
          ignore = true;
        };
    
    }, []);

    useEffect(() => {
        helper.SetScrollHeight();
    });
    
    useEffect(() => {
        if (files.length > 0)
            filterFiles();
        else
            setFilteredFiles([]);
    },[files, searchString])
    
    const filterFiles = () => {
        if (searchString === '') {
          setFilteredFiles(files);
        } else {
            const matchedFiles = files.filter((f) => {
              let matchString = `${f.Name}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredFiles(matchedFiles);
        }
    };

    const selectFileHandler = (ID, IsChecked) => {
        if (IsChecked) {
          setAttachedFiles((f) => {
            const newA = [...f];
            const d = filteredFiles.find(d => d.ID == ID);
            newA.push(d);
            return newA;
          });  
        }else{
          setAttachedFiles((f) => {
            const nA = f.filter(a => a.ID != ID);
            return nA;
          })   
        }
    }
    
    const addDocument = async (e) => {
        attachedFiles.forEach(file => {
            props.setDocumentHandler(file);
        });
    
        $('#add-document-modal').modal('hide');
    
    };

    return(
        <>
            <div className="modal fade" id="add-document-modal" tabIndex="-1" role="dialog" aria-hidden="true" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Attachment</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body padding-0">
                            <React.Fragment>
                                <div className="row align-items-center stickyFilter">
                                    <div className="col-xl-12 order-1 order-xl-1">
                                        <div className="row">
                                            <div className="col-md-6 margin-b0">
                                                <div className="kt-input-icon kt-input-icon--left">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search File..."
                                                        id="generalSearch"
                                                        value={searchString || ''}
                                                        onChange={(event) => setSearchString(event.target.value)}>
                                                    </input>
                                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span>
                                                            <i className="la la-search"></i>
                                                        </span>
                                                    </span>
                                                    {searchString && (
                                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                        <span>
                                                            <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                        </span>
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading ? (
                                <Loading />
                                ) : (
                                <React.Fragment>
                                    {filteredFiles.length > 0 ? (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                <table className="kt-datatable__table">
                                                    <thead className="kt-datatable__head" style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <th className="kt-datatable__cell text-clip" width="75%">
                                                                <span>Attachment</span>
                                                            </th>
                                                            <th className="kt-datatable__cell" width="10%">
                                                                <span>Actions</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                        {filteredFiles.map((f, i) => (
                                                        <tr key={f.ID} className="kt-datatable__row block-row">
                                                            <td title={f.Name} className="kt-datatable__cell" width='75%'>
                                                                <div className="kt-notification text-clip">
                                                                    <a className="kt-notification__item padding-0">
                                                                        <div className="kt-notification__item-icon margin-r10">
                                                                            <img height="30px" width="30px" src={helper.getFileLogo(f.FileName)} />
                                                                        </div>
                                                                        <div className="kt-notification__item-details text-clip">
                                                                            <div className="kt-notification__item-title kt-font-bold text-clip">
                                                                                {f.Name}
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="kt-datatable__cell text-clip" width="10%">
                                                                <label className="kt-checkbox kt-checkbox--brand">
                                                                    <input type="checkbox" 
                                                                        onClick={(event) => selectFileHandler(f.ID, event.target.checked)}/>
                                                                    &nbsp;<span></span>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    ) : (
                                    <NoData
                                        src={FileImg}
                                        alt="File"
                                        message="No Files Found"
                                    />
                                    )}
                                </React.Fragment>
                                )}             
                            </React.Fragment>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal">    
                                Close
                            </button>
                            <button
                                id="save-document"
                                onClick={addDocument}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            > 
                                Attach
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttachmentModal;