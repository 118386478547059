import { useState } from "react";
import { useEffect } from "react";
import Loading from "../global/loading";
import  * as helper from '../global/helper';
import nofile from "../../assets/images/no-file.svg";


const Attachment = (props) => {

    const [attachment,                        setAttachment]                = useState([]);

    useEffect(() => {
        if(props.attachment && props.attachment.length > 0){
            setAttachment(props.attachment);
        }
    },[props.attachment])

    return(
        <>
            <div className="kt-form kt-form--label-right kt-margin-b-10">
                {props.loading ? <Loading /> 
                :<>
                    {attachment && attachment.length > 0 ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                <table className="kt-datatable__table">
                                    <thead
                                        className="kt-datatable__head"
                                        style={{ display: "block" }}>
                                        <tr className="kt-datatable__row block-row">
                                            <th className="kt-datatable__cell text-clip" width="75%">
                                                <span>Attachment</span>
                                            </th>
                                            <th className="kt-datatable__cell" width="10%">
                                                <span>Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" >
                                <table className="kt-datatable__table table-striped">
                                    <tbody
                                        className="kt-datatable__body"
                                        style={{ display: "block", minHeight:'40vh' }}>
                                        {attachment.map((t, i) => {
                                        return (
                                            <tr key={i} className="kt-datatable__row block-row ">
                                                <td className="kt-datatable__cell text-clip" width="85%" title={t.Name}>
                                                    <div className="kt-user-card-v2">
                                                        <img height="30px" width="30px" src={helper.getFileLogo(t.FileName)} />                                               
                                                        <div className="kt-user-card-v2__details text-clip ml-3">
                                                            <span className="kt-user-card-v2__name text-clip">
                                                                {t.Name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td width="10%"  className="kt-datatable__cell ">
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            onClick={() => props.deleteDocumentHandler(t)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                            data-toggle="kt-popover"
                                                            data-trigger="hover"
                                                            data-placement="left"
                                                            data-content="Delete Document">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className="padding-20" style={{ margin:'auto'}}>
                            <div className="empty-image-container">
                                <img style={{ width: '80%' }} src={nofile} alt="no-client-img" />
                                <h4 className="margin-t20" style={{ fontSize :'18px' }}> No Attachment Found </h4>
                            </div>
                        </div>
                    )}
                </>}
            </div>
        </>
    )
}

export default Attachment;