import React,{ useState, useEffect} from "react";
import { addAdminEmailTemplate, deleteAdminEmailAttachmentByID, deleteAdminEmailTemplate, getAdminEmailAttachment, getAdminEmailTemplate, updateAdminEmailTemplate } from "../api/golfClubAPI";
import EmailTemplateTable from "./emailTemplateTable";
import * as helper from "../global/helper";
import SendEmailModal from "./sendEmailModal";
import EmailStatTable from "./emailStatsTable";
import Attachment from "./attachment";
import AddAttachment from "./addAttachment";
import swal from 'sweetalert2';

const AddTemplateModal = (props) => {
    const { selectedTemplate , setSelectedTemplate } = props;

    const [templateData,          setTemplateData]          = useState(selectedTemplate || {});

    useEffect(() => {
        $('#addTemplate-modal').modal({ backdrop: 'static' });

        window.initCkeditor("#editorContent");
  
        $('#addTemplate-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          window.removeCkeditor("editorContent");
          props.refetchTemplates(true);
          setSelectedTemplate(null);
          props.onDismissModal(); 
        });
    }, []);

    useEffect(() => {
        if(selectedTemplate && selectedTemplate.Template) window.setEditorValue("#editorContent", selectedTemplate.Template);
    },[selectedTemplate])

    const handleInput = (key, value) => {
        setTemplateData( d  => { return {...d, [key]: value}})
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        const Content = window.getEditorValue("#editorContent");
        var form = $("#addTemplate-form");
        form.validate().destroy();
        
        form.validate({
            rules: {
              Name: { required: true },
            },
        });

        if (!form.valid())  return;

        if (!Content) {
            return swal.fire({
              icon  : "error",
              title : "Template Content is required !",
            });
        }

        helper.StartProcessing($("#save-btn"));
        try {
            if(selectedTemplate && selectedTemplate.ID){
                let newData = templateData;
                newData.Template = Content;
                await updateAdminEmailTemplate(newData);
                swal.fire({
                    icon                : 'success',
                    titleText           : 'Template Saved',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
            }else{
                await addAdminEmailTemplate({...templateData, Template : Content});
                swal.fire({
                    icon                : 'success',
                    titleText           : 'Template Added Successfully',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
            }

            helper.StopProcessing($("#save-btn"));
            $("#addTemplate-modal").modal("hide");
        } catch (err) {
            helper.StopProcessing($("#save-btn"));
            swal.fire({
              icon                : 'error',
              titleText           : 'Error!',
              text                : err.message,
              buttonsStyling      : false,
              confirmButtonClass  : 'btn btn-brand',
            });  
        }
    }

    return(
        <div className="modal fade" id="addTemplate-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title"> {selectedTemplate ? 'Edit': 'Add'} Email Template </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    <div className="client-container">
                        <form id="addTemplate-form" onSubmit={(e) => {  e.preventDefault()}}>
                            <div className="row">
                                <div className="col-12">
                                <input
                                    id="Name"
                                    value={templateData.Name || ''}
                                    name="Name"
                                    placeholder="Name"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={({ target: { value } }) => handleInput('Name', helper.camelCase(value)) }/>          
                                </div>
                            </div>
                        
                            {/* Content- CkEditor */}
                            <div className="row margin-t20">
                                <div className="col-12">
                                    <textarea name="editorContent" id="editorContent" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary margin-0 margin-r5"
                        data-dismiss="modal">   
                        Close
                    </button>
                    <button
                        id="save-btn"
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-brand margin-0">   
                        {selectedTemplate ? 'Save' : 'Add'} Template
                    </button>
                </div>
            </div>
            </div>
        </div>
    )   
}

const Emailer = (props) => {
    const [Templates,               setTemplates]               = useState([]);
    const [loading,                 setLoading]                 = useState(false);
    const [loadingAttachment,       setLoadingAttachment]       = useState(false);
    const [showAddTemplateModal,    setShowAddTemplateModal]    = useState(false); 
    const [showAddAttachmentModal,  setShowAddAttachmentModal]  = useState(false); 
    const [selectedTemplate,        setSelectedTemplate]        = useState(null);
    const [sendEmailTemplate,       setSendEmaiTemplate]        = useState(null); 
    const [refetch,                 setRefetch]                 = useState(false);
    const [refetchAttachment,       setRefetchAttachment]       = useState(false);
    const [navState,                setNavState]                = useState('Emailer');
    const [attachment,              setAttachment]              = useState([]);


    useEffect(() => {
        props.title('Emailer', 'admin-emailer');
        fetchEmailTemplates();
        fetchAttachment();
    }, []);

    useEffect(() => {
        if(refetch){
            fetchEmailTemplates()
            setRefetch(false)
        }
    },[refetch])

    useEffect(() => {
        if(refetchAttachment){
            fetchAttachment()
            setRefetchAttachment(false)
        }
    },[refetchAttachment])
    
    const fetchEmailTemplates = async() =>{
        setLoading(true)
        try {
            const response = await getAdminEmailTemplate();
            if(response) {
                setTemplates(response); 
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });    
        }
    } 

    const onEditHandler = (data) => {
        setSelectedTemplate(data)
        setShowAddTemplateModal(true);
    }

    const onDeleteHandler = (data) => {
        swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3758ff',
            confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
            if (result.value) {
                try {
                     const response = await deleteAdminEmailTemplate(data.ID);
                    
                    if(response) {
                        const newArr = Templates.filter( t => t.ID != data.ID);
                        setTemplates(newArr);
                        swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        setRefetch(true)
                    }
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });    
                }
            }
        })
    }

    const fetchAttachment = async() => {
        setLoadingAttachment(true)
        try {
            const response = await getAdminEmailAttachment();
            if(response) {
                setAttachment(response); 
                setLoadingAttachment(false)
            }
        } catch (err) {
          setLoadingAttachment(false)
          swal.fire({
            icon                : 'error',
            titleText           : 'Error!',
            text                : err.message,
            buttonsStyling      : false,
            confirmButtonClass  : 'btn btn-brand',
          });  
        }
    }   
  
    const deleteDocumentHandler = async (t) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
                try {
                    await deleteAdminEmailAttachmentByID(t.ID);
                    fetchAttachment();
                } catch (err) {
                    swal.fire({
                    icon                : 'error',
                    titleText           : 'Error!',
                    text                : err.message,
                    buttonsStyling      : false,
                    confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
            }
            },
        });
        if (!swalResult.value) return;
        setAttachment((a) => {
            const nA = a.filter((b) => b.ID != t.ID)
            return nA
        });
        swal.fire({
            icon              : 'success',
            titleText         : 'Attachment Deleted Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    const onSendHandler = (data) => { setSendEmaiTemplate(data) };
    return(
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <a className={navState === "Emailer" ? "top-nav-active" : "" } onClick={() => setNavState("Emailer")}  >  
                                    Emailer
                                </a>
                                <a className={navState === "Email-Stats" ? "top-nav-active" : "" } onClick={() => setNavState("Email-Stats")}  >  
                                    Email-Stats
                                </a>
                                <a className={navState === "Attachment" ? "top-nav-active" : "" } onClick={() => setNavState("Attachment")}  >  
                                    Attachment
                                </a>
                            </nav>
                        </div>
                        {navState === "Emailer" && (
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions margin-l10">
                                        <button className="btn btn-brand btn-icon-sm text-white" onClick={(e) => {e.preventDefault(), setShowAddTemplateModal(true)}}>
                                            <i className="la la-plus"></i>New Template
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {navState === "Attachment" && (
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions margin-l10">
                                        <button className="btn btn-brand btn-icon-sm text-white" onClick={(e) => {e.preventDefault(), setShowAddAttachmentModal(true)}}>
                                            <i className="la la-plus"></i>New Attachment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        {navState == "Emailer" && (
                            <EmailTemplateTable
                                loading         = {loading}
                                Templates       = {Templates}
                                onEditHandler   = {onEditHandler}
                                onDeleteHandler = {onDeleteHandler}
                                onSendHandler   = {onSendHandler}
                            /> 
                        )}
                        {navState == "Email-Stats" && (
                            <EmailStatTable/> 
                        )}
                        {navState == "Attachment" && (
                            <Attachment
                                loading                 = {loadingAttachment}
                                attachment              = {attachment}
                                deleteDocumentHandler   = {deleteDocumentHandler}                            
                            /> 
                        )}
                    </div>
                </div>
            </div>
            {showAddTemplateModal && (
                <AddTemplateModal 
                    onDismissModal={() => setShowAddTemplateModal(false)}
                    refetchTemplates = {(v) => setRefetch(v)}
                    selectedTemplate = { selectedTemplate }
                    setSelectedTemplate= { setSelectedTemplate }
                />
            )}
            {sendEmailTemplate && (
                <SendEmailModal
                    onDismissModal = {() => setSendEmaiTemplate(null)}
                    selectedEmailTemplate = { sendEmailTemplate }
                />
            )}
            {showAddAttachmentModal && (
                <AddAttachment
                    onDismissModal={() => setShowAddAttachmentModal(false)}
                    setRefetchAttachment = {(v) => setRefetchAttachment(v)}
                />
            )}
        </>
    )
}

export default Emailer;