import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import RightSideDetails from "../global/rightSideDetails";
import { deleteBooking, getBookingDetails, getShotgunBookSheet, getTeeTimeBookSheet, postTeeTimeBook, verifyDate } from "../api/teeTimeAPI";
import ic_check_on from '../../assets/images/ic_check_on.png'
import golf_cart from '../../assets/images/golf_cart.png'
import golf_caddy from '../../assets/images/golf_caddy.png'
import * as helper from '../global/helper';
import AddGuest from "./addGuest";
import AddUser from "./addUser";
import AddPayment from "./addPayment";
import Loading from "../global/loading";

const BookingSheet = (props) => {

    const [selectedDate,                    setSelectedDate]                    = useState(moment().toDate());
    const [TeeBoxOption,                    setTeeBoxOption]                    = useState([{ value:  'All', label: 'All'},{ value:  '1', label: '1'}, { value:  '4', label: '4'}]);
    const [typeOption,                      setTypeOption]                      = useState([{ value:  'Member', label: 'Member'}, { value:  'Guest', label: 'Guest'},]);
    const [blockData,                       setBlockData]                       = useState([]);
    const [type,                            setType]                            = useState({'TeeBox': TeeBoxOption[0].label});
    const [teeTimeBookSheet,                setTeeTimeBookSheet]                = useState([]);
    const [shotgunBookSheet,                setShotgunBookSheet]                = useState([]);
    const [isInit,                          setIsInit]                          = useState(true);
    const [rightSideDetails,                setRightSideDetails]                = useState(false);
    const [bookSheet,                       setBookSheet]                       = useState({});
    const [showAddUser,                     setShowAddUser]                     = useState(false);
    const [showAddGuest,                    setShowAddGuest]                    = useState(false);
    const [usersList,                       setUsersList]                       = useState([]);
    const [MemberList,                      setMemberList]                      = useState([]);
    const [guestList,                       setGuestList]                       = useState([]);
    const [mobileCodeList,                  setMobileCodeList]                  = useState([]);
    const [bookingUserList,                 setBookingUserList]                 = useState([]);
    const [showAddPayment,                  setShowAddPayment]                  = useState();
    const [paymentTagging,                  setPaymentTagging]                  = useState({});
    const [paymentTaggingList,              setPaymentTaggingList]              = useState([]);
    const [bookingTable,                    setBookingTable]                    = useState(null);
    const [loading,                         setLoading]                         = useState(false);

    
    useEffect(() => {
        props.title("Booking Sheet", "tee-sheet")    
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
    },[])

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
            if(bookSheet && bookSheet.Hole == '9'){
                $('.redio9').attr('checked', true)
            }else {
                $('.redio18').attr('checked', true)
            }
        }else{
            setBookSheet({})
            setBookingUserList([])
            setPaymentTaggingList([])
        }
    }, [rightSideDetails]);

    useEffect(() => {
        if(selectedDate){
           fetchVerifyDate(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }
    },[selectedDate])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable(type.TeeBox);
        }
    }, [teeTimeBookSheet, shotgunBookSheet]);

    useEffect(() => {       
        if (paymentTagging && Object.keys(paymentTagging).length !== 0) {
            setPaymentTaggingList((a) => {
                const filterA = a.filter((c) => c.UserID != paymentTagging.UserID);
                const newA = [...filterA];
                newA.push((paymentTagging))
                return newA
            })
        }
    }, [paymentTagging]);

    useEffect(() => {
        if(type.Type == "Shotgun"){
            fetchShotgunBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }else{
            fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }
    },[type])

    useEffect(() => {
        if(bookingUserList){
            fetchBookingDetails(bookSheet.ID || 0)    
            let ids = new Set(bookingUserList.map(({ UserID }) => UserID));
            setUsersList((a) => {
                const nA = a.filter(({ ID }) => !ids.has(ID));
                return nA;
            })
            let idsGuest = new Set(bookingUserList.map(({ ID }) => ID));
            setGuestList((a) => {
                const nA = a.filter(({ ID }) => !idsGuest.has(ID));
                return nA;
            })
        }
    },[bookingUserList])

    const fetchBookingDetails = async(id) =>{
        try {
            setLoading(true);
            const booking = await getBookingDetails(id)
            const filteredUsers = booking.BookingUsers ?  booking.Users.filter( g => booking.BookingUsers.findIndex( f => ((f.UserID == g.ID) )) === -1 ) : booking.Users.filter( g =>  bookingUserList.findIndex( f => (f.UserType == "User" &&  (f.UserID == g.ID) )) === -1 );
            const filteredGuests = booking.BookingUsers ?  booking.Guests.filter( g => booking.BookingUsers.findIndex( f => ((f.UserID == g.ID) )) === -1 ) : booking.Guests.filter( g => bookingUserList.findIndex( f => (f.UserType == "Guest" && (f.UserID == g.ID) )) === -1 );
            setMemberList(booking.Users)
            setUsersList(filteredUsers)
            setGuestList(filteredGuests)
            setMobileCodeList(booking.MobileCode)
            if(booking.BookingUsers && bookingUserList.length == 0) setBookingUserList(booking.BookingUsers)
            if(booking.teeTimePaymentTagging) setPaymentTaggingList(booking.teeTimePaymentTagging)
            setLoading(false);
           
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
            setLoading(false);

        }
    }

    const fetchShotgunBookSheetData = async(date) => {
        try {
            const response = await getShotgunBookSheet(date);
            isInit ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setShotgunBookSheet(response.BookSheet)
          
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }
    
    const fetchTeeTimeBookSheetData = async(date) => {
        try {
            const response = await getTeeTimeBookSheet(date);
            isInit ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setTeeTimeBookSheet(response)
         
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchVerifyDate = async(date) => {
        try {
            const response = await verifyDate(date);
            updateTypeDetails('Type', response.type)
            if(response.type == "Shotgun"){
                const ddlBlock = response.shotgunBlocks.map(b => ({ value: b.ShotgunBlockID, label: b.ShotgunBlockID }));
                updateTypeDetails('Block', ddlBlock[0].label)
                setBlockData(ddlBlock);
            }           
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };

    function exportSheet(btnType) {
        let fileName = `${moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} - Tee Booking Sheet - ${type == 'Shotgun' ? 'Shotgun Start' : type.TeeBox + ' Tee Box'}`;

        bookingTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName // pass in a new set of properties
        });
        
        if (btnType == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        
        $('.tableexport-caption').addClass('hidden');
    }

    function fillDataTable(teeBox) {

        swal.close();

        var check = '<img src="' + ic_check_on + '" style="height: 20px; width: 20px; float:right;"/>';
        var cart = '<img src="' + golf_cart + '" style="height: 22px; width: 22px; float:right; margin-left:5px"/>';
        var caddy = '<img src="' + golf_caddy + '" style="height: 20px; width: 20px; float:right; margin-left:5px"/>';

        var bookingList = [];

        if (teeBox == 'All') {
            bookingList = teeTimeBookSheet.BookSheet.sort((a, b) => {
                var c = moment(moment($('#txtDate').val(), 'DD-MM-YYYY').format("DD-MMM-YYYY") + ' ' + a.Slot, "DD-MMM-YYYY").format("DD-MMM-YYYY hh:mm A");
                var d = moment(moment($('#txtDate').val(), 'DD-MM-YYYY').format("DD-MMM-YYYY") + ' ' + b.Slot, "DD-MMM-YYYY").format("DD-MMM-YYYY hh:mm A");

                if (moment(d).isBefore(moment(c))) {
                    return 1;
                } else if (moment(c).isBefore(moment(d))) {
                    return -1;
                } else {
                    return 0;
                }
            });

        } else {
            bookingList = teeTimeBookSheet.BookSheet.filter(i => i.TeeBox == teeBox);
        }

        if (teeTimeBookSheet.Holiday != null) {
            $('#lblHoliday').text("Holiday : " + teeTimeBookSheet.Holiday.Name);
        } else {
            $('#lblHoliday').text("");
        }

        $.each(bookingList, function (index, element) {

            if (element.Player1Caddy == 'Y') {
                element["Player1"] += caddy;
            }
            if (element.Player2Caddy == 'Y') {
                element["Player2"] += caddy;
            }
            if (element.Player3Caddy == 'Y') {
                element["Player3"] += caddy;
            }
            if (element.Player4Caddy == 'Y') {
                element["Player4"] += caddy;
            }

            if (element.Player1Cart == 'Y') {
                element["Player1"] += cart;
            }
            if (element.Player2Cart == 'Y') {
                element["Player2"] += cart;
            }
            if (element.Player3Cart == 'Y') {
                element["Player3"] += cart;
            }
            if (element.Player4Cart == 'Y') {
                element["Player4"] += cart;
            }

            if (element.Player1IsPresent == 'Y') {
                element["Player1"] += check;
            }
            if (element.Player2IsPresent == 'Y') {
                element["Player2"] += check;
            }
            if (element.Player3IsPresent == 'Y') {
                element["Player3"] += check;
            }
            if (element.Player4IsPresent == 'Y') {
                element["Player4"] += check;
            }

        });
        const dataTable = $('#tblBookSheet').KTDatatable({
            data: {
                saveState: false,
                source: bookingList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Slot',
                    title: 'Slot',
                    width: 70
                },
                {
                    field: 'TeeBox',
                    title: 'Tee',
                    width: 50
                },
                {
                    field: 'Status',
                    title: 'Status',
                    width: 85,
                    template: function (row) {
                        if (row.Status == null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return '<div class="badge badge-danger"><span>' + row.Status + '</span></div>';
                        }
                    }
                },
                {
                    field: 'Player1',
                    title: 'Player 1',
                    template: function (row) {
                        if (row?.Player1) {
                            return `<span class='kt-badge kt-badge--${row.UserType1 == "User" ? 'success' : (row.UserType1 == "Guest" || row.UserType1 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player1}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player2',
                    title: 'Player 2',
                    template: function (row) {
                        if (row?.Player2) {
                            return `<span class='kt-badge kt-badge--${row.UserType2 == "User" ? 'success' : (row.UserType2 == "Guest" || row.UserType2 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player2}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player3',
                    title: 'Player 3',
                    template: function (row) {
                        if (row?.Player3) {
                            return `<span class='kt-badge kt-badge--${row.UserType3 == "User" ? 'success' : (row.UserType3 == "Guest" || row.UserType3 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player3}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player4',
                    title: 'Player 4',
                    template: function (row) {
                        if (row?.Player4) {
                            return `<span class='kt-badge kt-badge--${row.UserType4 == "User" ? 'success' : (row.UserType4 == "Guest" || row.UserType4 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player4}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'CreatedDate',
                    title: 'Time',
                    width: 145,
                    template: function (row) {
                        if (row.CreatedDate == null) {
                            return "";
                        } else {
                            return moment(row.CreatedDate).format('DD-MMM hh:mm:ss.SS A');
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {

                        if (row.Status == "Booked" || row.Status == null) {
                            return showEditButton(row)
                        } else {
                            return '';
                        }
                    }
                },

            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const BookingTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Tee Booking Sheet - ${type.TeeBox} Tee Box`,
        });
        setBookingTable(BookingTable);
        $('.tableexport-caption').addClass('hidden');
        
        function showEditButton(row) {

            const formattedDate = moment($('#txtDate').val(), 'DD/MM/YYYY').format('DD-MMM-YYYY');

            var optString = '';

            const isEdit = moment(moment(teeTimeBookSheet.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(formattedDate);

            optString += '<center>'

            if (row.Comment) optString += `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>`

            if (isEdit) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

            optString += '</center>'

            return optString;
        }
        dataTable.on('click', '.btn-edit', function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setBookSheet({...dataTable.dataSet[index], Type: 'Member'});  
                fetchBookingDetails(dataTable.dataSet[index].ID || 0)
                setRightSideDetails(true); 
            } 
        });
    }
    
    const addBookingUserHandler = () =>{
        if(bookSheet.UserID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = usersList.find((f) => f.ID == bookSheet.UserID)
                newA.push({...newO,  UserID: newO.ID, UserType: "User"})
                return newA;
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: ''}))
        }
    }

    const addBookingGuestHandler = () =>{
        if(bookSheet.GuestID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = guestList.find((f) => f.ID == bookSheet.GuestID)
                newA.push({...newO,  UserID: newO.ID, UserType: "Guest"})
                return newA
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: ''}))
            paymentHandler({...guestList.find((f) => f.ID == bookSheet.GuestID), UserID: bookSheet.GuestID, UserType: "Guest"});
        }
    }

    const addBookingHandler = async() => {
        const btn = $('#btnSave');
        helper.StartProcessing(btn);

        try {
            const bookSheetDetails = {
                ID:                 bookSheet.ID || 0,
                TemplateID:         bookSheet.TemplateID,
                Hole:               bookSheet.Hole || 18,
                BookDate:           moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                Comment:            bookSheet.Comment,
            }

            let bookingUser = [];

            bookingUserList.forEach(function (e, i) {
                bookingUser.push({
                    ID:                 0, 
                    TeeTimeBookID:      bookSheet.ID || 0,
                    UserID:             e.UserID,  
                    UserType:           e.UserType
                });
            });

            const teeTimePaymentTagging = paymentTaggingList.map((t) =>({
                ...t,
                ID:                     t.ID || 0,
                UserID:                 t.UserID,
                TeeTimeBookUserID:      0,
                ReferenceID:            t.ReferenceID || 0,
                ReferenceType:          t.ReferenceType || 'Direct',
                PaymentMode:            t.PaymentMode || 'Direct',
                ReferenceName:          t.ReferenceName || ''

            }))

            await postTeeTimeBook(bookSheetDetails, bookingUser, teeTimePaymentTagging)
            helper.StopProcessing(btn);
            setTimeout(() => {
                if(type.Type == "Shotgun"){
                    fetchShotgunBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                }else{
                    fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                }
            }, 500);
            closeDrawer();
            if (bookSheetDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Booking updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Booking added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const paymentHandler = (value) => {
        if(value.UserType == 'Guest'){
            if(paymentTaggingList && paymentTaggingList.length > 0){
                setPaymentTagging(paymentTaggingList.find((f) => f.UserID == value.UserID && f.TeeTimeBookUserID == value.TeeTimeBookUserID))
            }else{
                setPaymentTagging({})
            }
            setShowAddPayment(value)
        }
    }

    const cancelBookingHandler = async() => {
        const btn = $('#btnCancel');
        helper.StartProcessing(btn);
        try {
            const isEmptyOrSpaces = bookSheet.Comment === null || bookSheet.Comment.match(/^ *$/) !== null || /\s/.test(bookSheet.Comment);

            await deleteBooking(bookSheet.ID, isEmptyOrSpaces ? null : bookSheet.Comment)
            helper.StopProcessing(btn);
            setTimeout(() => {
                if(type.Type == "Shotgun"){
                    fetchShotgunBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                }else{
                    fetchTeeTimeBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                }
            }, 1000);
            closeDrawer();
            swal.fire({
                icon: 'success',
                titleText: 'Booking deleted successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const resetUser = () => {
        setBookSheet(a => ({...a, UserID: ''}))
    }
    const resetGuest = () => {
        setBookSheet(a => ({...a, GuestID: ''}))
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const deleteUserHandler = (index, value) => {
        setBookingUserList(p => {
            const nA = p.filter((f, i) => index !== i )
            return nA
        })
        if(value.UserType == 'Guest'){
            setPaymentTaggingList(p => {
                const nA = p.filter((f) => value.UserID !== f.UserID )
                return nA
            })    
        }
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control " 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right pnlSlot ">Tee Box </label>
                                    <div className="form-group col-sm-2 pnlSlot ">
                                        <div>
                                            <Select 
                                                id="ddlTeeBox" 
                                                name="ddlTeeBox "
                                                className="multi-custom "
                                                defaultValue={ TeeBoxOption.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => updateTypeDetails('TeeBox', event.value)}
                                                options={TeeBoxOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Tee Time..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-sm-2">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            <div id="tblBookSheet" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && (<RightSideDetails title="Book Tee Time" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Date</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="lblDate" 
                                                        name="lblDate" 
                                                        disabled="disabled" 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                    />
                                                </div>
                                            </div>
                                            <div id="pnlEdit" className="form-group row">
                                                <label className="col-form-label col-lg-3">Slot <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div>
                                                        <Select 
                                                            id="ddlTeeBox" 
                                                            name="ddlTeeBox"
                                                            className="multi-custom"
                                                            value={(teeTimeBookSheet && teeTimeBookSheet.BookSheet.length > 0 && bookSheet.TemplateID) && {label: teeTimeBookSheet.BookSheet.find(c => c.TemplateID == bookSheet.TemplateID).Slot, value: bookSheet.TemplateID}}
                                                            onChange={(event) => updateBookingDetails('TemplateID', event.value)}
                                                            options={teeTimeBookSheet.BookSheet.map(a => ({
                                                                label: a.Slot,
                                                                value: a.TemplateID,
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div id="pnlAdd" className="form-group row">
                                                <label className="col-form-label col-lg-3">Slot</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="lblSlot" 
                                                        name="lblSlot" 
                                                        disabled="disabled" 
                                                        type="text" 
                                                        className="form-control" 
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"></label>
                                                <div className="kt-radio-inline col-xl-6 col-lg-8 margin-l10">
                                                    <label id="pnlRedio18" className="kt-radio kt-radio--brand">
                                                        <input 
                                                            type="radio" 
                                                            id="redio18" 
                                                            className="redio18" 
                                                            name="hole" 
                                                            value="18" 
                                                            onChange={({ target }) => updateBookingDetails('Hole',target.value)}
                                                        />
                                                            18 Holes
                                                        <span></span>
                                                    </label>
                                                    <label className="kt-radio kt-radio--brand">
                                                        <input 
                                                            type="radio" 
                                                            id="redio9" 
                                                            className="redio9" 
                                                            name="hole" 
                                                            value="9"
                                                            onChange={({ target }) => updateBookingDetails('Hole',target.value)}
                                                        />
                                                            9 Holes
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div id="pnlType" className="form-group row">
                                                <label className="col-form-label col-lg-3">Type <span className="red"></span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div>
                                                        <Select 
                                                            id="ddlType" 
                                                            name="ddlType"
                                                            className="multi-custom"
                                                            placeholder="Select Type"
                                                            defaultValue={ typeOption.find((m) => m.value[0] )  || ''}
                                                            onChange={(event) => updateBookingDetails('Type', event.value)}
                                                            options={typeOption}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {bookSheet.Type == "Member" ? (
                                                <div id="UserHolder">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">User</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(usersList && usersList.length > 0 && bookSheet.UserID) && {label: `${usersList.find(m => m.ID == bookSheet.UserID).Name} (${usersList.find(m => m.ID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''}
                                                                        onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        className="ddlUser"
                                                                        options={usersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append" style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button"  onClick={addBookingUserHandler}>Add</button>
                                                                {/* <button id="btnNewUser" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalUser" onClick={() => setShowAddUser(true)}>New</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div id="GuestHolder" >
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Guest</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group">
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(guestList && guestList.length > 0 && bookSheet.GuestID) && {label: `${guestList.find(m => m.ID == bookSheet.GuestID).Name} (${guestList.find(m => m.ID == bookSheet.GuestID).Mobile})`, value: bookSheet.GuestID}}
                                                                        onChange={(event) => updateBookingDetails('GuestID', event.value)}
                                                                        name="ddlGuest"
                                                                        placeholder="Select Guest"
                                                                        id="ddlGuest"
                                                                        data-live-search="true"
                                                                        className="ddlGuest"
                                                                        options={guestList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append"  style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetGuest}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                <button id="btnAddGuest" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingGuestHandler}>Add</button>
                                                                <button id="btnNewGuest" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalGuest"  onClick={() => setShowAddGuest(true)} >New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Comment</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <textarea 
                                                        id="txtComment" 
                                                        name="txtComment" 
                                                        className="form-control" 
                                                        rows="3"
                                                        value={bookSheet.Comment || ''}
                                                        onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </form>
                                        {loading ? <Loading/> : (<table id="table-user" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th>Players</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            {bookingUserList && bookingUserList.length > 0 &&
                                                <tbody>
                                                    {bookingUserList.map((m, i) => (
                                                        <tr key={i}>
                                                            <td onClick={() => {!m.TeeTimeBookID  && paymentHandler(m)}}><span className={`kt-badge kt-badge--${m.UserType == "User" ? 'success' : (m.UserType == "Guest" || m.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot`}></span>&nbsp; &nbsp;{m.UserNameAdmin || m.Name}{m.UserType == "Guest" && !m.TeeTimeBookID && ` (G) - ${(paymentTaggingList.length > 0 && paymentTaggingList.find(f => f.UserID == m.UserID && (f.TeeTimeBookUserID == m.TeeTimeBookUserID || f.TeeTimeBookUserID == m.ID)) ? paymentTaggingList.find(f => f.UserID == m.UserID).TagTo : "Direct")}`}</td>
                                                            <td>
                                                                <center>
                                                                    <button className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type="button" onClick={() => deleteUserHandler(i, m)} >                  
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>             
                                                                    </button>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                            }
                                            <tfoot></tfoot>
                                        </table>)}
                                        <div className="row margin-bl5">
                                            <div className="col-sm-12 pl-0">
                                                <h6 className="margin-0">
                                                    <strong id="lblOwner">
                                                        {bookingUserList && bookingUserList.length > 0 && bookingUserList[0].UserName && 'Booked By ' + (bookSheet.OwnerType == 'Admin' ? 'Admin' : bookingUserList[0].UserName) }
                                                    </strong>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.ID ? 'Update Booking' : 'Save Booking' }</button>
                                                    {bookSheet && bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>}
                                                    <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAddUser && (
                <AddUser
                    show={showAddUser}
                    onDismissModal={setShowAddUser}                   
                />
            )}
            {showAddGuest && (
                <AddGuest
                    show={showAddGuest}
                    onDismissModal={setShowAddGuest} 
                    setShowAddPayment={setShowAddPayment} 
                    mobileCodeList={mobileCodeList}
                    fetchBookingDetails={() => fetchBookingDetails(bookSheet.ID || 0)} 
                    bookingUserList={bookingUserList}
                />
            )}
            {(showAddPayment && Object.keys(showAddPayment).length !== 0) && (
                <AddPayment
                    show={showAddPayment}
                    MemberDetails={showAddPayment}
                    onDismissModal={setShowAddPayment} 
                    setPaymentTagging={setPaymentTagging} 
                    paymentTagging={paymentTagging} 
                    MemberList={MemberList} 
                />
            )}
        </>
    )
}
export default BookingSheet;