import React, { useEffect, useState } from 'react'
import * as helper from '../global/helper';
import Select from 'react-select';

import { getCoachBySportID, getSportTaggingByCoachID } from '../api/coachAPI';
import { getSportMasterAll } from '../api/sportsAPI';
import { getAuthState } from '../../assets/scripts/login-util';

const BookingFilterForCancellation= ({
    selectedDate,
    selectedToDate,
    selectedCoach,
    selectedSport,
    setSelectedDate,
    setSelectedToDate,
    setSelectedCoach,
    setSelectedSport,
}) => {

    const [coachList,               setCoachList]                   = useState([]);
    const [sportList,               setSportList]                   = useState([]);
    
    const userDetails = getAuthState();

    useEffect(() => {
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchSportMaster()
    }, []);

    useEffect(() => {
        if (selectedSport) fetchCoachBySportID(selectedSport.ID)
    }, [selectedSport])

    useEffect(() => {
        if (coachList && coachList.length > 0) setSelectedCoach(coachList[0])
    }, [coachList])

    useEffect(() => {
        if (sportList && sportList.length > 0) setSelectedSport(sportList[0])
    }, [sportList])

    const fetchCoachBySportID = async (sportID) => {
        try {
            const coachMaster = await getCoachBySportID(sportID);
            setCoachList(coachMaster)
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)  
        }
    }

    const fetchSportMaster = async () => {
        try {
            if (userDetails.user.userType == 'Coach') {
                const response = await getSportTaggingByCoachID(userDetails.user.id);
                const sportMaster = response.map(r => ({
                    ...r,
                    ID: r.SportID,
                }))
                setSportList(sportMaster)
            } else {
                const response = await getSportMasterAll();
                setSportList(response)
            }
        } catch (error) {
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    return (
        <>
            <div className="form-group col-sm-2">
                <div className="input-group date">
                    <input 
                        id="txtDate" 
                        name="txtDate" 
                        type="text" 
                        className="form-control" 
                        placeholder="Select date"
                        defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                    /> 
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="la la-calendar-check-o"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className="form-group col-sm-2">
                <div className="input-group date">
                    <input 
                        id="txtToDate" 
                        name="txtToDate" 
                        type="text" 
                        className="form-control" 
                        placeholder="Select to date"
                        defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                    /> 
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="la la-calendar-check-o"></i>
                        </span>
                    </div>
                </div>
            </div>
            <label className="col-form-label col-sm-1 text-right">Sport </label>
            <div className={`form-group col-sm-1`}>
                <Select
                    onChange={(event) => setSelectedSport(event.value)}
                    name="ddlSelectUser"
                    value={(selectedSport && sportList.length > 0) && {label : selectedSport.Name, value: selectedSport}}
                    id="ddlSelectUser"
                    className="ddlSelectUser"
                    options={sportList.map(c => ({
                        label: `${c.Name}`,
                        value: c
                    }))}
                />
            </div>
            <label className="col-form-label col-sm-1 text-right">Coach </label>
            <div className="form-group col-sm-2">
                <Select
                    onChange={(event) => setSelectedCoach(event.value)}
                    name="ddlSelectUser"
                    value={(selectedCoach && coachList.length > 0) && {label : selectedCoach.Name, value: selectedCoach}}
                    id="ddlSelectUser"
                    className="ddlSelectUser"
                    options={coachList.map(c => ({
                        label: `${c.Name}`,
                        value: c
                    }))}
                />
            </div>
            <div className="form-group col-sm-2">
                <div className="input-group">
                    <div className="kt-input-icon kt-input-icon--left">
                        <input 
                            type="text" 
                            className="form-control clientSearch" 
                            placeholder="Search..." 
                            id="generalSearch" 
                        />
                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingFilterForCancellation