import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getTaxType, postTaxType } from "../api/fnbAPI";

const TaxType = (props) => {
    
    const [taxType,              setTaxType]              = useState({});
    const [taxTypeList,          setTaxTypeList]          = useState([]);
    const [isInit,               setIsInit]               = useState(true);
    const [rightSideDetails,     setRightSideDetails]     = useState(false);
       
    useEffect(() => {
        props.title("Tax Type Master", "taxType-master")
        fetchTaxType();
    }, []);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setTaxType({});
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [taxTypeList]);

    const fetchTaxType = async() =>{
        try {
            const master = await getTaxType()
            isInit ? setIsInit(false) : $('#tblTaxTypeMaster').KTDatatable().destroy(); 
            setTaxTypeList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        };
    };

    function fillDataTable() {

        const dataTable = $('#tblTaxTypeMaster').KTDatatable({
            data: {
                saveState: false,
                source: taxTypeList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2__pic">
                                ${`<div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                            </div> 
                            <div class="kt-user-card-v2__details text-clip ml-3">
                                <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> 
                            </div>`
                    }
                },
                // {
                //     field: 'Active',
                //     title: 'Active',
                //     template: function (row) {
                //         return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                //     },
                //     width: 50,
                // },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTaxTypeMaster .kt-datatable__table').addClass('overflow-x-auto');


        dataTable.on('click', 'tr', function () {

            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setTaxType(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });
    };

    const updateTaxTypeDetails = (field, value) => {   
        setTaxType((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const addTaxTypeHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-taxType-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                txtDescription: {
                    required: true,
                }         
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtDescription: {
                    required: "Please enter description"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
                      
            await postTaxType(taxType);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchTaxType();
            if (taxType.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'TaxType updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'TaxType added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {          
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        };
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Master</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTaxTypeMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="TaxType Master Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-taxType-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtName" 
                                                        name="txtName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={taxType.Name || ''}
                                                        onChange={(event) => updateTaxTypeDetails('Name', event.target.value)}   
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Description <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <textarea 
                                                        id="txtDescription" 
                                                        name="txtDescription" 
                                                        className="form-control" 
                                                        rows="3"
                                                        value={taxType.Description || ''}
                                                        onChange={(event) => updateTaxTypeDetails('Description', event.target.value)} 
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block"  onClick={addTaxTypeHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default TaxType;