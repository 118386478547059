import AssessmentTracker from "./assessmentTracker";
import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import defaultUserImg from '../../assets/images/user.jpg'
import Select from 'react-select';
import swal from 'sweetalert2';
import ic_check_on from '../../assets/images/ic_check_on.png'

const Assessment = (props) => {
 
    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [onboardingUserList,         setOnboardingUserList]         = useState([]);
    const [userDetails,                setUserDetails]                = useState({});
    const [isInit,                     setIsInit]                     = useState(true);
    const [coachOption,                setCoachOption]                = useState([{ value: 'Coach 1', label: 'Coach 1'}, { value:  'Coach 2', label: 'Coach 2'}, { value:  'Coach 3', label: 'Coach 3' }, { value:  'Coach 4', label: 'Coach 4' }, { value:  'Coach 5', label: 'Coach 5' } ]);
    const [playerOption,               setPlayerOption]               = useState([{ value: 'Player 1', label: 'Player 1'}, { value:  'Player 2', label: 'Player 2'}, { value:  'Player 3', label: 'Player 3' }, { value:  'Player 4', label: 'Player 4' }]);
    const [action,                     setAction]                     = useState('a');

    useEffect(() => {        
        props.title('Assessment', 'assessment')
        fetchOnboardingUsers();
    },[]); 

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer();
            $('#txtStartDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtStartDate').on('change', function (event) {
                updateUserDetails('StartDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtEndDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtEndDate').on('change', function (event) {
                updateUserDetails('EndDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
        }else{
            setUserDetails({})
            setAction('a');
        }
    },[rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [onboardingUserList]);

    const fetchOnboardingUsers = async() => {
        try {
            const users = [{ID: 1, Name: 'Player 1', ProfilePhoto: 'https://img.freepik.com/free-photo/3d-cartoon-individual-playing-tennis_23-2151730835.jpg', FormSubmit: 'Y', Submit: 'Y', Coach: 'Coach 2', TeamName: 'Team 2', FromDate: '2024-06-01', ToDate: '2024-09-31', Age: '21'},{ID: 2, Name: 'Player 2', ProfilePhoto: 'https://img.freepik.com/free-photo/kids-playing-soccer-cartoon_23-2151451224.jpg', FormSubmit: 'N', Submit: 'N', Coach: 'Coach 1', TeamName: 'Team 1', FromDate: '2024-06-01', ToDate: '2024-09-31', Age: '18'}]   
            isInit ? setIsInit(false) : $('#tblAssessmentForm').KTDatatable().destroy(); 
            setOnboardingUserList(users);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
  
    function fillDataTable() {

        const dataTable = $('#tblAssessmentForm').KTDatatable({
            data: {
                saveState: false,
                source: onboardingUserList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Student',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.ProfilePhoto && row.ProfilePhoto != '') ? `<img src="${row.ProfilePhoto}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                         </span> <span class="kt-user-card-v2__desc text-clip"> Age: ${row.Age} </span>
                                    </div>
                               </div>`
                    },
                    width: 220,

                },
                {
                    field: 'Coach',
                    title: 'Coach',
                    template: function (row) {
                        return  `<strong style="font-weight: 600"> <span class="kt-user-card-v2__desc text-clip"> ${row.Coach} </span></strong>`

                    },
                    width: 100, 
                },  
                {
                    field: 'TeamName',
                    title: 'Team',
                    template: function (row) {
                        return  `<strong style="font-weight: 600"> <span class="kt-user-card-v2__desc text-clip"> ${row.TeamName} </span></strong>`

                    },
                    width: 100, 
                },  
                {
                    field: '',
                    title: 'Validity',
                    template: function (row) {
                        return `<strong style="font-weight: 600"> <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.FromDate).format('YYYY-MM-DD')).isSame(moment(row.to).format('YYYY-MM-DD')) ? moment(row.FromDate).format('DD MMM YYYY') : moment(row.FromDate).format('DD MMM YYYY') + ' - ' + moment(row.SubscriptionExpiresOn).format('DD MMM YYYY') } </span></strong>`
                    }
                },
                {
                    field: ' ',
                    title: 'Status',
                    template: function (row) {
                        return row?.Submit == 'Y'   ? `<span class=""> <img src="${ic_check_on}" height="24"/></span>` : `<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr35 padding-tb10"> Pending </span>`
                    },
                    width: 80,
                },
               
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblAssessmentForm .kt-datatable__table').addClass('overflow-x-auto');
        
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if(dataTable.dataSet[index]){
                setUserDetails(dataTable.dataSet[index])
                setAction('e');
                setRightSideDetails(true);           
             }
        });
    };
    
    const updateUserDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const addChargeHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-assessment-form');
        form.validate({
            rules: {         
                ddlCoach: {
                    required: true
                },          
                ddlPlayer: {
                    required: true
                },
                txtStartDate: {
                    required: true,
                },            
                txtEndDate: {
                    required: true
                },          
                txtTeamName: {
                    required: true
                }         
            },
            messages: {
                ddlCoach: {
                    required: "Please select coach"
                },
                ddlPlayer: {
                    required: "Please select player"
                },
                txtStartDate: {
                    required: "Please select start date"
                },
                txtEndDate: {
                    required: "Please select end date"
                },
                txtTeamName: {
                    required: "Please select team name"
                } 
            }
        });
        
        if (!form.valid()) return;
        
        try {
            window.open(`https://demo.clubez.in/assessment`, '_blank', 'noopener');
            
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        form.clearForm();
        form.validate().resetForm();
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    };

    const viewForm = () => {
        window.open(`https://demo.clubez.in/assessment`, '_blank', 'noopener');
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search User..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => {setRightSideDetails(true)}} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>Create Assessment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblAssessmentForm" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title={action == 'a' ? "Assessment Details" : "Player Details"}  onCloseDrawer={() => closeDrawer()}>
                                {action == 'a' ? (
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form className="kt-form" id="add-assessment-form">
                                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Coach <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlCoach" 
                                                                name="ddlCoach"
                                                                className="multi-custom"
                                                                placeholder="Select Coach"
                                                                value={userDetails && coachOption.find((m) => m.value == userDetails.Coach )  || ''}
                                                                onChange={(event) => updateUserDetails('Coach', event.value)}
                                                                options={coachOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Player <span className="red">*</span></label>
                                                        <div className="col-3">
                                                            <Select 
                                                                id="ddlPlayer" 
                                                                name="ddlPlayer"
                                                                className="multi-custom"
                                                                placeholder="Select Player"
                                                                value={userDetails && playerOption.find((m) => m.value == userDetails.Player )  || ''}
                                                                onChange={(event) => updateUserDetails('Player', event.value)}
                                                                options={playerOption}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Start Date <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtStartDate" 
                                                                    name="txtStartDate" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    readOnly={true} 
                                                                    placeholder="Select date" 
                                                                    value={userDetails.StartDate ? moment(userDetails.StartDate).format('DD-MM-YYYY') : ''}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">End Date <span className="red">*</span></label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group date">
                                                                <input 
                                                                    id="txtEndDate" 
                                                                    name="txtEndDate" 
                                                                    type="text" 
                                                                    className="form-control date-input" 
                                                                    readOnly={true} 
                                                                    placeholder="Select date"
                                                                    value={userDetails.EndDate ? moment(userDetails.EndDate).format('DD-MM-YYYY') : ''}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>  
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Team Name <span className="red">*</span></label>
                                                        <div className="col-xl-4 col-lg-8">
                                                            <input 
                                                                id="txtTeamName" 
                                                                name="txtTeamName" 
                                                                type="text" 
                                                                className="form-control" 
                                                                value={userDetails.TeamName || ''}
                                                                onChange={(event) => updateUserDetails('TeamName', event.target.value)} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-9 ml-lg-auto">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block"onClick={addChargeHandler}>Create Form </button>
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className={`col-12`}> 
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-widget kt-widget--user-profile-3">
                                                            <div className="kt-widget__top">
                                                                <img className="kt-widget__pic kt-widget__pic" src={userDetails.ProfilePhoto && userDetails.ProfilePhoto != '' ?  userDetails.ProfilePhoto : defaultUserImg } alt="user-profile"/> 
                                                                 <div className="kt-widget__content">
                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <div>
                                                                            <div className="kt-widget__head">
                                                                                <span className="kt-widget__username">
                                                                                    {userDetails.Name}{' '}
                                                                                </span>
                                                                            </div>
                                                                            <div className="kt-widget__subhead padding-t0">
                                                                                <span className="padding-r10">
                                                                                    <i className="flaticon2-user"></i>
                                                                                    {userDetails.Coach} 
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-widget__action margin-r50">
                                                                            <span className="dropdown">
                                                                                <button type="button" className="btn btn-primary" onClick={viewForm}>View Form</button>
                                                                             </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet kt-portlet--mobile">
                                            <div className="kt-portlet__body padding-t0">
                                                <AssessmentTracker
                                                    userDetails={userDetails}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

 export default Assessment;