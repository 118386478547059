import { useState, useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { getFnbRateMasterByMenuItemID, getTaxType, postFnbRateMaster, postTaxStructure } from "../api/fnbAPI";
import Select from 'react-select';

const RateModal = (props) => {

    const { menuItem } = props

    const [rateList,           setRateList]            = useState([]);
    const [rateDetails,        setRateDetails]         = useState({});
    const [isEdit,             setIsEdit]              = useState(false);
    const [taxTypeList,        setTaxTypeList]         = useState([]);

    useEffect(() => {
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            dataHandler('FromDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            dataHandler('ToDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }); 
        fetchTaxType();
    },[]);

    useEffect(() => {
        if(menuItem?.ID){
            fetchFnbRateMaster(menuItem.ID);
        };
    },[menuItem]);

    const dataHandler = (field,value) => setRateDetails(d => ({...d, [field]: value}));

    const fetchTaxType = async() =>{
        try {
            const master = await getTaxType();
            setTaxTypeList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        };
    };

    const editHandler = (id) => {
        setIsEdit(true);
        const d = rateList.find(a => a.ID == id);
        setRateDetails({...d, Rate: d.Rate.replace(/,/g, "")});
    };

    const postTaxStructureData = async() => {
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addBlockForm');

        form.validate({
            rules: {        
                ddlTaxType: {
                    required: true
                },
                txtRate: {
                    required: true,
                },         
                txtDiscount: {
                    required: true,
                },         
                txtFromDate: {
                    required: true
                },          
                txtToDate: {
                    required: true
                }          
            },
            messages: {
                ddlTaxType: {
                    required: "Please select to type"
                },
                txtRate: {
                    required: "Please enter rate"
                },
                txtDiscount: {
                    required: "Please enter discount"
                },
                txtFromDate: {
                    required: "Please enter from date"
                },
                txtToDate: {
                    required: "Please enter to date"
                }
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);

        try {
            if((moment(rateDetails.FromDate).isAfter(rateDetails.ToDate)) || rateList.find((d) => d.ID !== rateDetails.ID && (moment(rateDetails.FromDate) < moment(d.FromDate) && moment(rateDetails.ToDate) > moment(d.ToDate)  || moment(rateDetails.FromDate).isBetween(d.FromDate, d.ToDate) || moment(rateDetails.ToDate).isBetween(d.FromDate, d.ToDate) || moment(rateDetails.FromDate).isSame(d.FromDate) || moment(d.FromDate).isSame(rateDetails.ToDate) || moment(rateDetails.ToDate).isSame(d.ToDate) || moment(d.ToDate ).isSame(rateDetails.FromDate)))){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Date Conflict!',
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return  
            };

            rateDetails.MenuItemID = menuItem.ID;

            const res = await postFnbRateMaster(rateDetails);          
            setRateList(res);            
            helper.StopProcessing(btn);                  
            if (rateDetails.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Rate updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Rate added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            };
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const fetchFnbRateMaster = async(id) => {
        try {
            const master = await getFnbRateMasterByMenuItemID(id);
            setRateList(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        };
    };

    const clearAction = () => {       
        setRateDetails({});
        setIsEdit(false);
    };

    return(
        <>
            <div className="kt-portlet__body padding-t20 padding-b0">
                <form className="kt-form sticky margin-b10 padding-t15 padding-b10 bg-white" id="addBlockForm" >                
                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form  " role="form">
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-1 text-right"> Tax Type </label>
                            <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                <Select
                                    value={((taxTypeList && taxTypeList.length > 0 && rateDetails.TaxTypeID) && {label: (taxTypeList.find((m) => m.ID == rateDetails.TaxTypeID) && taxTypeList.find((m) => m.ID == rateDetails.TaxTypeID).Name), value: rateDetails.TaxTypeID}) || ''}
                                    onChange={(event) => dataHandler('TaxTypeID', event.value)}
                                    name="ddlTaxType"
                                    id="ddlTaxType"
                                    className="ddlTaxType"
                                    options={taxTypeList.map(c => ({
                                        label: c.Name,
                                        value: c.ID
                                    }))}
                                />
                            </div>
                            <label className="col-form-label col-sm-1 text-right"> Rate </label>
                            <div className="col-3 form-group" style={{marginBottom: '1rem'}}>
                                <input 
                                    id="txtRate" 
                                    name="txtRate" 
                                    type="number" 
                                    className="form-control"
                                    value={rateDetails.Rate  || ''}
                                    onChange={(event) => dataHandler('Rate', event.target.value)} 
                                />
                            </div>
                            <label className="col-form-label col-sm-1 text-right"> Discount </label>
                            <div className="form-group col-3 input-group" style={{marginBottom: '1rem'}}>
                                <input 
                                    id="txtDiscount" 
                                    name="txtDiscount" 
                                    type="number" 
                                    className="form-control"
                                    value={rateDetails.Discount  || ''}
                                    onChange={(event) => dataHandler('Discount', event.target.value)} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="flaticon2-percentage"></i>                    
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-form-label col-sm-1 text-right"> From Date </label>
                            <div className="form-group input-group date col-3">
                                <input 
                                    id="txtFromDate" 
                                    name="txtFromDate" 
                                    type="text" 
                                    className="form-control date-input" 
                                    defaultValue={rateDetails?.FromDate ? moment(rateDetails?.FromDate).format('DD-MM-YYYY') : ''} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="la la-calendar-check-o"></i>
                                    </span>
                                </div>
                            </div>
                            <label className="col-form-label col-sm-1 text-right"> To Date </label>
                            <div className="form-group input-group date col-3">
                                <input 
                                    id="txtToDate" 
                                    name="txtToDate" 
                                    type="text" 
                                    className="form-control date-input" 
                                    defaultValue={rateDetails?.ToDate ? moment(rateDetails?.ToDate).format('DD-MM-YYYY') : ''} 
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="la la-calendar-check-o"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-3">
                                {(!isEdit) ? (
                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0 form-control`} onClick={postTaxStructureData} ><i className="la la-plus"></i> Add </button>
                                ):(
                                    <div className="btn-group" style={{width: '100%'}}>
                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0`} style={{width: '75%'}} onClick={postTaxStructureData} > Update </button>
                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <table id="table-block" className="table table-bordered table-hover margin-b30">
                    <thead className="light-grey-background">
                        <tr>
                            <th  width="10%" > Rate </th>
                            <th  width="10%" > Discount </th>
                            <th  width="35%" > Date </th>
                            <th  width="40%" > Tax Type </th>
                            <th  width="5%" >  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(rateList.length > 0 ) ?
                            rateList.map((t,i) => {
                                return (
                                    <tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                        <td width="10%" style={{fontWeight: '520'}}>₹ {t.Rate}</td>
                                        <td width="10%">{t.Discount} %</td>
                                        <td width="40%">{moment(moment(t.FromDate).format('YYYY-MM-DD')).isSame(moment(t.ToDate).format('YYYY-MM-DD')) ? moment(t.FromDate).format('DD MMM YYYY') : moment(t.FromDate).format('DD MMM YYYY') + ' - ' + moment(t.ToDate).format('DD MMM YYYY') }</td>
                                        <td width="40%">{t.TaxType} &nbsp;{t.IsActive && <span className={`kt-badge kt-badge--success kt-badge--inline kt-badge--pill padding-lr10`}>{t.IsActive && 'Active'}</span>} </td>
                                        <td className="text-center" width="5%">
                                            <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>
                                        </td>
                                    </tr>
                                )
                            }):
                            (
                            <tr>
                                <td colSpan={3}> 
                                    <div className="padding-10"> 
                                        <h5 className="text-center text-muted"> No Rates..! </h5> 
                                    </div>
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    );

};

export default RateModal;