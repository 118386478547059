import { useEffect } from "react";
import { deleteMemberDocumentsByIDAndGroupID, getMemberDocumentsByGroupID } from "../api/usersAPI";
import { useState } from "react";
import swal from 'sweetalert2';
import Loading from "../global/loading";
import FileImg from '../../assets/images/no-file.svg'
import NoData from "../global/noData";
import * as helper from '../global/helper';

const MemberDocuments = (props) =>{

    const [document,              setDocument]        = useState([])
    const [loading,               setLoading]         = useState(false);

    useEffect(() =>{
        fetchMemberDocument()
    },[props.refresh])

    const fetchMemberDocument = async() =>{
        try {
            setLoading(true);
            const response = await getMemberDocumentsByGroupID(props.GroupID)
            setDocument(response)
            setLoading(false);

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            setLoading(false);
        }
    }

    const openDocument = async (d) => {
        // window.open(d.FileName, "_blank")       
        const ext = helper.getFileExt(d.FileName)
        const name = (d.DocumentType + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler('Document', d.FileName, name); 
    }

    const deleteDocumentHandler = async (d) => {
        const swalResult = await swal.fire({
            title               : 'Are you sure?',
            text                : "You won't be able to revert this!",
            icon                : 'warning',
            showCancelButton    : true,
            buttonsStyling      : false,
            reverseButtons      : true,
            showLoaderOnConfirm : true,
            confirmButtonClass  : 'btn btn-brand',
            cancelButtonClass   : 'btn btn-secondary',
            confirmButtonText   : 'Delete',
            preConfirm: async () => {
              try {
                    await deleteMemberDocumentsByIDAndGroupID(d.ID, d.GroupID);
              } catch (err) {
                swal.fire({
                  icon                : 'error',
                  titleText           : 'Error!',
                  text                : err.message,
                  buttonsStyling      : false,
                  confirmButtonClass  : 'btn btn-brand',
                });
                return;
              }
            },
        });
        if (!swalResult.value) return;
        setDocument((a) => {
            const nA = a.filter((b) => b.ID != d.ID)
            return nA
        });
        swal.fire({
            icon              : 'success',
            titleText         : 'Document Deleted Successfully',
            showConfirmButton : false,
            timer             : 1500
        });
    }

    return(
        <>
        {loading ? <Loading/> :(
            document.length > 0 ? (
                <div className="row">
                    <div className="col-12 padding-0">
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable" onScroll={helper.setStickyHeader}>
                            <table className="kt-datatable__table table-striped">
                                <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                    {document.map((d, i) => {
                                        return(
                                            <tr key={d.ID} className="kt-datatable__row block-row">
                                                <td title={d.DocumentType} className="kt-datatable__cell" width='80%' onClick={() => openDocument(d)}>
                                                    <div className="kt-notification text-clip">
                                                        <a className="kt-notification__item padding-0">
                                                            <div className="kt-notification__item-icon margin-r10">
                                                                <img height="30px" width="30px" 
                                                                    src={helper.getFileLogo(d.FileName)} 
                                                                />
                                                            </div>
                                                            <div className="kt-notification__item-details text-clip">
                                                                <div className="kt-notification__item-title kt-font-bold text-clip">
                                                                    {d.DocumentType}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip text-right" width="20%">
                                                    <button 
                                                        type="button"
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="left"
                                                        data-content="Edit Document"                
                                                        onClick={() => props.setEditDocument(d)}>  
                                                        <i className="flaticon2-edit"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => deleteDocumentHandler(d)}
                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="left"
                                                        data-content="Delete Document">
                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            ):(
                <NoData
                    src={FileImg}
                    alt="File"
                    message="No Documents Found"
                />
            )
        )}  
        </>
    )
}
export default MemberDocuments;